import React, { useState } from 'react'
import { SettingService } from '../../../../services/setting/SettingService'
import { toast } from 'react-toastify'
import { AuthService } from '../../../../services/auth/AuthService'
import { AuthUtil } from '../../../../utils/AuthUtil'
import { useTranslation } from 'react-i18next'

export default function OtpModal(props:any) {
    
    const [otpCode, setOtpCode] = useState('')    
    const { t, i18n } = useTranslation();

    const onClickClose = () => {
        props.onCloseModal(false)
    }

    const handleSubmit = () => {

        if(props.loginData){
            const param = {
                otpCode: otpCode,
                ...props.loginData
            }  
            AuthService.getInstance().verifyOTP(param).then((res) => {
                AuthUtil.saveToken(res.data.result.accessToken);
                window.location.href = "/";
            })
        }else{
            const param = {
                otpCode: otpCode,
                status: false
            }            
            SettingService.getInstance().verifyOtp(param).then((result:any)=>{
                if(result.data.status){
                    toast.success(t("setting.message.disable-2fa"))
                    props.onCloseModal(false)
                }else{
                    toast.error(t("setting.message.otp-err"))
                }
            }).catch((error:any)=>{
                console.log(error);
            })
        }
        
    }
  return (
    <div className='modal-content'>
        <div className="modal-body">
        <div className="text-center">
           <h4>{t("setting.label.otp")}</h4> 
        </div>
        <div className="form p-4">
                <div className="form-group">
                    <input type="text" onChange={(event:any)=>setOtpCode(event.target.value)} className="form-control text-center"  name='otp'/>
                </div>
            </div>
        </div>
        <div className="modal-footer">
            <button className="btn btn-secondary mr-4" onClick={onClickClose}>{t("common.button.cancel")}</button>
            <button className="btn btn-info" onClick={handleSubmit}>{t("common.button.save")}</button>
        </div>
    </div>
  )
}
