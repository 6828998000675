import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { PostService } from "../../../services/post/PostService";
import PostView from "./components/PostView";
import { useDispatch } from "react-redux";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";
import { PostReadLaterService } from "../../../services/post/PostReadLaterService";
import { PaginationConstant } from "../../../constants/PaginationConstant";
import { CommonConstant } from "../../../constants/CommonConstant";
import { bubbles } from "./bubble";
import { HabbitService } from "../../../services/habbit/HabbitService";
import { ReviewerService } from "../../../services/reviewer/ReviewerService";
import Suggested from "./components/Suggested";
import { TranslationConstant } from "../../../constants/TranslationConstant";
import { useAppSelector } from "../../../hooks/hook";

export default function Newsfeed() {
  const { t, i18n } = useTranslation();
  const lang = useAppSelector((state) => state.language.lang);
  const dispatch = useDispatch();

  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });
  const [totalElements, setTotalElements] = useState(0);

  const [posts, setPosts] = useState<any[]>([]);
  const [bubblesSelected, setBubblesSelected] = useState<any>();
  const [bubble, setBubble] = useState<any[]>(bubbles);
  const [trendingCategory, setTrendingCategory] = useState<any[]>([]);
  const [topHashtags, setTopHashtags] = useState<any[]>([]);
  const [isLoaded, setIsLoaded] = useState<any>(false);
  useEffect(() => {
    getPosts();
  }, [bubblesSelected]);
  useEffect(() => {
    getTrendingCategory();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", addEventListenerScroll);
  //   return () => {
  //     window.removeEventListener("scroll", addEventListenerScroll);
  //   };
  // }, []);

  // const addEventListenerScroll = useCallback((event: any) => {
  //   console.log(
  //     event.target.scrollingElement.scrollHeight -
  //       event.target.scrollingElement.scrollTop,
  //     event.target.scrollingElement.clientHeight
  //   );
  //   if (
  //     event.target.scrollingElement.scrollHeight -
  //       event.target.scrollingElement.scrollTop ===
  //     event.target.scrollingElement.clientHeight
  //   ) {

  //     loadMorePosts();
  //   }
  // }, []);

  const getPosts = () => {
    dispatch(showAndHideSpinner(true));
    setIsLoaded(false)
    if (!bubblesSelected) {
      PostService.getInstance()
        .search(searchRequest)
        .finally(() => {
          setIsLoaded(true)
          setTimeout(() => {
            dispatch(showAndHideSpinner(false));
          }, 500);
        })
        .then(
          (res) => {
            if (res.data.status) {
              let temps = posts.concat(res.data.responseData?.content || []);
              setPosts(temps);
              setTotalElements(res.data.responseData?.totalElements);
            } else {
              toast.error(t("toast.serverError"))
            }
          },
          (error) => { }
        );
    }
    if (bubblesSelected) {
      if (bubblesSelected.id === 1) {
        getPostTrending();
      } else if (bubblesSelected.id === 2) {
        getPostFollowing()
      } else if (bubblesSelected.id === 3) {
        getPostReadLater();
      } else {
        filterTrending();
      }

    }
  };


  const getPostTrending = () => {
    setIsLoaded(false)
    PostService.getInstance()
      .trending(searchRequest)
      .finally(() => {
        setIsLoaded(true)
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then((res) => {
        let temps = posts.concat(res.data.responseData?.listPost.content);
        setPosts(temps);
      });
  };

  const getPostFollowing = () => {
    setIsLoaded(false)
    PostService.getInstance()
      .following(searchRequest)
      .finally(() => {
        setIsLoaded(true)
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then((res) => {
        let temps = posts.concat(res.data.responseData?.content || []);
        setPosts(temps);
        setTotalElements(res.data.responseData?.totalElements);
      });
  };

  const filterTrending = () => {
    PostService.getInstance()
      .filterTrending(searchRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then((res) => {
        let temps = posts.concat(res.data.responseData?.content);
        setTotalElements(res.data.responseData?.totalElements);
        setPosts(temps);
      });
  };

  const getTrendingCategory = () => {
    PostService.getInstance()
      .trending(searchRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then((res) => {
        setTrendingCategory(res.data.responseData?.trendingCategory)
        setTopHashtags(res.data.responseData?.topHashtags)
      });
  };

  const getPostReadLater = () => {
    setIsLoaded(false)
    PostReadLaterService.getInstance()
      .getReadLater(searchRequest)
      .finally(() => {
        setIsLoaded(true)
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then((res) => {
        console.log(res.data.responseData);

        let temps = posts.concat(res.data.responseData);
        setTotalElements(res.data.responseData?.length);
        setPosts(temps);
      });
  };

  const loadMorePosts = () => {
    searchRequest.page = searchRequest.page + 1;
    setSearchRequest(searchRequest);
    getPosts();
  };

  const refreshPosts = () => {
    getPosts();
  };

  const setNavSelected = (bubble: any, type?: any) => {
    if (bubble !== bubblesSelected) {
      setPosts([]);
      if (type === "category") {
        setSearchRequest({
          page: 0,
          size: PaginationConstant.PAGE_SIZE,
          categoryName: bubble,
        });
      } else if (type === "hashtag") {
        setSearchRequest({
          page: 0,
          size: PaginationConstant.PAGE_SIZE,
          tagName: bubble
        });
      } else {
        setSearchRequest({
          page: 0,
          size: PaginationConstant.PAGE_SIZE,
        });
      }
      setBubblesSelected(bubble);
    } else if (bubblesSelected !== 0) {
      setPosts([]);
      setSearchRequest({
        page: 0,
        size: PaginationConstant.PAGE_SIZE,
      });
      setBubblesSelected(null);
    }
  };

  const postElementsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const handleScroll = () => {
      postElementsRef.current.forEach((postElement, index) => {
        const rect = postElement?.getBoundingClientRect();
        const isVisible = rect?.top >= 0 && rect?.bottom <= window.innerHeight;
        if (isVisible && posts) {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            const habbit: any = {
              actionType: CommonConstant.ACTION_TYPE_SCROLL,
              postId: posts[index]?.id,
            };
            HabbitService.getInstance()
              .save(habbit)
              .then((resp) => {
                if (resp.data.status) {
                }
              })
              .catch((err) => { });
          }, 30000);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [getPosts]);



  return (
    <>
      <div className="page-content">
        <div className="container">
          <div
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            {
                bubblesSelected?.id === 2 &&
                <Suggested></Suggested>
              } 
            <div className="post-view-wrap" style={{ width: "100%" }}>
            
              {posts?.length == 0 && isLoaded &&
                <><h4 className="not-post-title">{t("newsfeed.post.notPost")}</h4>
                </>
              }
              {posts?.map((ele: any, idx) => (
                <div
                  key={idx}
                  ref={(element: any) => {
                    postElementsRef.current[idx] = element;
                  }}
                >
                  <PostView
                    key={idx}
                    post={ele}
                    refresh={refreshPosts}
                  ></PostView>
                </div>
              ))}
              {searchRequest.page * searchRequest.size < totalElements && posts.length > 0 && posts.length < totalElements && (
                <div className="block-box load-more-btn">
                  <a className="item-btn" onClick={loadMorePosts}>
                    <i className="icofont-refresh"></i>
                    {t("common.button.loadmore")}
                  </a>
                </div>
              )}
            </div>

            <div className="widget-block widget-break-lg quick-access">
              <div className="widget-bubbles">
                {bubble &&
                  bubble.map((element, index) => (
                    <div
                      key={index}
                      className={
                        "bubble bubble-filter " +
                        (bubblesSelected === element ? "active" : "")
                      }
                      onClick={() => {
                        setNavSelected(element);
                      }}
                    >
                      <i className={element.ico}></i>
                      <span>{lang === TranslationConstant.SOUTH_KOREA ? element.titleKr :
                        lang === TranslationConstant.ENGLISH ? element.titleEn : element.title}</span>
                    </div>
                  ))}
                {trendingCategory && trendingCategory.map((element, index) => (
                  <div
                    key={index}
                    className={
                      "bubble bubble-category " +
                      (bubblesSelected === element.cateName ? "active" : "")
                    }
                    onClick={() => {
                      setNavSelected(element.cateName, "category");
                    }}
                  >
                    <i className="fa fa-tag"></i>
                      <span>{lang === TranslationConstant.SOUTH_KOREA ? element.cateNameKr :
                      lang === TranslationConstant.ENGLISH ? element.cateNameEn : element.cateName}</span>
                  </div >
                ))
}
{
  topHashtags && topHashtags.map((element, index) => (
    <div
      key={index}
      className={
        "bubble bubble-hashtag " +
        (bubblesSelected === element ? "active" : "")
      }
      onClick={() => {
        setNavSelected(element, "hashtag");
      }}
    >
      <span> #{element}</span>
    </div>
  ))
}
              </div >
            </div >
          </div >
        </div >
      </div >
    </>
  );
}
