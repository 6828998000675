import React from 'react'
type AvatarImageProps = {
    atchFleSeq: any;
}
export default function AvatarImage(props: AvatarImageProps) {
    return (
        <>
            <img
                src={
                    process.env.REACT_APP_API_URL +
                    "/public/image-view/" +
                    props.atchFleSeq}
                onError={(event: any) => {
                    event.target.src = require("../../../assets/default-avatar.jpg")
                }}
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
        </>
    )
}
