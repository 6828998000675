import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { AuthUtil } from "../../../../utils/AuthUtil";
import AppTimeAgo from "../../../commons/ui/DateFormat/AppTimeAgo";
import { NotificationService } from "../../../../services/notification/NotificationService";
import AvatarImage from "../../../commons/post/AvatarImage";
import { useTranslation } from "react-i18next";
import { CommonConstant } from "../../../../constants/CommonConstant";

export default function AppNotification() {
  const { t } = useTranslation();
  const [totalNewNotification, setTotalNewNotification] = useState<any>(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    let eventSource = new EventSource(
      process.env.REACT_APP_API_URL +
      "/notification/sse-endpoint?token=" +
      AuthUtil.getToken()
    );

    eventSource.onmessage = (event) => {
      // Xử lý dữ liệu từ máy chủ

      // setNotifications((preState) => {
      //   let temps: any[] = JSON.parse(JSON.stringify(preState));
      //   temps.push(JSON.parse(event.data));
      //   console.log(temps);
      //   return temps;
      // });

      // setTotalNewNotification((preState: any) => preState + 1);
      const data = JSON.parse(event.data);
      showBrowserNotification(data.content);
      getNotifications();
    };

    return () => {
      // Đóng kết nối SSE khi component unmount
      eventSource.close();
    };
  }, []);

  const showBrowserNotification = (content: string) => {
    if ('Notification' in window) {
      // Kiểm tra xem trình duyệt hỗ trợ thông báo hay không
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // Hiển thị thông báo nếu người dùng đã cho phép
          const notification = new Notification('Thông báo từ ứng dụng RNS', {
            body: content,
          });
        }
      });
    } else {
      console.log('Trình duyệt không hỗ trợ thông báo.');
    }
  }

  const handleReadAll = () => {
    NotificationService.getInstance()
      .readAllByUser()
      .then(
        (res) => {
          if (res.data.status) {
            getNotifications();
          }
        },
        (error) => { }
      );
  };

  const getNotifications = () => {
    NotificationService.getInstance()
      .getNotification({})
      .then(
        (res) => {
          if (res.data.status) {
            setNotifications(res.data.responseData?.notifications);
            setTotalNewNotification(res.data.responseData?.totalUnreadNotice);
          }
        },
        (error) => { }
      );
  };

  const redirectToUrl = (url: string) => {
    navigate(url);
  };

  const onclickNoti = () => {
    handleReadAll();
  }

  return (
    <>
      <div className="dropdown dropdown-notification">
        <button
          className="dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-expanded="false"
          onClick={onclickNoti}
        >
          <i className="icofont-notification"></i>
          {totalNewNotification > 0 && (
            <span className="notify-count">
              {totalNewNotification > 99 ? "99+" : totalNewNotification}
            </span>
          )}
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="item-heading">
            <h6 className="heading-title">{t("header.notification.title")}</h6>
            <div className="heading-btn">
              {/* <a href="#">Settings</a> */}
              <a onClick={handleReadAll}>{t("header.notification.readAll")}</a>
            </div>
          </div>
          <div className="item-body scrollbar">
            {notifications.map((ele: any) => (
              <>
                {ele.senderInfo == null && (
                  <div
                    onClick={() => redirectToUrl(ele.url)}
                    key={ele.notiId}
                    className={"media " + (ele.readYn === "N" ? 'unread' : '')}
                  >
                    <NavLink
                      className="item-img"
                      to={"/profile/" + ele.senderInfo?.userUid}
                      style={{ width: "50px", height: "50px" }}
                    >
                      <img
                        src="xemreview_logo_nobg.png"
                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                      />
                    </NavLink>
                    <div className="media-body">
                      {/* <NavLink to={"/profile/" + ele.senderInfo?.userUid}>
                  <h6 className="item-title">{ele.senderInfo?.fullName}</h6>
                </NavLink> */}

                      {ele.content === CommonConstant.MESSAGE_REVIEW_SUCCESS && <p style={{margin:"revert"}}>{t("notification.reviewSuccess")}</p>}
                      {ele.content === CommonConstant.MESSAGE_REVIEW_FAILED && <p style={{margin:"revert"}}>{t("notification.reviewFailed")}</p>}
                    </div>
                    <div className="item-time">
                      <AppTimeAgo date={new Date(ele.createdDate)}></AppTimeAgo>
                    </div>
                  </div>
                )}
                {ele.senderInfo != null && (
                  <div
                    onClick={() => redirectToUrl(ele.url)}
                    key={ele.notiId}
                    className={"media " + (ele.readYn === "N" ? 'unread' : '')}
                  >
                    <NavLink
                      className="item-img"
                      to={"/profile/" + ele.senderInfo?.userUid}
                      style={{ width: "50px", height: "50px" }}
                    >
                      <AvatarImage atchFleSeq={ele.senderInfo?.imgPath}></AvatarImage>
                    </NavLink>
                    <div className="media-body">
                      {/* <NavLink to={"/profile/" + ele.senderInfo?.userUid}>
                  <h6 className="item-title">{ele.senderInfo?.fullName}</h6>
                </NavLink> */}


                      <p>{ele.content}</p>
                    </div>
                    <div className="item-time">
                      <AppTimeAgo date={new Date(ele.createdDate)}></AppTimeAgo>
                    </div>
                  </div>
                )}</>
            ))}
          </div>
          <div className="item-footer">
            <NavLink to="/notification" className="view-btn">
              {t("header.notification.viewAll")}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
