import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { ObjectFavorites } from "../../models/objectFavorites";

export class ObjectFavoritesService {
    private static _objectFavoritesService: ObjectFavoritesService

    public static getInstance(): ObjectFavoritesService {
        if (!ObjectFavoritesService._objectFavoritesService) {
            ObjectFavoritesService._objectFavoritesService = new ObjectFavoritesService()
        }
        return ObjectFavoritesService._objectFavoritesService;
    }

    getFavorites(objectId: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFavorites/getFavorites.exclude?objectId=" + objectId);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    addFavorites(objectFavorites: ObjectFavorites) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFavorites/addFavorites.exclude");
        return axios.post(url,objectFavorites, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    deleteFavorites(objectId: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFavorites/deleteFavorites.exclude?objectId=" + objectId);
        return axios.delete(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    getCount(objectId: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFavorites/getCount.exclude?objectId=" + objectId);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

}

