import axios from "axios";
import { toast } from "react-toastify";
import { AuthUtil } from "../../utils/AuthUtil";
import { t } from "i18next";

axios.interceptors.request.use(req => {
    return req;
});

axios.interceptors.response.use((response: any) => {
    return response;
}, (error: any) => {
    if (error.response && error.response.status === 401) {
        AuthUtil.logout();
    }
    if (error.code === "ERR_NETWORK") {
        // window.location.href = "/#/error-500"
        toast.error(t("toast.serverError"), {toastId: "unique-random-text-xAu9C9-"})
        console.log('Cannot connect to backend....')
    }
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
});