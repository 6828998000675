import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";

export class SettingService {
  private static _settingService: SettingService;

  public static getInstance(): SettingService {
    if (!SettingService._settingService) {
        SettingService._settingService = new SettingService();
    }
    return SettingService._settingService;
  }


  public changePassword(param: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/settings/change-password.exclude"
    );
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getTwoFa() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/settings/get-two-fa.exclude");
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public generateSecretKey() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/settings/generate-secret-key.exclude");
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public verifyOtp(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/settings/verify-otp.exclude", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public verifyPassword(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/settings/verify-pwd.exclude", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

}
