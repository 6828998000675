import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { PostWishList } from "../../models/postWishlist";

export class PostWishlistService {

    private static _postWishlistService: PostWishlistService

    public static getInstance(): PostWishlistService {
        if (!PostWishlistService._postWishlistService) {
            PostWishlistService._postWishlistService = new PostWishlistService()
        }
        return PostWishlistService._postWishlistService;
    }

    getWishlist(request: any) {
        const url = process.env.REACT_APP_API_URL + "/postWishlist/getWishlist.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    addWishlist(postWishlist : PostWishList) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/postWishlist/addWishlist.exclude");
        return axios.post(url,postWishlist, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    getWishlistByPostId(postId : string) {
        const url = process.env.REACT_APP_API_URL + "/postWishlist/getWishlistByPostId.exclude?postId=" + postId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    removeWishlist(postId : string) {
        const url = process.env.REACT_APP_API_URL + "/postWishlist/removeWishlist.exclude?postId=" + postId;
        return axios.delete(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    
}

