import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import "./WriteReview.css";
import RatingForm from "../../commons/form/RatingForm";
import { toast } from "react-toastify";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";
import { PostService } from "../../../services/post/PostService";
import { CategoryService } from "../../../services/category/CategoryService";
import { ObjectService } from "../../../services/object/ObjectService";
import UploadFile from "../../commons/form/UploadFile/UploadFile";
import { FileService } from "../../../services/common/FileService";
import ObjectDropdownCustom from "./components/ObjectDropdownCustom";
import AppModal from "../../commons/ui/Modal/AppModal";
import AddObject from "./components/AddObject";
import { useLocation, useNavigate } from "react-router-dom";
import ChipInput from "../../commons/form/ChipInput/ChipInput";
import { HashtagService } from "../../../services/post/HashtagService";
import { useTranslation } from "react-i18next";
import { TranslationConstant } from "../../../constants/TranslationConstant";
import { useAppSelector } from "../../../hooks/hook";
import ReactQuill from "react-quill";
import TextEditor from "../../commons/form/TextEditor/TextEditor";

type Props = {
  post?: any;
  object?: any;
  refresh?: () => void;
  groupId?: string;
  userId?: string;
  isPopup?: boolean;
};

export default function WriteReview(props: Props) {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.language.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [evalStandards, setEvalStandards] = useState<any[]>([]);
  const [objects, setObjects] = useState<any[]>([]);
  const [hashtags, setHashtags] = useState<any[]>([]);
  const [selectedObject, setSelectedObject] = useState<any>();
  const [isOpenPopupAddObject, setIsOpenPopupAddObject] =
    useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postId = searchParams.get("postId");
  const [postReqUpdate, setPostReqUpdate] = useState<any>(null);

  useEffect(() => {
    if (postId != null) {
      getPostById(postId);
    } else {
      resetForm();
    }
  }, [postId]);

  useEffect(() => {
    getObjects();
    if (props.object) {
      setSelectedObject(props.object);
      getEvalStandard(props.object?.category?.cateId);
    }
    getTags();
  }, []);

  const handleSubmitReview = async (data: any) => {
    let request = Object.assign({}, postReqUpdate);
    if (!validation()) {
      return;
    }
    // let isValidated = false;
    // evalStandards.forEach((ele: any) => {
    //   if (!ele.rating || ele.rating <= 0) {
    //     ele.errorMgs = "No rating yet";
    //     isValidated = true;
    //   } else {
    //     ele.errorMgs = null;
    //   }
    // });
    // if (isValidated) return;

    if (data.files) {
      await FileService.getInstance()
        .uploadMulFile(data.files)
        .then((res) => {
          if (res.data.status) {
            request.attachments = res.data.responseData;
          }
        });
    }

    let evalStandardsFilter =  evalStandards.filter(ele => (ele.rating || ele.rating > 0));

    request.content = data.content;
    request.objectId = selectedObject.id;
    request.ratingProps = evalStandardsFilter;
    request.groupId = props.groupId;
    request.hashtags = data.hashtags;
    request.category = selectedObject.category?.cateName;

    dispatch(showAndHideSpinner(true));
    PostService.getInstance()
      .save(request)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            // toast.success('Bài viết đang được đang tải...')
            if (props.isPopup) {
              props.refresh?.();
            } else {
              navigate("/newsfeed");
            }
          } else {
            toast.warning("An error occurred on the server");
          }
        },
        (error) => {}
      );
  };

  const validation = () => {
    let content = getValues("content");
    if (!content || content.length === 0 || !selectedObject ) {
      if (!content || content.length === 0) {
        setError("content", {
          type: "manual",
        });
      }
      if (!selectedObject) {
        setError("selectedObject", {
          type: "manual",
        });
      }
      return false;
    }

    return true;
  };

  const onChangeObject = (data: any) => {
    if (data) {
      setSelectedObject(data);
      getEvalStandard(data?.category?.cateId);
      clearErrors(["selectedObject"]);
    } else {
      setSelectedObject(null);
      setEvalStandards([]);
    }
  };

  const onChangeUpload = (data: any) => {
    setValue("files", data);
  };

  const onClickEventNoOptions = () => {
    setIsOpenPopupAddObject(true);
  };

  const onClosePopupAddObject = (data: any) => {
    setIsOpenPopupAddObject(false);
    if (data) {
      getObjects();
    }
  };

  const getPostById = (postId: any) => {
    PostService.getInstance()
      .getPostDetail(postId)
      .then(
        (res) => {
          if (res.data.status) {
            setPostReqUpdate(res.data.responseData);
            setValueForm(res.data.responseData);
          }
        },
        (error) => {}
      );
  };

  const setValueForm = (post: any) => {
    setValue("content", post.content);
    setValue("hashtags", post.hashtags);
    setSelectedObject(post.object);
    setEvalStandards(post.ratingProps);
  };

  const resetForm = () => {
    setSelectedObject(null);
    setEvalStandards([]);
    setValue("content", "");
    setPostReqUpdate(null);
  };

  const getEvalStandard = (cateId: any) => {
    CategoryService.getInstance()
      .getEvalStandard(cateId)
      .then(
        (res) => {
          if (res.data.status) {
            setEvalStandards(res.data.responseData);
          }
        },
        (error) => {}
      );
  };

  const getObjects = () => {
    ObjectService.getInstance()
      .getAll()
      .then(
        (res) => {
          setObjects(res.data.responseData);
        },
        (error) => {}
      );
  };

  const getTags = () => {
    HashtagService.getInstance()
      .getAll()
      .then(
        (res) => {
          if (res.data.status) {
            setHashtags(res.data.responseData);
          }
        },
        (err) => {}
      );
  };

  return (
    <>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="forum-topic-add">
                {!props.isPopup && (
                  <h3 className="heading-title">{t("writeReview.title")}</h3>
                )}
                <div
                  className={
                    props.isPopup || props.groupId || props.userId
                      ? ""
                      : "block-box"
                  }
                >
                  <form id="new-post-form">
                    {!props.object && (
                      <div className="form-group row mb-4">
                        <label className="col-sm-2 col-form-label">
                          {t("writeReview.field.reviewsAbout")}
                        </label>
                        <div className="col-sm-10">
                          {!postReqUpdate && (
                            <ObjectDropdownCustom
                              sources={objects}
                              bindValue={"id"}
                              bindLabel={"objectName"}
                              onChange={onChangeObject}
                              onClickEventNoOptions={onClickEventNoOptions}
                              isValid={errors?.selectedObject ? false : true}
                            ></ObjectDropdownCustom>
                          )}

                          {errors?.selectedObject && (
                            <p className="msg-error">
                              {t("writeReview.errors.required.object")}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    {/* {selectedObject && (
                      <div className="user-about">
                        <ul className="user-info">
                          <li>
                            <label>{t("writeReview.field.objectName")}:</label>
                            <p className="object-name">
                              {selectedObject.objectName}
                            </p>
                          </li>
                          <li>
                            <label>{t("writeReview.field.category")}:</label>
                            <p>
                              <button className="button-category" type="button">
                                <i className="fa fa-tag"></i>
                                {selectedObject.category?.cateName}
                              </button>
                            </p>
                          </li>
                          <li>
                            <label>{t("writeReview.field.address")}:</label>
                            <p>{selectedObject.address}</p>
                          </li>
                          <li>
                            <label>{t("writeReview.field.phone")}:</label>
                            <p>
                              {selectedObject.objectDetail?.phoneNumber ||
                                "---"}
                            </p>
                          </li>
                          <li>
                            <label>{t("writeReview.field.email")}:</label>
                            <p>{selectedObject.objectDetail?.email || "---"}</p>
                          </li>
                          <li>
                            <label>{t("writeReview.field.web")}:</label>
                            <p>
                              {selectedObject.objectDetail?.website || "---"}
                            </p>
                          </li>
                          <li>
                            <label>{t("writeReview.field.description")}:</label>
                            <p>{selectedObject.description}</p>
                          </li>
                        </ul>
                      </div>
                    )} */}
                    {evalStandards.length > 0 ? (
                      <div className="form-group row mb-4 p-2">
                        {/* <label>
                          {t("writeReview.field.evaluationCriteria")}
                        </label> */}
                        {evalStandards.map((ele, idx) => (
                          <div
                            key={idx}
                            className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-between py-2 px-5"
                          >
                            <label>
                              {lang === TranslationConstant.SOUTH_KOREA
                                ? ele.propNmKR
                                : lang === TranslationConstant.ENGLISH
                                ? ele.propNmEn
                                : ele.propNm}
                            </label>
                            <RatingForm
                                defaultValue={ele.rating ? ele.rating : 0}
                                onChange={
                                  (value) => {
                                    ele.rating = value;
                                  }
                                  // onChangeRating(ele.propnm, value)
                                }
                                disabled={postReqUpdate ? true : false}
                              ></RatingForm>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="write-review">
                      <ul className="nav nav-tabs row " role="tablist">
                        <li className="nav-item col-6">
                          <a className="text-center nav-link active" data-toggle="tab" href="#TAB_CONTENT" role="tab" aria-selected="true">
                            <i className="icofont-pencil" style={{fontSize: '18px'}}></i> Nội dung bài viết
                          </a>
                        </li>
                        <li className="nav-item col-6" >
                          <a className="text-center nav-link" data-toggle="tab" href="#TAB_IMG" role="tab" aria-selected="false">
                            <i className="icofont-image" style={{fontSize: '18px'}}></i> Tải lên hình ảnh
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="TAB_CONTENT" role="tabpanel">
                          <div className="form-group">
                            {/* <label>{t("writeReview.field.content")}</label> */}
                            {/* <TextEditor></TextEditor> */}
                            <textarea
                              {...register("content")}
                              className={
                                errors.content
                                ? "form-control textarea outline-error"
                                : "form-control textarea"
                              }
                              cols={30}
                              rows={10}
                              name="content"
                            ></textarea>
                            {errors?.content && (
                              <p className="msg-error">
                                {t("writeReview.errors.required.content")}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="TAB_IMG" role="tabpanel">
                          <div className="form-group">
                            {!postReqUpdate && (
                              <UploadFile
                                onChange={onChangeUpload}
                                accept={"image/*"}
                              ></UploadFile>
                            )}
                            {postReqUpdate?.attachments && (
                              <div className="row gutters-20 zoom-gallery zoom-gallery-upload">
                                {postReqUpdate.attachments.map(
                                  (ele: any, idx: any) => (
                                    <div
                                      key={idx}
                                      className="col-lg-3 col-md-4 col-6 upload-file"
                                    >
                                    <div className="preview-image-upload user-group-photo">
                                      <img
                                        src={
                                          process.env.REACT_APP_API_URL +
                                          "/public/image-view/" +
                                          ele.atchFleSeq
                                        }
                                      />
                                      <div className="upload-overlay">
                                        <div
                                          style={{
                                            textAlign: "center",
                                            width: "-webkit-fill-available",
                                            height: "-webkit-fill-available",
                                            marginTop: "20%",
                                            color: "#fff",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>                       
                      </div>
                    </div>                  

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        {t("writeReview.field.hashtag")}
                      </label>
                      <div className="col-sm-10">
                        <ChipInput
                          bindLabel={"hashtagname"}
                          bindValue={"hashtagname"}
                          onChange={(data) => {
                            setValue("hashtags", data);
                          }}
                          value={getValues("hashtags")}
                          suggestions={hashtags}
                        ></ChipInput>
                      </div>
                    </div>

                    

                    <div className="btn-light-c mt-5 text-right">
                      <a className="act-click" onClick={handleSubmit(handleSubmitReview)}>
                        {t("writeReview.button.submit")}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppModal
        open={isOpenPopupAddObject}
        onClose={() => setIsOpenPopupAddObject(false)}
        title={"Add Object"}
      >
        <AddObject onClose={onClosePopupAddObject}></AddObject>
      </AppModal>
    </>
  );
}
