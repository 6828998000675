import { createSlice } from "@reduxjs/toolkit";
import { TranslationConstant } from "../../constants/TranslationConstant";

const initialState = {
  lang:
    localStorage.getItem(TranslationConstant.STORAGE_KEY) == null ||
    localStorage.getItem(TranslationConstant.STORAGE_KEY) == ""
      ? TranslationConstant.VIETNAMESE
      : localStorage.getItem(TranslationConstant.STORAGE_KEY),
};

const multiLangSilce = createSlice({
  name: "lang",
  initialState,
  reducers: {
    changeMultiLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export default multiLangSilce.reducer;
export const { changeMultiLang } = multiLangSilce.actions;


export const langSelector = (state: any) => state.language.lang;
