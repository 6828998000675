import { useTranslation } from "react-i18next";
import "./Header.css";

type HeaderProps = {
  onLogin: () => void;
  onRegister: () => void;
}

export default function Header(props: HeaderProps) {

  const {t} = useTranslation();

  const login = () => {
    props.onLogin();
  };

  const register = () => {
    props.onRegister();
  };

  return (
    <>
      <header className="fixed-header">
        <div className="header-menu">
          <div className="navbar">
            <div className="nav-item d-none d-sm-block">
              {/* <div className="header-logo" style={{height: '75px'}}>
                            RNS logo
                        </div> */}
            </div>
            <div className="nav-item header-search2">
              <div className="input-group">
                <span className="icon-search">
                  <i className="icofont-ui-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("header.search.inputPlaceholder")}
                  onClick={login}
                />
                {/* <div className="input-group-append">
                                <button className="submit-btn" type="button"><i className="icofont-search"></i></button>
                            </div> */}
              </div>
            </div>
            <div className="nav-item header-control auth-action">
              <div
                className="block-box"
                style={{
                  marginRight: "20px",
                }}
              >
                <a className="item-btn-login" onClick={login}>
                  {t("common.button.signIn")}
                </a>
              </div>
              <div className="block-box ">
                <a onClick={register} className="item-btn-login">
                  {t("common.button.signUp")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
