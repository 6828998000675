import React, { useEffect, useState } from 'react'
import { GroupService } from '../../../services/group/GroupService'
import { useNavigate } from 'react-router-dom';

export default function MyGroup(props: any) {

    const navigate = useNavigate();
    const [lstMyGroup, setLstMyGroup] = useState<[]>()

    useEffect(()=>{
        GroupService.getInstance().getMyGroup().then((result: any)=>{
          if(result.data.status){
            setLstMyGroup(result.data.responseData)
          }
        }).catch((error)=>{
          console.log(error);
          
        })
    }, [])

    const redirectGroupDetail = (groupId:any) => {
        navigate("/groups/" + groupId)
    }

    const loadMoreGroups = ()=>{

    }


  return (
    <div id="user-view" className="user-grid-view">
    <div className="row gutters-20">
      {lstMyGroup && lstMyGroup?.length > 0 ?
        <>
          {lstMyGroup.map((groupMap: any) => (
            <div className="col-xl-4 col-lg-4 col-md-6" key={groupMap.groupInf?.id}>
              <div className="widget-author user-group">
                <div className="author-heading">
                  <div className="cover-img">
                    <img src={process.env.REACT_APP_API_URL +
                        "/public/image-view/" +
                        groupMap.groupInf?.coverImage?.atchFleSeq}
                        onError={(event: any) => {
                          event.target.src = require("../../../assets/cover_1.jpg")
                        }}
                        style={{
                          objectPosition: groupMap.groupInf?.coverImage && `${groupMap.groupInf?.coverImage?.positionX * 100}% ${groupMap.groupInf?.coverImage?.positionY * 100}%`,
                          objectFit: 'cover',
                        }} alt="cover" />
                  </div>
                  <div className="group-img">
                    <a onClick={() => redirectGroupDetail(groupMap.groupInf?.id)}>
                      <img style={{
                      objectPosition: groupMap.groupInf?.avatarImage && `${groupMap.groupInf?.avatarImage?.positionX * 100}% ${groupMap.groupInf?.avatarImage?.positionY * 100}%`,
                      objectFit: 'cover',
                      width: "100px",
                      height: "100px",
                    }} src={process.env.REACT_APP_API_URL +
                      "/public/image-view/" +
                      groupMap.groupInf?.avatarImage?.atchFleSeq} alt="author" 
                      onError={(event: any) => {
                        event.target.src = require("../../../assets/default-group.png")
                      }}/>
                    </a>
                  </div>
                  <div className="profile-name">
                    <h4 className="author-name"><a onClick={() => redirectGroupDetail(groupMap.groupInf?.id)}>{groupMap.groupInf?.groupName}</a></h4>
                    {/* <div className="author-location">@tourist</div> */}
                  </div>
                </div>
                <ul className="author-statistics mt-4">
                  <li>
                    <a href="#"><span className="item-number">{groupMap.numPost}</span> <span className="item-text">GROUP POSTS</span></a>
                  </li>
                  <li>
                    <a href="#"><span className="item-number">{groupMap.groupInf?.members.length}</span> <span className="item-text">ALL MEMBERS</span></a>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </>


        :
        <div className="col-md-12 text-center">
          <h5>No data</h5>
        </div>
      }
    </div>
    {/* {lstMyGroup && lstMyGroup.length > 0 &&
      <div className="block-box load-more-btn">
        <a className="item-btn" onClick={loadMoreGroups}>
          <i className="icofont-refresh"></i>Load More
        </a>
      </div>} */}
  </div>
  )
}
