import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ObjectService } from "../../../services/object/ObjectService";
import { Object } from "../../../models/object";
import { useCompareHandler } from "../../layout/SidebarRight/SidebarRight";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { refreshCompare } from "../../../redux/reducers/compareSlice";
import { ObjectFollowService } from "../../../services/object/ObjectFollowService";
import { ObjectFollow } from "../../../models/objectFollow";
import { toast } from "react-toastify";
import Home from "./Home";
import Video from "./Video";
import Photo from "./Photo";
import About from "./About";
import { ObjectFavoritesService } from "../../../services/object/ObjectFavoritesService";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";
import WriteReview from "../WriteReview/WriteReview";
import AppModal from "../../commons/ui/Modal/AppModal";
import AvatarEditor from 'react-avatar-editor'
import { FileService } from "../../../services/common/FileService";
import AppAvatarEditor from "../../commons/ui/AppAvatarEditor";
import React from "react";
import ImageModal from "../../commons/ui/ImageModal/ImageModal";
import { useTranslation } from "react-i18next";
import { CommonConstant } from "../../../constants/CommonConstant";
import { TranslationConstant } from "../../../constants/TranslationConstant";
export default function ObjectPage() {
  const lang = useAppSelector((state) => state.language.lang);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [object, setObject] = useState<any>();
  const [isFollowing, setIsFollowing] = useState<any>();
  const [isLike, setIsLike] = useState<any>();
  const [followCount, setFollowCount] = useState<number>();
  const [likeCount, setLikeCount] = useState<number>();
  const [lstPost, setLstPost] = useState<[]>();
  const { handleAddCompareClick } = useCompareHandler();
  const [tabSelected, setTabSelected] = useState<any>(0)
  const dispatch = useAppDispatch()
  const [isOpenWriteReviewPopup, setIsOpenWriteReviewPopup] = useState(false);
  const [isOpenUploadAvatar, setIsOpenUploadAvatar] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<any>();
  const fileCoverInputRef = useRef<any>();
  const elementRef = useRef<any>();
  const [fileCover, setFileCover] = useState<File>();
  const [position, setposition] = useState<any>({ x: 0, y: 0 });
  const editor = React.createRef<AvatarEditor>()
  const myInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [isOpen, setIsOpen] = useState(false)
  const [srcImageModal, setSrcImageModal] = useState(false)
  useEffect(() => {
    dispatch(showAndHideSpinner(true));
    _getObject()
  }, [id])
  function _getObject() {
    ObjectService.getInstance().getObjectById(id).finally(() => {
      setTimeout(() => {
        dispatch(showAndHideSpinner(false));
      }, 500);
    }).then(resp => {
      if (resp.data.status) {
        setObject(resp.data.responseData.object);
        setLstPost(resp.data.responseData.lstPost);
        setIsFollowing(resp.data.responseData.isFollowing)
        setIsLike(resp.data.responseData.isLike);
        setFollowCount(resp.data.responseData.object.countFollow);
        setLikeCount(resp.data.responseData.object.countLike)
      }
    })
      .catch(err => {
        // console.log(err);

      })
  }
  function _getFollow() {
    ObjectFollowService.getInstance().getFollow(id)
      .then(resp => {
        if (resp.data.status) {
          setIsFollowing(resp.data.responseData);
        }
      })
      .catch(err => {
        // console.log(err);
      })
  }
  useEffect(() => {
  }, [object])

  function _getCountFollow() {
    ObjectFollowService.getInstance().getCount(id)
      .then(resp => {
        if (resp.data.status) {
          setFollowCount(resp.data.responseData);
        }
      })
      .catch(err => {
        // console.log(err);
      })
  }
  function _getLike() {
    ObjectFavoritesService.getInstance().getFavorites(id)
      .then(resp => {
        if (resp.data.status) {
          setIsLike(resp.data.responseData);
        }
      })
      .catch(err => {
        // console.log(err);
      })
  }
  function _getCountLike() {
    ObjectFavoritesService.getInstance().getCount(id)
      .then(resp => {
        if (resp.data.status) {
          setLikeCount(resp.data.responseData);
        }
      })
      .catch(err => {
        // console.log(err);
      })
  }
  function handleAddFollow(object: Object | undefined) {
    if (object) {
      ObjectFollowService.getInstance().addFollow(new ObjectFollow(object.id)).then(resp => {
        if (resp.data.status) {
          toast.info(t("toast.followObject"))
          _getFollow();
          _getCountFollow()
        }
      })
        .catch(err => {
          // console.log(err);
        })
    }
  }

  function handleDeleteFollow(object: Object | undefined) {
    if (object) {
      ObjectFollowService.getInstance().deleteFollow(object.id).then(resp => {
        if (resp.data.status) {
          toast.info(t("toast.disFollowObject"))
          _getFollow();
          _getCountFollow()
        }
      })
        .catch(err => {
          // console.log(err);
        })
    }
  }

  function handleLike(object: Object | undefined) {

    if (object) {
      ObjectFavoritesService.getInstance().addFavorites(new ObjectFollow(object.id)).then(resp => {
        if (resp.data.status) {
          _getLike();
          handleAddFollow(object);
          _getCountLike();
        }
      })
        .catch(err => {
          // console.log(err);
        })
    }
  }

  function handleDislike(object: Object | undefined) {
    if (object) {
      ObjectFavoritesService.getInstance().deleteFavorites(object.id).then(resp => {
        if (resp.data.status) {
          _getLike();
          handleDeleteFollow(object);
          _getCountLike();
        }
      })
        .catch(err => {
          // console.log(err);
        })
    }
  }

  const onWriteReview = () => {
    setIsOpenWriteReviewPopup(true);
  }

  const onUploadAvatar = () => {
    if (myInfo?.rolesStr?.includes("R000")) {
      setIsOpenUploadAvatar(true);
    } else {
      setSrcImageModal(object?.avatarImage?.atchFleSeq)
      setIsOpen(true)
    }
  }

  const onCloseWriteReviewPopup = () => {
    setIsOpenWriteReviewPopup(false);
  }
  const onCloseOpenUploadAvatarPopup = () => {
    setIsOpenUploadAvatar(false);
  }
  const refreshObject = () => {
    onCloseWriteReviewPopup();
    _getObject();
  }

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    setFileCover(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (previewUrl) {
      elementRef.current.classList.add('disable-pointer-events');
    } else {
      elementRef.current.classList.remove('disable-pointer-events');
    }
  }, [previewUrl]);
  const handleButtonClick = () => {
    fileCoverInputRef.current.click();
  };
  const cancelUpload = () => {
    setPreviewUrl(null);
  }
  const saveImageCover = async (data: any) => {
    if (fileCover && editor) {
      await FileService.getInstance()
        .uploadFile(fileCover)
        .then((res) => {
          if (res.data.status) {
            data.coverImage = {}
            data.coverImage.atchFleSeq = res?.data?.responseData?.atchFleSeq;
            const rect = editor.current?.getCroppingRect();
            if (rect) {
              data.coverImage.positionX = rect.x / (1.001 - rect.width);
              data.coverImage.positionY = rect.y / (1.001 - rect.height);
            } else {
              data.coverImage.positionX = 0;
              data.coverImage.positionY = 0;
            }
          }
        });
    }
    dispatch(showAndHideSpinner(true));
    ObjectService.getInstance().save(data)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            toast.success(t("toast.success"));
            _getObject();
            setPreviewUrl(null);
          } else {
            toast.success(t("toast.failed"));
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  }
  const saveAvatar = (file: any) => {
    let data = object;
    if (file.atchFleSeq) {
      data.avatarImage = {}
      data.avatarImage.atchFleSeq = file.atchFleSeq;
    }
    dispatch(showAndHideSpinner(true));
    ObjectService.getInstance().save(data)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            toast.success(t("toast.success"));
            _getObject();
            setIsOpenUploadAvatar(false);
          } else {
            toast.success(t("toast.failed"));
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  }
  const handlePositionChange = (position: any) => {
    setposition(position)
  };
  const seeMoreImg = () => {
    setTabSelected(1)
  }
  return (
    <>
      {isOpen && (
        <ImageModal
          src={srcImageModal}
          alt="snow"
          caption=""
          onClose={() => setIsOpen(false)}
        />
      )}
      <AppModal open={isOpenWriteReviewPopup} title={"Đánh giá " + object?.objectName} onClose={onCloseWriteReviewPopup}>
        <div className="write-review-modal">
          <div
            className="modal-body"
            style={{ maxHeight: "700px", overflowY: "scroll" }}
          >
            <WriteReview object={object} refresh={refreshObject} isPopup={true} />
          </div>
        </div>
      </AppModal>
      <div className="edit-avatar-modal">
        <AppModal open={isOpenUploadAvatar} title={"Cập nhật ảnh đại diện"} onClose={onCloseOpenUploadAvatarPopup}>
          <AppAvatarEditor
            border={50}
            height={250}
            width={250}
            image={
              object?.avatarImage?.atchFleSeq}
            borderRadius={100}
            rotate={0}
            onClose={saveAvatar}
          />
        </AppModal>
      </div>

      <div className="page-content object-page-content">
        <div className="container">
          <div className="banner-user" style={{
            backgroundImage: `url(${previewUrl != null ? previewUrl : process.env.REACT_APP_API_URL +
              "/public/image-view/" +
              object?.coverImage?.atchFleSeq})`, backgroundRepeat: 'no-repeat', backgroundPosition: `${object?.coverImage?.positionX * 100}% ${object?.coverImage?.positionY * 100}%`
          }}>
            {previewUrl != null && <>
              <div className="preview-image-cover">
                <AvatarEditor
                  ref={editor}
                  image={previewUrl}
                  height={342}
                  width={1170}
                  border={0}
                  position={position}
                  scale={1}
                  rotate={0}
                  onPositionChange={handlePositionChange}
                />
                <div className="button-image-cover-arrow" role="button">
                  <i className="fa fa-arrows"></i>{t("object.button.drag")}
                </div></div></>}
            {myInfo?.rolesStr?.includes("R000") && <div className="image-cover">
              {previewUrl == null && <>
                <input type="file" ref={fileCoverInputRef} style={{ display: 'none' }} onChange={handleImageUpload} />
                <div className="button-image-cover" role="button" onClick={handleButtonClick}>
                  <i className="fa fa-camera"></i>{t("object.button.upload.cover")}
                </div>
              </>}
              {previewUrl != null && <>
                <div className="button-image-cover" style={{ marginRight: "5px" }} role="button" onClick={cancelUpload}>
                  {t("common.button.cancel")}
                </div>
                <div className="button-image-cover" role="button" onClick={() => saveImageCover(object)} >
                  {t("common.button.save")}
                </div>
              </>}
            </div>}
            {!myInfo?.rolesStr?.includes("R000") && <div className="image-cover">
              <div className="button-image-cover" role="button" onClick={() => { setSrcImageModal(object?.coverImage?.atchFleSeq); setIsOpen(true) }}>{t("object.button.see.cover")}
              </div>
            </div>}
            <div ref={elementRef} className="banner-content">
              <div className="media" style={{ alignItems: 'baseline' }}>
                <div className="item-img" style={{
                  width: "115px", height: "115px",
                }} >
                  <img onClick={() => {
                    onUploadAvatar()
                  }} style={{
                    objectPosition: `${object?.avatarImage?.positionX * 100}% ${object?.avatarImage?.positionY * 100}%`,
                    objectFit: 'cover',
                    width: "100%",
                    height: "100%",
                  }} src={process.env.REACT_APP_API_URL +
                    "/public/image-view/" +
                    object?.avatarImage?.atchFleSeq}
                    onError={(event: any) => {
                      event.target.src = require("../../../assets/default-avatar.jpg")
                    }} />
                </div>
                {object && <div className="media-body">
                  <h3 className={"item-title " + (object?.legal ? "legal" : "")}>{object?.objectName}</h3>
                  <div className="item-subtitle">
                    <button className="button-category" role="button"><i className="fa fa-tag">
                    </i>{lang === TranslationConstant.SOUTH_KOREA ? object?.category?.cateNameKr :
                      lang === TranslationConstant.ENGLISH ? object?.category?.cateNameEn : object?.category?.cateName}</button>
                  </div>
                  <ul className="user-meta">
                    <li>{t("object.info.count.likes")}: <span>{likeCount ? likeCount : 0}</span></li>
                    <li>{t("object.info.count.posts")}: <span>{lstPost?.length ? lstPost?.length : 0}</span></li>
                    <li>{t("object.info.count.followers")}: <span>{followCount ? followCount : 0}</span></li>
                  </ul>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="row object-menu-list" style={{ padding: '0 15px' }}>
            <div className="user-top-header col-lg-7 p-0">
              <ul className="menu-list">
                <li className={tabSelected === 0 ? 'active' : ''} onClick={() => { setTabSelected(0) }}><a>{t("object.menu.home")}</a></li>
                <li className={tabSelected === 1 ? 'active' : ''} onClick={() => { setTabSelected(1) }}><a>{t("object.menu.image")}</a></li>
                <li className={tabSelected === 2 ? 'active' : ''} onClick={() => { setTabSelected(2) }}><a>{t("object.menu.video")}</a></li>
                <li className={tabSelected === 3 ? 'active' : ''} onClick={() => { setTabSelected(3) }}><a>{t("object.menu.introduce")}</a></li>
                {/* <li>
                  <div className="dropdown">
                    <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      ...
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#">Shop</a>
                      <a className="dropdown-item" href="#">Blog</a>
                      <a className="dropdown-item" href="#">Others</a>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className="user-top-header-btn col-lg-5 d-lg-block p-0" style={{ marginBottom: '30px' }}>
              <div className="post-input-tab d-flex align-items-start justify-content-end h-100">
                <div className="post-footer p-0">
                  {/* <div className="submit-btn">
                    <a onClick={() => handleCompareClick(object)}>So sánh</a>
                  </div> */}
                  <div className="submit-btn">
                    {!isLike &&
                      <a onClick={() => {
                        handleLike(object);
                        dispatch(refreshCompare(new Date().getTime()))
                      }} ><i className="icofont-like"></i>{t("object.menu.like")}</a>
                    }
                    {isLike &&
                      <>
                        <a className="submit-btn did" onClick={() => {
                          handleDislike(object);
                          dispatch(refreshCompare(new Date().getTime()))
                        }} type="button" ><i className="icofont-like"></i>{t("object.menu.liked")}</a>
                        {/* <div className="dropdown-menu dropdown-menu-right" style={{ padding: "0", minWidth: "9rem" }}>
                          <button className="dropdown-item text-center" onClick={() => {
                            handleDislike(object);
                            dispatch(refreshCompare(new Date().getTime()))
                          }}>Bỏ thích</button>
                        </div> */}
                      </>
                    }
                  </div>
                  <div className="submit-btn">
                    <a onClick={() => {
                      onWriteReview()
                    }}><i className="icofont-pencil"></i>{t("object.menu.write")}</a>
                  </div>
                  <div className="btn-more">
                    <a className="submit-btn-more dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><div>...</div></a>
                    <div className="dropdown-menu dropdown-menu-right">
                      {!isFollowing &&
                        <a className="dropdown-item" onClick={() => {
                          handleAddFollow(object);
                          dispatch(refreshCompare(new Date().getTime()))
                        }} ><i className="icofont-toggle-off" aria-hidden="true"></i>{t("object.menu.follow")}</a>
                      }
                      {isFollowing &&
                        <a className="dropdown-item" style={isFollowing && { color: "var(--light-color)" }} onClick={() => {
                          handleDeleteFollow(object);
                          dispatch(refreshCompare(new Date().getTime()))
                        }}><i className="icofont-toggle-on" aria-hidden="true"></i>{t("object.menu.followed")}</a>
                      }
                      <a className="dropdown-item" onClick={() => {
                        handleAddCompareClick(object);
                        dispatch(refreshCompare(new Date().getTime()))
                      }}><i className="icofont-rounded-expand" aria-hidden="true"></i>{t("object.menu.compare")}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tabSelected === 0 && <Home data={{ object: object, followCount: followCount, lstPost: lstPost }} onSeeMoreImg={seeMoreImg} refreshObject={refreshObject} />}
          {tabSelected === 1 && <Photo data={{ lstPost: lstPost }} />}
          {tabSelected === 2 && <Video />}
          {tabSelected === 3 && <About data={{ object: object, followCount: followCount }} />}
        </div >
      </div >
    </>
  );
}