import { useState } from "react";
import Resizer from "react-image-file-resizer";
import { BarLoader } from "react-spinners";
import "./UploadFile.css";

const override: any = {
  position: "absolute",
  display: "block",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2
};

type UploadFileProps = {
  onChange?: (data?: any) => void;
  isMulti?: boolean;
  defaultValue?: any,
  accept?: any
}

export default function UploadFile(props: UploadFileProps) {

  const [filesRoot, setFilesRoot] = useState<any[]>([]);
  const [filesUpload, setFilesUpload] = useState<any[]>([]);

  const [filesToDelete, setFilesToDelete] = useState<any[]>([]); 
  const [filesToAdd, setFilesToAdd] = useState<any[]>([]);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const handleChange = async (event: any) => {
    showAndHideSpinner(true);
    var files = event.target.files;

    if (files) {
      let filesRootTemp = [...filesRoot, ...files];
      let fileListTemp = [...filesUpload, ...files];

      
      let resizePromises = fileListTemp.map(ele => {
        return resizeFile(ele);
      });

      let filesResized: any[] = []; 
      await Promise.all(resizePromises).then((resizeImages) => {
        filesResized = resizeImages;
      });
      
      setTimeout(() => {
        showAndHideSpinner(false);
      }, 200);

      setFilesUpload(filesResized);
      setFilesRoot(filesRootTemp);
      props.onChange?.(filesResized);
    }
   
  };

  //Thay đổi kích thước ảnh
  const resizeFile = (file: any) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  }

  const getFileType = (file: any) => {

  }
    

  const onSelectFile = () => {
    document.getElementById("file-upload-input")?.click();
  }

  const handleDeleteImage = (idx: any) => {
    let filesRootTemp = filesRoot.filter((ele, index) => index !== idx);
    let filesUploadTemp = filesUpload.filter((ele, index) => index !== idx);
    setFilesRoot(filesRootTemp);
    setFilesUpload(filesUploadTemp);

    props.onChange?.(filesUploadTemp);
  };

  //Hiển thị kích thước ảnh
  const humanFileSize = (size: any) => {
    if (size < 1024) return size + ' B'
    let i = Math.floor(Math.log(size) / Math.log(1024))
    let num: any = (size / Math.pow(1024, i))
    let round = Math.round(num)
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
    return `${num} ${'KMGTPEZY'[i-1]}B`
  }

  const showAndHideSpinner = (type: boolean) => {
    setIsShowSpinner(type);
  }

  return (
    <>
      <input id="file-upload-input" type="file" onChange={(event) => handleChange(event)} style={{display: 'none'}} accept={props.accept || "*"} multiple/>
      <div className="upload-file">
        <div className="dropzone dz-clickable mb-3" onClick={onSelectFile} >
          <div className="dz-message needsclick">
            <div className="mb-3">
              <i className="icofont-upload-alt" style={{fontSize: '35px'}}></i>
              {/* <i className="icofont-camera"></i> */}
            </div>
            <h4 className="text-dark-c">Click to upload.</h4>
          </div>
        </div>
        {
          isShowSpinner && 
          <div className="overlay-upload">
          <BarLoader 
            color={"#fd8535"}
            loading={true}
            cssOverride={override}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>
        }
        
      </div>

      <div className="row gutters-20 zoom-gallery zoom-gallery-upload">
        {filesUpload.map((ele, idx) => (
          <div key={idx} className="col-lg-3 col-md-4 col-6 upload-file">
            <div className="preview-image-upload user-group-photo">
              <img src={URL.createObjectURL(ele)} alt={ele.name} />
              <div className="upload-overlay">
                <div style={{textAlign: 'center', width: '-webkit-fill-available', height: '-webkit-fill-available', marginTop: '20%', color: "#fff"}}>
                  <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{ele.name}</div>
                  <div>{humanFileSize(ele.size)}</div>
                </div>
              </div>
            </div>
            <div className="action-icon">
              <a onClick={() => handleDeleteImage(idx)}>
                <i
                  style={{ fontSize: "15px", color: "var(--dark-color)" }}
                  className="icofont-close"
                ></i>
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
