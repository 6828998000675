import i18n from 'i18next';
import { TranslationConstant } from '../../constants/TranslationConstant';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import en from '../../i18n/lang/en.json';
import kr from '../../i18n/lang/kr.json';
import vi from '../../i18n/lang/vi.json';

// the translations
const resources = {
    en: {
        translation: en
    },
    kr: {
        translation: kr
    },
    vi: {
        translation: vi
    }
};

i18n
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: localStorage.getItem(TranslationConstant.STORAGE_KEY) || TranslationConstant.VIETNAMESE,
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;

