import React, { Component } from 'react'
import { ApiUrlUtil } from '../../utils/ApiUrlUtil';
import axios from 'axios';
import { HeadersUtil } from '../../utils/HeadersUtil';

export default class ReportService {
    private static _reportService: ReportService

    public static getInstance(): ReportService {
        if (!ReportService._reportService) {
            ReportService._reportService = new ReportService()
        }
        return ReportService._reportService;
    }

    getReportType() {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/reportType/getReportType.exclude");
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    addPostReport(request: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/postReport/addPostReport.exclude");
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}
