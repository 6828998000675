import { useEffect, useState } from "react";
type ModalProps = {
  onClose?: (data?: any) => void;
  open: any;
  title?: any;
  iconUrl?: any;
  children: any;
  size?: string; //xl,lg,sm
};

export default function AppModal(props: ModalProps) {
  const { open, title, iconUrl, children, onClose, size } = props;

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const escFunction = (event: any) => {
      if (event.key === "Escape") {
        onClickClose();
      }
    };

    document.addEventListener("keydown", escFunction, false);
    setIsShow(open);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [open]);

  const onClickClose = () => {
    setIsShow(false);
    onClose?.(false);
  };
  useEffect(() => {
    document.body.style.overflow = isShow ? 'hidden' : 'unset';
 }, [isShow]);
  return (
    <>
      {isShow && (
        <div
          className="modal fade show "
          style={{ display: "block", }}
        >
          <div className="overlay" onClick={onClickClose}></div>
          <div
            className={"modal-dialog modal-dialog-centered post-detail " + (size ? ("modal-" + size) : "modal-xl")}
            style={{ zIndex: 999 }}
          >
            <div className="modal-content">
              <div className={"modal-header post-detail" + title?"":"p-0"  }>
                <h5 className="modal-title fs-5 text-center text-default-color">{title}</h5>
                {iconUrl && <img src={iconUrl}></img>}
                <button
                  type="button"
                  className="close post-detail"
                  onClick={onClickClose}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
