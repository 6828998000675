import React, { useEffect, useState } from 'react'
import { ReviewerService } from '../../../../services/reviewer/ReviewerService';
import { showAndHideSpinner } from '../../../../redux/reducers/spinnerSlice';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { PaginationConstant } from '../../../../constants/PaginationConstant';
import { t } from 'i18next';
export default function Suggested(props: any) {

    const dispatch = useDispatch();
    const [topUser, setTopUser] = useState<any[]>([]);
    useEffect(() => {
        getTopUser();
    }, []);
    const getTopUser = () => {
        ReviewerService.getInstance()
            .getTopUser({
                size: PaginationConstant.PAGE_SIZE
            })
            .finally(() => {
                setTimeout(() => {
                    dispatch(showAndHideSpinner(false));
                }, 500);
            })
            .then((res) => {
                setTopUser(res.data.responseData)
            });
    };
    const handleFollow = (element: any, actionType: boolean) => {
        const param = {
            reviewer: element.user.userUid,
            actionType: actionType
        }
        ReviewerService.getInstance().handleFollow(param).then((result: any) => {
            if (result.data.status) {
                const updatedTopUser = topUser.map((user) => {
                    if (user.user.userUid === element.user.userUid) {
                        return {
                            ...user,
                            isFollowing: actionType
                        };
                    }
                    return user;
                });

                setTopUser(updatedTopUser);
            }
        }).catch((error: any) => {

        })
    }
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };
    return (
        <>
            {topUser?.length > 0 && <span className="white">Gợi ý cho bạn</span>}
            <div id="user-view" className="user-grid-view suggested" style={{margin: "0 -5px"}}>
                <Carousel responsive={responsive}>
                    {topUser?.map((element: any, index) => (
                        <div key={index} className="carousel-img">
                            <div className="widget-author">
                                <div className="author-heading">
                                    <div className="cover-img">
                                        <img src={process.env.REACT_APP_API_URL +
                                            "/public/image-view/" +
                                            element?.user?.coverImage?.atchFleSeq}
                                            onError={(event: any) => {
                                                event.target.src = require("../../../../assets/default-cover.png")
                                            }} alt="cover" />
                                    </div>
                                    <div className="profile-img">
                                        <a>
                                            <img src={process.env.REACT_APP_API_URL +
                                                "/public/image-view/" +
                                                element?.user?.avatarImage?.atchFleSeq}
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../../assets/default-avatar.jpg")
                                                }} alt="author" />
                                        </a>
                                    </div>
                                    <div className="profile-name">
                                        <h4 className="author-name"> <NavLink to={"/profile/" + element?.user.userUid}>
                                            {element?.user?.fullName}</NavLink></h4>
                                    </div>
                                </div>
                                <ul className="author-statistics">
                                    <li>
                                        <a><span className="item-number">{element?.postNum}</span> <span className="item-text"><i className="fa fa-newspaper-o"></i></span></a>
                                    </li>
                                    <li>
                                        <a><span className="item-number">{element?.followCount}</span> <span className="item-text"><i className="fa fa-rss" aria-hidden="true"></i></span></a>
                                    </li>
                                </ul>
                                {element?.isFollowing === false ?
                                    <div className="btn btn-info" onClick={() => handleFollow(element, true)} style={{ marginTop: "5px", fontSize:"10px" }}><a>{t("object.menu.follow")}</a></div>
                                    :
                                    <div className="submit-btn">
                                        <div className="btn btn-info did" onClick={() => handleFollow(element, false)} style={{ marginTop: "5px",fontSize:"10px" }}><a>{t("object.menu.followed")}</a></div>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </>
    )
}
