import React, { useEffect, useState } from 'react'
import { GroupService } from '../../../../services/group/GroupService';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function GroupInfoJoinRequest(props:any) {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
    const groupId = params.id;
    const [lstJoinRequest, setLstJoinRequest] = useState<[]>()
    

    useEffect(()=>{
        GroupService.getInstance().getMembersInfo({lstMembers: props.data.lstJoinRequest}).then((result:any)=>{
          if(result.data.status){
            setLstJoinRequest(result.data.responseData)
          }
        }).catch((error:any)=>{
          console.log("Error: " + error);
      })
    }, [props])

    const changeUser = (uid:any)=>{
       navigate("/profile/" + uid)
    }

    const approve = (userUid:any)=>{
      GroupService.getInstance().approveRequestJoin({userUid:userUid, groupId: groupId}).then((response:any)=>{
        if(response.data.status){
          toast.success(t("group.message.apv-request"))
          props.updateData();
        }else{
          toast.error(t("group.message.apv-request-err"))
        }
      }).catch(()=>toast.error(t("common.message.server-error")))
    }

    const reject = (userUid:any)=>{
      GroupService.getInstance().rejectRequestJoin({userUid:userUid, groupId: groupId}).then((response:any)=>{
        if(response.data.status){
          toast.success(t("group.message.reject-request"))
          props.updateData();
        }else{
          toast.error(t("group.message.reject-request-err"))
        }
      }).catch(()=>toast.error(t("common.message.server-error")))
    }
  return (
    <>
            <div className="user-list-view forum-member">
              {
                lstJoinRequest && lstJoinRequest.length > 0 ?
                  <>
                    {lstJoinRequest.map((user: any) => (
                        <>
                            <div className="widget-author block-box" key={user.userUid}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                        <img src="media/figure/cover_1.jpg" alt="cover" />
                                    </div>
                                    <div className="group-img">
                                        <a onClick={()=>changeUser(user.userUid)}>
                                            <img style={{ width: "100px", height: "100px" }} src={user?.imgPathBase64 || ""} onError={(event: any) => {
                                                event.target.src = "media/default-avatar.jpg"
                                            }} alt="author" />
                                        </a>
                                    </div>
                                    <div className="profile-name mx-2" style={{cursor: "pointer"}} onClick={()=>changeUser(user.userUid)}>
                                        <h4 className="author-name text-dark-c">
                                          <>{user.fullName}</>
                                          { user.legal && <i className="icofont-check legal mx-1" style={{ borderRadius: "50%", color: "var(--deep-lilac-color)", border: "2px solid var(--deep-lilac-color)"}}></i>}
                                        </h4>
                                        <div className="author-location">{user.email}</div>
                                    </div>
                                </div>
                                <ul className="author-badge">
                                    <li><a href="#" className="bg-salmon-gradient"><i className="icofont-star"></i></a></li>
                                    <li><a href="#" className="bg-sun-gradient"><i className="icofont-squirrel"></i></a></li>
                                    <li><a href="#" className="bg-jungle-gradient"><i className="icofont-rocket-alt-1"></i></a></li>
                                </ul>
                                <div>
                                  <button className='btn btn-danger mr-3' onClick={()=>reject(user.userUid)}>{t("group.button.reject")}</button>
                                  <button className='btn btn-success' onClick={()=>approve(user.userUid)}>{t("group.button.accept")}</button>
                                </div>
                            </div>
                        </>
                    ))}                     
                  </>
             
                :
                <>
                  <div className="text-center">
                    <h5>{t("group.text.no-data")}</h5>
                  </div>
                </>
              }

            </div>
        </>
  )
}

