import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { PostReadLater } from "../../models/postReadLater";

export class PostReadLaterService {

    private static _postReadLaterService: PostReadLaterService

    public static getInstance(): PostReadLaterService {
        if (!PostReadLaterService._postReadLaterService) {
            PostReadLaterService._postReadLaterService = new PostReadLaterService()
        }
        return PostReadLaterService._postReadLaterService;
    }

    getReadLater(request: any) {
        const url = process.env.REACT_APP_API_URL + "/postReadLater/getReadLater.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    addReadLater(postReadLater : PostReadLater) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/postReadLater/addReadLater.exclude");
        return axios.post(url,postReadLater, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    getReadLaterByPostId(postId : string) {
        const url = process.env.REACT_APP_API_URL + "/postReadLater/getReadLaterByPostId.exclude?postId=" + postId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    removeReadLater(postId : string) {
        const url = process.env.REACT_APP_API_URL + "/postReadLater/removeReadLater.exclude?postId=" + postId;
        return axios.delete(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    
}

