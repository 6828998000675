import { useEffect, useState } from "react";
import { CommentService } from "../../../../services/post/CommentService";
import { useSelector } from "react-redux";
import SubComment from "./SubComment/SubComment";
import { currentUserSelector } from "../../../../redux/reducers/userSlice";
import { CommonConstant } from "../../../../constants/CommonConstant";
import { HabbitService } from "../../../../services/habbit/HabbitService";

type CommentProps = {
  postId: any,
  refresh: () => void,
  commentList: any,
  moveToComment: any
}
export default function Comment(props: CommentProps) {

  const currentUserInfo = useSelector(currentUserSelector);

  const [postId, setPostId] = useState(props.postId);
  const [commentList, setCommentList] = useState<any[]>(props.commentList);
  const [moveToComment, setMoveToComment] = useState();

  useEffect(() => {
    setCommentList(props.commentList);
  }, [props.commentList]);

  useEffect(() => {
    setPostId(props.postId);
  }, [props.postId])

  useEffect(() => {
    setMoveToComment(props.moveToComment);
  }, [props.moveToComment]);

  const handleSubmitComment = (data: any) => {
    data.postId = postId;
    data.attachments = null;
    CommentService.getInstance().save(data).then(res => {
      if (res.data.status) {
        setMoveToComment(res.data.responseData.id);
        props.refresh();
      }
    }, error => { });
    //collect rabbit
    const habbit: any = {
      actionType: CommonConstant.ACTION_TYPE_COMMENT,
      postId: postId,
    }
    HabbitService.getInstance().save(habbit).then(resp => {
      if (resp.data.status) {
        // console.log(resp);
      }
    })
      .catch(err => {
      })
  };

  return (
    <>
      <div className="post-comment">
        <ul className="comment-list">
          <SubComment commentList={commentList} onSubmitComment={handleSubmitComment} currentUser={currentUserInfo} refresh={props.refresh} moveToComment={moveToComment}></SubComment>
        </ul>
      </div>
    </>
  );
}
