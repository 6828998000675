import { FacebookShareButton, TwitterShareButton } from "react-share";
import AppModal from "../ui/Modal/AppModal";
import { useState } from "react";
import ShareFormModal from "./ShareFormModal";
import { useTranslation } from "react-i18next";

export default function Share(props: any) {

  const { t, i18n } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isShareGroup, setIsShareGroup] = useState(false)
  
  const handleShareInMyPage = (postId: any) => {
    setIsOpenModal(true)
    // PostService.getInstance().sharePostInMyPage({ postId: postId }).then((result: any) => {
    //   if (result.data.status) {
    //     toast.success("Share post successfully")
    //   }
    //   else {
    //     toast.error("Share post unsuccessfully")
    //   }
    // }).catch((error: any) => {
    //   console.log(error);
    //   toast.error("Share post unsuccessfully")
    // })
  }

  const onCloseModal = () => {
    setIsOpenModal(false)
    setIsShareGroup(false)
  }
  return (
    <>
      <AppModal open={isOpenModal} onClose={onCloseModal}>
        <ShareFormModal onClose={onCloseModal} postId={props.post?.id} isGroup={isShareGroup} title={ isShareGroup? t("share.title.group") : t("share.title.mypage")}></ShareFormModal>
      </AppModal>
      <li className="post-share">
        <a className="share-btn"
          type="button"
          data-toggle="dropdown"
          aria-expanded="false">
          <i className="icofont-share"></i>{t("newsfeed.post.share")}
        </a>

        <div className="dropdown-menu dropdown-menu-right share-lst">
          <div className="dropdown-item share-item" >
            <i className="icofont-facebook color-fb"></i>
            <FacebookShareButton
                url={process.env.REACT_APP_URL + "/#/profile/" + props.post?.creator?.userUid + "/?postId=" + (props.post?.postSharedId ? props.post.postSharedId : props.post?.id)}
                quote={"rns"}
                hashtag={"#post"}
                className=""

              >
                {t("share.text.shareFb")}
              </FacebookShareButton>
          </div>
          <div className="dropdown-item share-item" >
            <i className="icofont-twitter color-twitter"></i>
            <TwitterShareButton 
              url={process.env.REACT_APP_URL + "/#/profile/"+ props.post?.creator?.userUid + "?postId=" + (props.post?.postSharedId ? props.post.postSharedId : props.post?.id)}
              hashtags={["#post"]}>
                {t("share.text.shareTweet")}
            </TwitterShareButton>
          </div>
          <div className="dropdown-item share-item"  onClick={() => handleShareInMyPage(props.post.id)}>
            <i className="icofont-share-alt color-twitter"></i>
            {t("share.text.shareMypage")}
          </div>
          <div className="dropdown-item share-item" onClick={() => {handleShareInMyPage(props.post.id); setIsShareGroup(true)}}>
            <i className="icofont-ui-user-group color-whatsapp"></i>
            {t("share.text.shareGroup")}
          </div>
        </div>
        {/* <ul className="share-list">
          <li>
            <a className="color-fb">
              <FacebookShareButton
                url={process.env.REACT_APP_URL + "/#/profile/" + props.post?.creator?.userUid + "/?postId=" + (props.post?.postSharedId ? props.post.postSharedId : props.post?.id)}
                quote={"rns"}
                hashtag={"#post"}
                className=""

              >
                <i className="icofont-facebook"></i>
              </FacebookShareButton>
            </a>
          </li>
          <li>
            <a href="#" className="color-twitter">
            <TwitterShareButton 
              url={process.env.REACT_APP_URL + "/#/profile/"+ props.post?.creator?.userUid + "?postId=" + (props.post?.postSharedId ? props.post.postSharedId : props.post?.id)}
              hashtags={["#post"]}>
              <i className="icofont-twitter"></i>
            </TwitterShareButton>
              
            </a>
          </li>
          <li>
            <a className="color-twitter" onClick={() => handleShareInMyPage(props.post.id)}>
              <i className="icofont-newspaper"></i>
            </a>
          </li>
          <li>
            <a className="color-whatsapp" onClick={() => {handleShareInMyPage(props.post.id); setIsShareGroup(true)}}>
              <i className="icofont-ui-user-group"></i>
            </a>
          </li>
        </ul> */}
      </li>
    </>
  );
}
