import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { PaginationConstant } from "../../constants/PaginationConstant";


const initialState: any = {
    posts: []
}

export const getPosts = createAsyncThunk('post/search', async () => {
    let request = {
        page: 0,
        size: PaginationConstant.PAGE_SIZE
    }
    return axios.post(process.env.REACT_APP_API_URL + "/post/search.exclude", request, {
        headers: HeadersUtil.getHeadersAuth()
    })
});

const postSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        setPosts: (state, action) => {
            state.posts = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getPosts.pending, state => {
        })
        builder.addCase(getPosts.fulfilled, (state, action: any) => {
            if (action.payload.data.status) {
                state.posts = action.payload.data.responseData
            }
        })
        builder.addCase(getPosts.rejected, (state, action: any) => {
            state.posts = []
        })
    },
});

export default postSlice.reducer
export const { setPosts } = postSlice.actions


export const postsSelector = (state: any) => state.postSlice.posts;