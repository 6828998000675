import axios from "axios";
import { RequestParam } from "../../models/request-params";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { ParamUtil } from "../../utils/ParamUtil";


export class ElasticsearchService {

  private static _elasticsearchService: ElasticsearchService;

  public static getInstance(): ElasticsearchService {
    if (!ElasticsearchService._elasticsearchService) {
        ElasticsearchService._elasticsearchService = new ElasticsearchService();
    }
    return ElasticsearchService._elasticsearchService;
  }

  public searchAll(request: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(request);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/elasticsearch/searchAll",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth()
    })
  }

}
