import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { GroupService } from "../../../../services/group/GroupService";
import GroupInfoHome from "../GroupInfoHome/GroupInfoHome";
import GroupInfoMembers from "./GroupInfoMembers";
import GroupInfoJoinRequest from "./GroupInfoJoinRequest";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../../../hooks/hook";
import { showAndHideSpinner } from "../../../../redux/reducers/spinnerSlice";
import AppModal from "../../../commons/ui/Modal/AppModal";
import InviteMember from "./form/InviteMember";
import AppAvatarEditor from "../../../commons/ui/AppAvatarEditor";
import AvatarEditor from "react-avatar-editor";
import { FileService } from "../../../../services/common/FileService";
import { useTranslation } from "react-i18next";

export default function GroupInfo() {
  // const search = window.location.search;
  // const urlSearchParam = new URLSearchParams(search);
  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams<{ id: string }>();
  const groupId = params.id;
  const [group, setGroup] = useState<any>();
  const [groupClassification, setGroupClassification] = useState<any>();
  const [lstJoinRequest, setLstJoinRequest] = useState<any>();
  const [tabSelected, setTabSelected] = useState<any>(0);
  const [isAdmin, setIsAdmin] = useState<any>(false);
  const [isRequest, setIsRequest] = useState<any>(false);
  const [isInvite, setIsInvite] = useState<any>(false);
  const [isMember, setIsMember] = useState<any>(false);
  const [numPost, setNumPost] = useState<any>(0);
  const dispatch = useAppDispatch();
  const [isOpenInviteModal, setIsOpenInviteModal] = useState<boolean>(false);
  const [isOpenUploadAvatar, setIsOpenUploadAvatar] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [fileCover, setFileCover] = useState<File>();
  const editor = React.createRef<AvatarEditor>();
  const [position, setposition] = useState<any>({ x: 0, y: 0 });
  const fileCoverInputRef = useRef<any>();

  useEffect(() => {
    let tab: any = searchParams.get("tab");
    setTabSelected(tab ? parseInt(tab) : 0);
  }, [searchParams]);

  useEffect(() => {
    _getData();
  }, [groupId]);

  function _getData() {
    dispatch(showAndHideSpinner(true));
    GroupService.getInstance()
      .getGroupById({ groupId: groupId })
      .then((result: any) => {
        if (result.data.status) {
          setGroup(result.data.responseData.group);
          setGroupClassification(result.data.responseData.classification);
          setLstJoinRequest(result.data.responseData.lstJoinRequest);
          setIsAdmin(result.data.responseData.isAdmin);
          setIsInvite(result.data.responseData.isInvite);
          setIsRequest(result.data.responseData.isRequest);
          setIsMember(result.data.responseData.isMember);
          setNumPost(result.data.responseData.numPost);
        }
        dispatch(showAndHideSpinner(false));
      })
      .catch((error: any) => {
        dispatch(showAndHideSpinner(false));
      });
  }

  const onUpdateData = () => {
    _getData();
  };

  const handleAnswerInvite = (isAccept: any) => {
    if (isAccept) {
      GroupService.getInstance()
        .acceptInviteJoinGroup({ groupId: groupId })
        .then((result: any) => {
          if (result.data.status) {
            toast.success(t("group.message.joined"));
            _getData();
          } else {
            toast.error(t("common.mesage.server-error"));
          }
        })
        .catch((error: any) => {});
    } else {
      GroupService.getInstance()
        .rejectInviteJoinGroup({ groupId: groupId })
        .then((result: any) => {
          if (result.data.status) {
            toast.success(t("group.message.joined"));
            _getData();
          } else {
            toast.error(t("common.mesage.server-error"));
          }
        })
        .catch((error: any) => {});
    }
  };

  const requestJoin = () => {
    GroupService.getInstance()
      .requestJoinGroup({ groupId: group.id })
      .then((result: any) => {
        if (result.data.status) {
          toast.success(t("group.message.requested"));
          _getData();
        } else {
          toast.error(t("common.mesage.server-error"));
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const cancelRequest = () => {
    GroupService.getInstance()
      .cancelRequestJoin({ groupId: group.id })
      .then((result: any) => {
        if (result.data.status) {
          toast.success(t("group.message.cancel-request"));
          _getData();
        } else {
          toast.error(t("group.message.cancel-request-err"));
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const leaveGroup = () => {
    Swal.fire({
      title: t("group.message.leave-group-title"),
      text: t("group.message.leave-group-text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.button.yes"),
      cancelButtonText: t("common.button.cancel"),
      reverseButtons: true,
    }).then((result: any) => {
      if (result.isConfirmed) {
        GroupService.getInstance()
          .leaveGroup({ groupId: groupId })
          .then((result: any) => {
            _getData();
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    });
  };

  const closeForm = (data: any) => {
    setIsOpenInviteModal(false);
  };

  const saveAvatar = (file: any) => {
    let data: any = { ...group };
    let avatarImage = { ...group.avatarImage };
    if (file.atchFleSeq) {
      avatarImage["atchFleSeq"] = file.atchFleSeq;
    }
    avatarImage["positionX"] = file.positionX;
    avatarImage["positionY"] = file.positionY;
    data.avatarImage = avatarImage;

    dispatch(showAndHideSpinner(true));
    GroupService.getInstance()
      .saveImage(data)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            toast.success(t("common.message.change-img"));
            _getData();
            setIsOpenUploadAvatar(false);
          } else {
            toast.warning(t("common.message.change-img-err"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const saveImageCover = async () => {
    let data = { ...group };
    let coverImage = { ...group.coverImage };
    if (fileCover && editor) {
      await FileService.getInstance()
        .uploadFile(fileCover)
        .then((res) => {
          if (res.data.status) {
            coverImage["atchFleSeq"] = res.data.responseData.atchFleSeq;
            const rect = editor.current?.getCroppingRect();
            if (rect) {
              coverImage["positionX"] = rect.x / (1.001 - rect.width);
              coverImage["positionY"] = rect.y / (1.001 - rect.height);
            } else {
              coverImage["positionX"] = 0;
              coverImage["positionY"] = 0;
            }
            data.coverImage = coverImage;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    dispatch(showAndHideSpinner(true));
    GroupService.getInstance()
      .saveImage(data)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            toast.success(t("common.message.change-img"));
            _getData();
            setPreviewUrl(null);
          } else {
            toast.warning(t("common.message.change-img-err"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const onCloseOpenUploadAvatarPopup = () => {
    setIsOpenUploadAvatar(false);
  };

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    setFileCover(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handlePositionChange = (position: any) => {
    setposition(position);
  };

  const onUpdateInfo = (data: any) => {
    if (data) {
      setGroup({ ...group, groupName: data.groupName });
    }
  };
  return (
    <>
      <AppModal open={isOpenInviteModal} onClose={closeForm}>
        <InviteMember open={isOpenInviteModal} groupId={groupId} title={t("group.text.invite")} members={group?.members} onCloseForm={closeForm}></InviteMember>
      </AppModal>
      <div className="edit-avatar-modal">
        <AppModal open={isOpenUploadAvatar} title={t("group.text.change-avt")} onClose={onCloseOpenUploadAvatarPopup}>
          <AppAvatarEditor border={50} height={250} width={250} image={group?.avatarImage?.atchFleSeq} borderRadius={100} rotate={0} onClose={saveAvatar} />
        </AppModal>
      </div>
      <div className="page-content object-page-content group-page">
        <div className="container">
          <div
            className="banner-user"
            style={{
              backgroundImage: `url(${previewUrl != null ? previewUrl : process.env.REACT_APP_API_URL + "/public/image-view/" + group?.coverImage?.atchFleSeq})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: `${group?.coverImage?.positionX * 100}% ${group?.coverImage?.positionY * 100}%`,
            }}>
            {previewUrl != null && (
              <>
                <div className="preview-image-cover">
                  <AvatarEditor ref={editor} image={previewUrl} height={342} width={1170} border={0} position={position} scale={1} rotate={0} onPositionChange={handlePositionChange} />
                  <div className="button-image-cover-arrow" role="button">
                    <i className="fa fa-arrows"></i>
                    {t("group.text.drag")}
                  </div>
                </div>
              </>
            )}
            <div className="image-cover">
              {isAdmin && previewUrl == null && (
                <>
                  <input type="file" ref={fileCoverInputRef} style={{ display: "none" }} onChange={handleImageUpload} />
                  <div
                    className="button-image-cover"
                    role="button"
                    onClick={() => {
                      fileCoverInputRef.current.click();
                    }}>
                    <i className="fa fa-camera"></i>
                    {t("group.button.upload-cover")}
                  </div>
                </>
              )}
              {previewUrl != null && (
                <>
                  <div
                    className="button-image-cover"
                    style={{ marginRight: "5px" }}
                    role="button"
                    onClick={() => {
                      setPreviewUrl(null);
                    }}>
                    {t("common.button.cancel")}
                  </div>
                  <div className="button-image-cover" role="button" onClick={() => saveImageCover()}>
                    {t("common.button.save")}
                  </div>
                </>
              )}
            </div>
            <div className="banner-content">
              <div className="media" style={{ alignItems: "baseline" }}>
                <div
                  className="item-img"
                  style={{
                    width: "115px",
                    height: "115px",
                  }}>
                  <img
                    onClick={() => {
                      isAdmin && setIsOpenUploadAvatar(true);
                    }}
                    style={{
                      objectPosition: group?.avatarImage && `${group?.avatarImage.positionX * 100}% ${group?.avatarImage.positionY * 100}%`,
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                    src={process.env.REACT_APP_API_URL + "/public/image-view/" + group?.avatarImage?.atchFleSeq}
                    onError={(event: any) => {
                      event.target.src = require("../../../../assets/default-group.png");
                    }}
                  />
                </div>
                <div className="media-body">
                  <h3 className="item-title">{group?.groupName}</h3>
                  {/* <ul className="item-social">
                                        <li><a href="#" className="bg-fb"><i className="icofont-facebook"></i></a></li>
                                        <li><a href="#" className="bg-twitter"><i className="icofont-twitter"></i></a></li>
                                        <li><a href="#" className="bg-dribble"><i className="icofont-dribbble"></i></a></li>
                                        <li><a href="#" className="bg-youtube"><i className="icofont-brand-youtube"></i></a></li>
                                        <li><a href="#" className="bg-behance"><i className="icofont-behance"></i></a></li>
                                    </ul> */}
                  <ul className="user-meta">
                    <li>
                      {t("group.text.group-post")}: <span>{numPost}</span>
                    </li>
                    <li>
                      {t("group.text.all-member")}: <span>{group?.members.length}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-3">
            <div className="user-top-header col-lg-7 p-0">
              <ul className="menu-list">
                <li
                  className={tabSelected === 0 ? "active" : ""}
                  onClick={() => {
                    setTabSelected(0);
                  }}>
                  <a style={{ width: "max-content" }}>{t("group.nav.home")}</a>
                </li>
                <li
                  className={tabSelected === 1 ? "active" : ""}
                  onClick={() => {
                    setTabSelected(1);
                  }}>
                  <a style={{ width: "max-content" }}>{t("group.nav.members")}</a>
                </li>
                {isAdmin === true && (
                  <li
                    className={tabSelected === 2 ? "active" : ""}
                    onClick={() => {
                      setTabSelected(2);
                    }}>
                    <a style={{ width: "max-content" }}>{t("group.nav.request")}</a>
                  </li>
                )}
                {isMember === true && (
                  <li className="text-center">
                    <div className="dropdown">
                      <a className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        <div>...</div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        {!isAdmin && (
                          <a className="dropdown-item" onClick={leaveGroup}>
                            {t("group.text.leave")}
                          </a>
                        )}
                        <a className="dropdown-item" onClick={() => setIsOpenInviteModal(true)}>
                          {t("group.text.invite")}
                        </a>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            {!isMember && (
              <div className="user-top-header-btn col-lg-5 d-none d-lg-block" style={{ marginBottom: "30px" }}>
                <div className="post-input-tab d-flex align-items-center justify-content-end h-100">
                  <div className="post-footer p-0">
                    <div className="submit-btn">
                      {isInvite ? (
                        <>
                          <a className="mx-1 bg-danger" onClick={() => handleAnswerInvite(false)}>
                            {t("group.button.reject")}
                          </a>
                          <a onClick={() => handleAnswerInvite(true)}>{t("group.button.accept")}</a>
                        </>
                      ) : (
                        <>
                          {isRequest ? (
                            <a onClick={cancelRequest} className="">
                              {t("group.button.cancel-request")}
                            </a>
                          ) : (
                            <a onClick={requestJoin}>{t("group.button.join")}</a>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {tabSelected === 0 && <GroupInfoHome group={group} groupClassification={groupClassification} isMember={isMember} onUpdate={onUpdateInfo} />}
          {tabSelected === 1 && <GroupInfoMembers data={{ groupId: groupId, lstMembers: group?.members }} />}
          {isAdmin === true && tabSelected === 2 && <GroupInfoJoinRequest updateData={onUpdateData} data={{ lstJoinRequest: lstJoinRequest }} />}
        </div>
      </div>
    </>
  );
}
