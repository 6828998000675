import { useEffect, useState } from "react";
import { PostWishlistService } from "../../../../services/post/PostWishlistService";
import { PostWishList } from "../../../../models/postWishlist";
import { toast , Zoom } from "react-toastify";
import { PostReadLaterService } from "../../../../services/post/PostReadLaterService";
import { PostReadLater } from "../../../../models/postReadLater";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { refreshCompare } from "../../../../redux/reducers/compareSlice";
import { PostService } from "../../../../services/post/PostService";
import { showAndHideSpinner } from "../../../../redux/reducers/spinnerSlice";
import { AuthUtil } from "../../../../utils/AuthUtil";
import { useNavigate } from "react-router-dom";
import PostReport from "./PostReport/PostReport";
import AppModal from "../../../commons/ui/Modal/AppModal";
import { t } from "i18next";

type PostActionType = {
  post: any;
  refresh?: () => void;
};

export default function PostAction(props: PostActionType) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [post, setPost] = useState<any>();
  const [isWislist, setIsWislist] = useState(false);
  const [isReadLater, setIsReadLater] = useState(false);
  const [isOpenReportPopup, setIsOpenReportPopup] = useState(false);
  useEffect(() => {
    setPost(props.post);
    setIsWislist(props.post.currentUserReactionToPost?.addedWishList);
    setIsReadLater(props.post.currentUserReactionToPost?.addedReadLater);
  }, [props.post]);

  const getIsWishlist = () => {
    PostWishlistService.getInstance()
      .getWishlistByPostId(props.post.id)
      .then((resp) => {
        if (resp.data.status) {
          setIsWislist(resp.data.responseData);
        }
      })
      .catch((err) => { });
  };

  const getIsReadLater = () => {
    PostReadLaterService.getInstance()
      .getReadLaterByPostId(props.post.id)
      .then((resp) => {
        if (resp.data.status) {
          setIsReadLater(resp.data.responseData);
        }
      })
      .catch((err) => { });
  };

  const addWishlist = () => {
    PostWishlistService.getInstance()
      .addWishlist(new PostWishList(post.id))
      .then((resp) => {
        if (resp.data.status) {
          toast.success(t("toast.addWishlist"));
          getIsWishlist();
        }
      })
      .catch((err) => { });
  };

  const removeWishlist = () => {
    PostWishlistService.getInstance()
      .removeWishlist(post.id)
      .then((resp) => {
        if (resp.data.status) {
          toast.success(t("toast.removeWishlist"));
          getIsWishlist();
        }
      })
      .catch((err) => { });
  };

  const addReadLater = () => {
    PostReadLaterService.getInstance()
      .addReadLater(new PostReadLater(post.id))
      .then((resp) => {
        if (resp.data.status) {
          toast.success(t("toast.addReadlater"));
          getIsReadLater();
        }
      })
      .catch((err) => { });
  };

  const removeReadLater = () => {
    PostReadLaterService.getInstance()
      .removeReadLater(post.id)
      .then((resp) => {
        if (resp.data.status) {
          toast.success(t("toast.removeReadlater"));
          getIsReadLater();
        }
      })
      .catch((err) => { });
  };

  const deletePost = () => {
    dispatch(showAndHideSpinner(true));
    PostService.getInstance()
      .delete(post.id)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            props.refresh?.();
          }
        },
        (error) => { }
      );
  };

  const navigateEditPost = () => {
    navigate("/write-review?postId=" + post.id);
  };
  const onCloseReportPopup = () => {
    setIsOpenReportPopup(false);
  };
  const onOpenReportPopup = () => {
    setIsOpenReportPopup(true);
  };
  return (
    <>
      <div className="report-modal">
        <AppModal
          open={isOpenReportPopup}
          title={"Báo cáo bài viết"}
          onClose={onCloseReportPopup}
        >
          <PostReport post={post} onClose={onCloseReportPopup}></PostReport>
        </AppModal>
      </div>
      <div className="dropdown">
        <button
          className="dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          ...
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          {/* {isWislist === true && (
            <a
              onClick={() => {
                removeWishlist();
              }}
              className="dropdown-item"
            >
              Remove wishlist
            </a>
          )}
          {isWislist !== true && (
            <a
              onClick={() => {
                addWishlist();
                dispatch(refreshCompare(new Date().getTime()));
              }}
              className="dropdown-item"
            >
              Add Wishlist
            </a>
          )} */}
          {isReadLater === true && AuthUtil.getCurrentUserUid() != props.post?.creator?.userUid && (
            <a
              onClick={() => {
                removeReadLater();
              }}
              className="dropdown-item"
            >
              Bỏ xem sau
            </a>
          )}
          {isReadLater !== true && AuthUtil.getCurrentUserUid() != props.post?.creator?.userUid && (
            <a
              onClick={() => {
                addReadLater();
                dispatch(refreshCompare(new Date().getTime()));
              }}
              className="dropdown-item"
            >
              Xem sau
            </a>
          )}
          {/* <a className="dropdown-item" href="#">
            Close
          </a> */}

          {AuthUtil.getCurrentUserUid() === post?.creator?.userUid && (
            <>
              <a className="dropdown-item" onClick={navigateEditPost}>
                Sửa đổi
              </a>
              <a className="dropdown-item" onClick={deletePost}>
                Xóa
              </a>
            </>
          )}
          {AuthUtil.getCurrentUserUid() != props.post?.creator?.userUid &&
            <a onClick={onOpenReportPopup} className="dropdown-item">
              Báo cáo bài viết
            </a>
          }
        </div>
      </div>
    </>
  );
}
