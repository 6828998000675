import { useEffect, useState } from "react";
import { CategoryService } from "../../../services/category/CategoryService";
import { TranslationConstant } from "../../../constants/TranslationConstant";
import { t } from "i18next";
import { useAppSelector } from "../../../hooks/hook";

type CompareProps = {
    listObject: any
    viewPostDetail: (data?: any) => void;
};
export default function Compare(props: CompareProps) {
    const [listObject, setListObject] = useState(props.listObject);
    const [evalStandards, setEvalStandards] = useState<any[]>([]);
    const lang = useAppSelector((state) => state.language.lang);
    useEffect(() => {
        if (listObject && listObject.length > 0 && listObject[0].category !== null) {
            getEvalStandard(listObject[0]?.category?.cateId);
            console.log(listObject);
            
        }
    }, [listObject])

    const getEvalStandard = (cateId: any) => {
        CategoryService.getInstance()
            .getEvalStandard(cateId)
            .then(
                (res) => {
                    if (res.data.status) {
                        setEvalStandards(res.data.responseData);;
                    }
                },
                (error) => {

                }
            );
    };
    return (
        <>
            <div className="compare-modal-body">
                <table className="preview-banner">
                    <tbody>
                        <tr>
                            <td></td>
                            {listObject && listObject.map((object: any, index: any) => (
                                <td key={index} >
                                    <div className="banner-user m-0" style={{
                                        backgroundImage: `url(${process.env.REACT_APP_API_URL +
                                            "/public/image-view/" +
                                            object?.coverImage?.atchFleSeq})`, backgroundRepeat: 'no-repeat',
                                    }}>
                                        <div className="banner-content">
                                            <div className="media">
                                                <div className="item-img">
                                                    <img src={process.env.REACT_APP_API_URL +
                                                        "/public/image-view/" +
                                                        object?.avatarImage?.atchFleSeq} onError={(event: any) => {
                                                            event.target.src = require("../../../assets/default-avatar.jpg")
                                                        }} />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className={"item-title " + (object?.legal ? "check" : "")}>{object?.objectName}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group">
                                    <div className="user-about">
                                        <ul className="user-info">
                                            <li style={{ justifyContent: "left" }}>
                                                <span>{t("object.compare.info")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div></td>
                            {listObject && listObject.map((object: any, index: any) => (
                                <td key={index} >
                                    <div className="form-group">
                                        <div className="user-about">
                                            <ul className="user-info">
                                                <li>
                                                    <span style={{ marginRight: "25px" }}><i className="fa fa-thumbs-o-up" aria-hidden="true"></i>{object?.countLike}</span>
                                                    <span style={{ marginRight: "25px" }}><i className="fa fa-bookmark-o" aria-hidden="true"></i>{object?.countPost}</span>
                                                    <span><i className="fa fa-rss" aria-hidden="true"></i>{object?.countFollow}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group">
                                    <div className="user-about">
                                        <ul className="user-info">
                                            <li style={{ justifyContent: "left" }}>
                                                <span>{t("object.label.description")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div></td>
                            {listObject && listObject.map((object: any, index: any) => (
                                <td key={index} style={{ maxWidth: "400px", width: "400px" }}>
                                    <div className="form-group">
                                        <div className="user-about">
                                            <ul className="user-info">
                                                <li style={{ textAlign: "left" }}>
                                                    <span>{object?.description.slice(0, 200)}...</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            ))}
                        </tr>
                        {evalStandards.map((ele, idx) => (
                            <tr key={idx}>
                                <td style={{ paddingBottom: "2px", paddingTop: "2px", minWidth: "200px" }}>
                                    {evalStandards && evalStandards.length > 0 ? (
                                        <div className="form-group" style={{ textAlign: "left" }}>
                                            <div className="user-about">
                                                <ul className="user-info">
                                                    <li style={{ justifyContent: "left" }}>
                                                        <span>{lang === TranslationConstant.SOUTH_KOREA ? ele.propNmKR :
                                                            lang === TranslationConstant.ENGLISH ? ele.propNmEn : ele.propNm}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </td>
                                {listObject && listObject.map((object: any, index: any) => (
                                    <td key={index} style={{ paddingBottom: "2px", paddingTop: "2px" }} >
                                        <div className="form-group" style={{ textAlign: "left" }}>
                                            <div className="user-about">
                                                <ul className="user-info">
                                                    {object && object.ratingProps && object.ratingProps.map((rating: any, index: any) => (
                                                        <li key={index}>
                                                            {rating.propNm == ele.propNm && (
                                                                <><span>{rating.rating}</span>
                                                                    <img src="/media/star-2.png" alt="start" width={15} /></>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td>
                                <div className="form-group" style={{ textAlign: "left", marginTop: '10px' }}>
                                    <div className="user-about">
                                        <ul className="user-info">
                                            <li style={{ justifyContent: "left" }}>
                                                <span>{t("object.menu.image")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            {listObject && listObject.map((object: any, index: any) => (
                                <td key={index} >
                                    <ul className="photo-list">
                                        {object.lstMedia &&
                                            object.lstMedia.length > 0 &&
                                            object.lstMedia.slice(0, 5).map((media: any, index: any) => (
                                                <li key={index}><a onClick={() => props.viewPostDetail(media.postId)}><img src={
                                                    process.env.REACT_APP_API_URL +
                                                    "/public/image-view/" +
                                                    media.attachment.atchFleSeq
                                                }
                                                    onError={(event: any) => {
                                                        event.target.src = require("../../../assets/placeholder.jpg");
                                                    }} alt="Gallery" /></a></li>

                                            ))}
                                    </ul>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}