import { useForm } from "react-hook-form";

import "./Login.css";
import { AuthService } from "../../../../../services/auth/AuthService";
import { toast } from "react-toastify";
import { AuthUtil } from "../../../../../utils/AuthUtil";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../hooks/hook";
import { getUserInfo } from "../../../../../redux/reducers/userSlice";
import AppModal from "../../../../commons/ui/Modal/AppModal";
import OtpModal from "../../../Setting/modal/OtpModal";
import { t } from "i18next";

export default function Login() {

  const location = useLocation()
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [formData, setFormData] = useState({})


  useEffect(() => {
    if(!AuthUtil.isLogin()){
      const token = new URLSearchParams(location.search).get("access_token");
      if (token){        
        AuthUtil.saveToken(token);
        window.location.href = "/";
        dispatch(getUserInfo());
      }
    }
    
  }, [])
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const login = (data: any) => {
    AuthService.getInstance().login({...data, redirectUri: window.location.origin}, process.env.REACT_APP_AUTH_URL).then(res => {
      console.log(res.data);
        
      if (res.data.status === "OK"){
          AuthUtil.saveToken(res.data.result.accessToken);
          window.location.href = "/";
        } else if(res.data.status === "OTP_2FA") {
          setFormData({...data})
          setIsOpenModal(true);
        }else{
          toast.error(res.data.messages);
        }
    }, error => {})
  }

  const onRegister = () => {
    window.location.href = process.env.REACT_APP_AUTH_URL + "/sign-up?redirect_uri=" + window.location.origin;
  }

  const onLoginWithGoogle = () => {
    window.location.href = process.env.REACT_APP_AUTH_URL + '/oauth2/authorize/google?redirect_uri=' + window.location.origin;
  }

  const onLoginWithFacebook = () => {
    window.location.href = process.env.REACT_APP_AUTH_URL + '/oauth2/authorize/facebook?redirect_uri=' + window.location.origin;
  }

  const onCloseModal = (data:any) => {
    console.log(data);
    
  }

  return (
    <>
      <AppModal onClose={onCloseModal} open={isOpenModal} title={"Two FA"} size='lg'>
        <OtpModal loginData = {formData} onCloseModal={onCloseModal}></OtpModal>
      </AppModal>
      <div className="login-page-wrap">
        <div className="login-content-wrap">
          <div className="login-content">
            <div className="login-form-wrap">
              <div className="tab-content">
                <div className="tab-pane login-tab fade show active">
                  <h3 className="item-title">
                    {t("login.signIn")}<span>{t("login.yourAccount")}</span>
                  </h3>
                  <div className="google-signin">
                    <a onClick={onLoginWithGoogle}>
                      <img src="media/figure/google-icon.png" alt="Google" />
                      {t("login.googleLogin")}
                    </a>
                  </div>
                  <div className="facebook-signin">
                    <a onClick={onLoginWithFacebook}>
                      <img src="media/figure/facebook-icon.png" alt="Facebook" />
                      {t("login.facebookLogin")}
                    </a>
                  </div>
                  <div style={{margin: "15px 0", textAlign: "center"}}>
                    or
                  </div>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        {...register("username", { required: true })}
                        className="form-control"
                        name="username"
                        placeholder={t("login.username")}
                      />
                      {errors?.username && (
                        <p className="msg-error">{t("login.username")} {t("login.isrequired")}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        {...register("password", { required: true })}
                        className="form-control"
                        name="password"
                        placeholder={t("login.password")}
                      />
                      {errors?.password && (
                        <p className="msg-error">{t("login.password")} {t("login.isrequired")}</p>
                      )}
                    </div>
                    {/* <div className="form-group reset-password">
                      <a>Forgot Password ?</a>
                    </div> */}
                    {/* <div className="form-group mb-4">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" />
                        <label className="form-check-label">
                          Keep me as signed in
                        </label>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <a className="item-btn-login" onClick={handleSubmit(login)}>{t("login.login")}</a>
                    </div>
                  </form>
                  <div className="account-create">
                  {t("login.notAccount")}{" "}
                    <a onClick={onRegister}> {t("login.signUpNow")}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
