import { useEffect, useState } from 'react'
import PostView from '../Newsfeed/components/PostView';
import { NavLink, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/hook';
import { ReviewerService } from '../../../services/reviewer/ReviewerService';
import { PaginationConstant } from '../../../constants/PaginationConstant';
import AppModal from '../../commons/ui/Modal/AppModal';
import EditProfile from './form/EditProfile';
import { getUserInfo, updateUserInfo } from '../../../redux/reducers/userSlice';
import DateFormat from '../../commons/ui/DateFormat/DateFormat';
import { showAndHideSpinner } from '../../../redux/reducers/spinnerSlice';
import { CommonConstant } from '../../../constants/CommonConstant';
import PostDetail from '../Newsfeed/components/PostDetail/PostDetail';
import { PostService } from '../../../services/post/PostService';
import { toast } from 'react-toastify';
import { PostReadLaterService } from '../../../services/post/PostReadLaterService';
import { useTranslation } from 'react-i18next';

export default function HomeTab(props: any) {

  const { t, i18n } = useTranslation();
  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });
  const dispatch = useAppDispatch();
  const myInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [lstPost, setLstPost] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState<any>();
  const [lstReadLater, setLstReadLater] = useState<[]>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>();
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [searchImageRequest, setSearchImageRequest] = useState<any>({
    page: 0,
    postSize: PaginationConstant.PAGE_SUGGEST_GROUP_SIZE,
    type: CommonConstant.MEDIA_TYPE_IMAGE,
  });

  const [lstImg, setLstImg] = useState<[]>();
  const [isOpenPostDetailPopup, setIsOpenPostDetailPopup] = useState(false);
  const [postDetail, setPostDetail] = useState<any>({});
  const [totalPost, setTotalPost] = useState<any>(0);
  const [postShare, setPostShare] = useState<any>({});
  const [sharedPostId, setSharedPostId] = useState<any>();
  const location = useLocation();

  useEffect(() => {
    setSharedPostId(new URLSearchParams(location.search).get("postId"));
  }, [location]);

  useEffect(() => {
    if (sharedPostId) {
      viewPostDetail(sharedPostId);
    }
  }, [sharedPostId]);

  useEffect(() => {
    if (props.userInfo) {
      setUserInfo(props.userInfo);
      _refreshData()
      setSearchRequest({
        ...searchRequest,
        userUid: props.userInfo.userUid,
      });
      setSearchImageRequest({
        ...searchImageRequest,
        userUid: props.userInfo.userUid,
      });
    }
    if (props.lstWishlist) {
      setLstReadLater(props.lstWishlist);
    }
  }, [props.userInfo]);

  useEffect(() => {
    if (props.lstWishlist) {
      setLstReadLater(props.lstWishlist);
    }
  }, [props.lstWishlist]);

  useEffect(() => {
    if (searchRequest.userUid) {
      _getData();
    }
    
  }, [searchRequest.page])

  useEffect(()=>{
  if (isEdited) {        
        setUserInfo(myInfo)
        _refreshData()
        setIsEdited(false)
      }
  }, [isEdited])

  useEffect(() => {
    if (searchRequest.userUid && searchRequest.userUid !== "") {
      _refreshData();
    }
  }, [searchRequest.userUid]);

  useEffect(() => {
    if (searchImageRequest.userUid) {
      ReviewerService.getInstance()
        .getMedia(searchImageRequest)
        .then((result: any) => {
          if (result.data.status) {
            setLstImg(result.data.responseData.lstMedia);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [searchImageRequest]);

  function _getData() {
    dispatch(showAndHideSpinner(true));
    ReviewerService.getInstance()
      .searchPostByUser(searchRequest)
      .then((result: any) => {
        dispatch(showAndHideSpinner(false));
        if (result.data.status) {
          let lstTemp = lstPost.concat(result.data.responseData.lstPost);
          setLstPost(lstTemp);
          setTotalPost(result.data.responseData.totalElement);
          
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(showAndHideSpinner(false));
      });
  }

  function _refreshData() {
    dispatch(showAndHideSpinner(true));
    ReviewerService.getInstance()
      .searchPostByUser({
        page: 0,
        size: PaginationConstant.PAGE_SIZE,
        userUid: userInfo?.userUid,
      })
      .then((result: any) => {
        dispatch(showAndHideSpinner(false));
        if (result.data.status) {
          setLstPost(result.data.responseData.lstPost);
          setTotalPost(result.data.responseData.totalElement);
          
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(showAndHideSpinner(false));
      });
  }

  const loadMorePosts = () => {
    setSearchRequest({
      ...searchRequest,
      page: searchRequest.page + 1,
    });
  };

  const editInfo = () => {
    setIsOpenModal(true);
  };

  const onCloseModal = (data: any) => {
    setIsOpenModal(false);
  };

  const onCloseForm = (data: any) => {
    if (data) {
      setIsEdited(true)
      dispatch(updateUserInfo({ ...myInfo, fullName: data.fullName, email: data.email, dob: data.dob, address: data.address}));
      dispatch(getUserInfo());
    }
    setIsOpenModal(false);
  };

  const viewPostDetail = (postId: any) => {
    PostService.getInstance()
      .getPostDetail(postId)
      .then((response: any) => {
        if (response.data.status) {
          setPostDetail({
            ...response.data.responseData,
            user: props.userInfo,
          });
          setIsOpenPostDetailPopup(true);
        }
      })
      .catch((error: any) => {
        console.log(t("common.message.server-error") + ": " + error);
        toast.error(t("common.message.server-error"));
      });
  };

  const onClosePostViewPopup = (data: any) => {
    setIsOpenPostDetailPopup(false);
    setPostShare({});
  };

  const handleSeeMoreImg = () => {
    props.onSeeMoreImg();
  };

  const removeReadLater = (post: any) => {
    PostReadLaterService.getInstance()
      .removeReadLater(post.id)
      .then((resp) => {
        if (resp.data.status) {
          props.getListReadLater();
          toast.success(t("profile.message.saved"));
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <AppModal
        open={isOpenPostDetailPopup}
        children={<PostDetail postId={postDetail.id} />}
        onClose={onClosePostViewPopup}
      ></AppModal>
      <div className="row">
        <div className="col-lg-4 widget-block widget-break-lg">
          <div className="widget widget-user-about">
            <div className="widget-heading">
              <h3 className="widget-title">{t("profile.info")}</h3>
              {myInfo?.userUid === userInfo?.userUid && (
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    ...
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" onClick={editInfo}>
                      {t("profile.edit")}
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="user-info">
              <h6 className='text-dark-c'>
                {myInfo.userUid === userInfo?.userUid
                  ? myInfo.description
                  : userInfo?.description}
              </h6>
              <ul className="info-list">
                {myInfo.userUid === userInfo?.userUid ? (
                  <>
                    {myInfo.dob && (
                      <li>
                        <i className="icofont-calendar text-dark-c info-icon"></i><span className="ml-2">{t("profile.label.dob")}</span>
                        <span className="ml-2 font-weight-bold text-dark-c"><DateFormat date={myInfo.dob}></DateFormat></span>
                      </li>
                    )}
                    {myInfo.address && (
                      <li>
                        <i className="icofont-location-pin text-dark-c info-icon"></i><span className="ml-2">{t("profile.label.address")}</span>
                        <span className="ml-2 font-weight-bold text-dark-c">{myInfo.address}</span>
                      </li>
                    )}
                    {myInfo.email && (
                      <li>
                        <i className="icofont-email text-dark-c info-icon"></i>
                        {/* <span className="mx-2">{t("profile.label.email")}</span> */}
                        <span className="ml-2 font-weight-bold text-dark-c">{myInfo.email}</span>
                      </li>
                    )}
                    {myInfo.cellPhone && (
                      <li>
                        <i className="icofont-phone text-dark-c info-icon"></i>
                        {/* <span className="mx-2">{t("profile.label.phone")}</span> */}
                        <span className="ml-2 font-weight-bold text-dark-c">{myInfo.cellPhone}</span>
                      </li>
                    )}
                  </>
                ) : (
                  <>
                    {userInfo?.dob && (
                      <li>
                        <i className="icofont-calendar text-dark-c info-icon"></i><span className="ml-2">{t("profile.label.dob")}</span>
                        <span className="ml-2 font-weight-bold text-dark-c"><DateFormat date={userInfo?.dob}></DateFormat></span>
                      </li>
                    )}
                    {userInfo?.address && (
                      <li>
                        <i className="icofont-location-pin text-dark-c info-icon"></i><span className="ml-2">{t("profile.label.address")}</span>
                        <span className="ml-2 font-weight-bold text-dark-c">{userInfo?.address}</span>
                      </li>
                    )}
                    {userInfo?.email && (
                      <li>
                        <i className="icofont-email text-dark-c info-icon"></i>
                        {/* <span>{t("profile.label.email")}:</span> */}
                        <span className="ml-2 font-weight-bold text-dark-c">{userInfo?.email}</span>
                      </li>
                    )}
                    {userInfo?.cellPhone && (
                      <li>
                        <i className="icofont-phone text-dark-c info-icon"></i>
                        {/* <span>{t("profile.label.phone")}:</span> */}
                        <span className="ml-2 font-weight-bold text-dark-c">{userInfo?.cellPhone}</span>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
          {myInfo?.userUid === userInfo?.userUid && (
            <>
              <div className="widget widget-comment">
                <div className="widget-heading">
                  <h3 className="widget-title">{t("profile.see-later")}</h3>
                </div>
                <div
                  className="group-list"
                  style={
                    lstReadLater && lstReadLater?.length > 3
                      ? { height: "280px", overflow: "auto" }
                      : { height: "auto" }
                  }
                >
                  {lstReadLater && lstReadLater.length > 0 &&
                    lstReadLater.map((post: any) => (
                      <div key={post.id} className="media">
                        <div className="item-img">
                          <a
                            style={{ width: "80px", height: "80px" }}
                            onClick={() => viewPostDetail(post.id)}
                            key={post.id}
                          >
                            {post.attachments && (
                              <img
                                style={{ objectFit: "cover" }}
                                src={
                                  process.env.REACT_APP_API_URL +
                                  "/public/image-view/" +
                                  post.attachments[0].atchFleSeq
                                }
                                onError={(event: any) => {
                                  event.target.src = require("../../../assets/placeholder.jpg");
                                }}
                                alt="post"
                              />
                            )}
                          </a>
                        </div>
                        <div className="media-body read-later">
                          <div className="widget-heading mb-2">
                            <a
                              onClick={() => viewPostDetail(post.id)}
                              key={post.id}
                              className="user-title"
                            >
                              <h5 className="mb-0" style={{fontSize: "13px", lineHeight: "1.2", fontWeight: "bold"}}>
                                {post.title.length > 25
                                  ? post.title.slice(0, 30) + "..."
                                  : post.title}
                              </h5>
                            </a>
                            <div className="dropdown">
                              <button
                                className="dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                ...
                              </button>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  onClick={() => removeReadLater(post)}
                                >
                                  {t("profile.delete-seelater")}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="user-title">
                          {t("profile.text.about")}{" "}
                            <NavLink to={"/object/" + post.object?.id}>
                              {post.object?.objectName}
                            </NavLink>
                            <i className="icofont-check"></i>
                          </div>
                          {/* <ul className="entry-meta">
                          <li className="meta-time">
                            <AppTimeAgo date={new Date(post.createdDate)}></AppTimeAgo>
                          </li>
                        </ul> */}
                          <div
                            style={{ fontSize: "12px" }}
                            className="post-content"
                          >
                            {t("profile.text.postof")}{" "}
                            <NavLink to={"/profile/" + post.creator?.userUid}>
                              {post.creator?.fullName}
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    ))}
                    {lstReadLater && lstReadLater.length == 0 && (
                      <p style={{margin : "0 auto"}}>{t("profile.label.no-post")} </p>
                    )}
                </div>
              </div>
            </>
          )}

          <div className="widget widget-gallery">
            <div className="widget-heading">
              <h3 className="widget-title">{t("profile.nav.img")}</h3>
            </div>

            <ul className="photo-list">
              {lstImg &&
                lstImg.length > 0 &&
                lstImg.map((media: any) => (
                  <li
                    onClick={() => viewPostDetail(media.postId)}
                    key={media.attachment.atchFleSeq}
                  >
                    <a>
                      <img
                        style={{ objectFit: "cover" }}
                        src={
                          process.env.REACT_APP_API_URL +
                          "/public/image-view/" +
                          media.attachment.atchFleSeq
                        }
                        onError={(event: any) => {
                          event.target.src = require("../../../assets/placeholder.jpg");
                        }}
                      />
                    </a>
                  </li>
                ))}
            </ul>
            {
            lstImg &&
                lstImg.length > 0 &&
              <div className="see-all-btn">
                <a className="item-btn" onClick={handleSeeMoreImg}>
                {t("profile.button.seemore")}
                </a>
            </div>
            }
          </div>
        </div>
        <div className="col-lg-8">
          {lstPost &&
            lstPost.map((post: any, index: any) => (
              <>
                <PostView
                  key={post.id + index}
                  post={post}
                  refresh={_refreshData}
                ></PostView>
              </>
            ))}
          {totalPost > lstPost?.length && (
            <div className="block-box load-more-btn">
              <a className="item-btn" onClick={loadMorePosts}>
                <i className="icofont-refresh"></i>{t("common.button.loadmore")}
              </a>
            </div>
          )}
        </div>
      </div>

      <AppModal
        onClose={onCloseModal}
        open={isOpenModal}
      >
        <EditProfile onCloseForm={onCloseForm} title={t("profile.text.edit-profile")} userInfo={myInfo}></EditProfile>
      </AppModal>
    </>
  );
}
