import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ReviewerService } from '../../../../services/reviewer/ReviewerService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function EditProfile(props:any) {

    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, reset } = useForm({defaultValues: {...props.userInfo, dob: new Date(props.userInfo.dob).toISOString().slice(0, 10)}});
    const [userInfo, setUserInfo] = useState<any>(props.userInfo);

    useEffect(() => {        
        setUserInfo(props.userInfo)
    },[props])

    const onClickClose = () => {
        props.onCloseForm(false)
    }

    const handleSaveForm = (dataForm:any) => {
        
        ReviewerService.getInstance().updateUserInfo({...userInfo, ...dataForm}).then((result) => {
            if(result.data.status){
                if(result.data.responseData.isExistEmail){
                    toast.error(t("profile.message.emailExists"))
                }else{
                    toast.success(t("profile.message.updateProfile"))
                    reset()
                    props.onCloseForm(dataForm)
                }
            }else{
                toast.error(t("profile.message.updateProfileErr"))
            }
        
        }).catch((error) => {
            console.log(error);
            
        })
    }

    const onChangeDob = (event:any) =>{
        setUserInfo({
            ...userInfo,
            dob: new Date(event.target.value).toLocaleDateString()
        })
    }
  return (
    <div className='modal-content'>
    <div className="modal-body" style={{maxHeight: '650px', overflowY: 'scroll'}}>
            <h3 className="heading-title px-4">{props.title}</h3>
            <div className="forum-topic-add" id="new-post-form">
                <div className="form px-4">
                    <div className="form-group">
                        <label>{t("profile.label.full-name")}</label>
                        <input {...register("fullName", {required: true})} type="text" className="form-control"  name='fullName'/>
                        {errors?.fullName && <p className="msg-error">{t("profile.message.reqFullName")}</p>}
                    </div>
                </div>

                <div className="form p-4">
                    <div className="form-group">
                        <label>{t("profile.label.description")}</label>
                        <textarea {...register("description", {required: true})} className="form-control textarea"  name='description'/>
                        {errors?.description && <p className="msg-error">{t("profile.message.reqDescription")}</p>}
                    </div>
                </div>

                <div className="form p-4">
                    <div className="form-group">
                        <label>{t("profile.label.dob")}</label>
                        <input {...register("dob")}type="date" className="form-control"  name='dob'/>
                        {errors?.dob && <p className="msg-error">{t("profile.message.reqDob")}</p>}
                    </div>
                </div>

                <div className="form p-4">
                    <div className="form-group">
                        <label>{t("profile.label.address")}</label>
                        <input {...register("address", {required: true})} type="text" className="form-control"  name='address'/>
                        {errors?.address && <p className="msg-error">{t("profile.message.reqAddress")}</p>}
                    </div>
                </div>

                <div className="form p-4">
                    <div className="form-group">
                        <label>{t("profile.label.phone")}</label>
                        <input {...register("cellPhone", {
                            required: true,
                            pattern: {
                                value: /((((\+|)84)|0)(3|5|7|8|9)+([0-9]{8}))\b/,
                                message: 'Please enter a valid email',
                            },})} type="text" className="form-control"  name='cellPhone'/>
                        {errors?.cellPhone && errors?.cellPhone.type === "required" && <p className="msg-error">{t("profile.message.reqPhone")}</p>}
                        {errors?.cellPhone && errors?.cellPhone.type === "pattern" && <p className="msg-error">{t("profile.message.invalidPhone")}</p>}
                    </div>
                </div>
                <div className="form p-4">
                    <div className="form-group">
                        <label>{t("profile.label.email")}</label>
                        <input {...register("email", {
                            required: true,
                            pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                        },})} type="email" className="form-control"  name='email'/>
                        {errors?.email && errors?.email.type === "required" && <p className="msg-error">{t("profile.message.reqEmail")}</p>}
                        {errors?.email && errors?.email.type === "pattern" && <p className="msg-error">{t("profile.message.invalidEmail")}</p>}
                    </div>
                </div>
            </div>
    </div>
    <div className="modal-footer">
        <button className="btn btn-secondary mr-4" onClick={onClickClose}>{t("common.button.cancel")}</button>
        <button className="btn btn-info" onClick={handleSubmit(handleSaveForm)}>{t("common.button.save")}</button>
    </div>
</div>
  )
}
