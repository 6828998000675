import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { ObjectFollow } from "../../models/objectFollow";

export class ObjectFollowService {
    private static _objectFollowService: ObjectFollowService

    public static getInstance(): ObjectFollowService {
        if (!ObjectFollowService._objectFollowService) {
            ObjectFollowService._objectFollowService = new ObjectFollowService()
        }
        return ObjectFollowService._objectFollowService;
    }

    getFollow(objectId: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFollow/getFollow.exclude?objectId=" + objectId);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    addFollow(objectFollow: ObjectFollow) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFollow/addFollow.exclude");
        return axios.post(url,objectFollow, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    deleteFollow(objectId: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFollow/deleteFollow.exclude?objectId=" + objectId);
        return axios.delete(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    getCount(objectId: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/objectFollow/getCount.exclude?objectId=" + objectId);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

}

