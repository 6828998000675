import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ObjectService } from '../../../services/object/ObjectService';
import { Object } from '../../../models/object';
import { useParams } from 'react-router-dom';
import { ObjectFollowService } from '../../../services/object/ObjectFollowService';
import PostView from '../Newsfeed/components/PostView';
import { PostService } from '../../../services/post/PostService';
import { toast } from 'react-toastify';
import AppModal from '../../commons/ui/Modal/AppModal';
import PostDetail from '../Newsfeed/components/PostDetail/PostDetail';
import AddObject from '../WriteReview/components/AddObject';
import { useAppDispatch, useAppSelector } from '../../../hooks/hook';
import { showAndHideSpinner } from '../../../redux/reducers/spinnerSlice';
import { PaginationConstant } from '../../../constants/PaginationConstant';
export default function Home(props: any) {
  const { t } = useTranslation();
  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });
  const dispatch = useAppDispatch();
  const [object, setObject] = useState<any>();
  const [followCount, setFollowCount] = useState<number>();
  const [lstPost, setLstPost] = useState<any[]>([]);
  const [totalPost, setTotalPost] = useState<any>(0);
  const [photoList, setPhotoList] = useState<any[]>([]);
  const [isOpenPostDetailPopup, setIsOpenPostDetailPopup] = useState(false);
  const [isOpenPopupEditObject, setIsOpenPopupEditObject] = useState(false);
  const [postDetail, setPostDetail] = useState<any>({});
  const myInfo = useAppSelector((state) => state.userInfo.userInfo);
  useEffect(() => {
    if (props.data.object) {
      setObject(props.data.object)
      setFollowCount(props.data.followCount)
      getPhotoList(props.data.lstPost);
      _refreshData()
      setSearchRequest({
        ...searchRequest,
        objectId: props.data.object.id,
      });
    }
  }, [props])

  function _getData() {
    dispatch(showAndHideSpinner(true));
    ObjectService.getInstance()
      .searchPostByObject(searchRequest)
      .then((result: any) => {
        dispatch(showAndHideSpinner(false));
        if (result.data.status) {
          let lstTemp = lstPost.concat(result.data.responseData.lstPost);
          setLstPost(lstTemp);
          setTotalPost(result.data.responseData.totalElement);

        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(showAndHideSpinner(false));
      });
  }

  function _refreshData() {
    // dispatch(showAndHideSpinner(true));
    ObjectService.getInstance()
      .searchPostByObject({
        page: 0,
        size: PaginationConstant.PAGE_SIZE,
        objectId: props.data.object.id,
      })
      .then((result: any) => {
        dispatch(showAndHideSpinner(false));
        if (result.data.status) {
          setLstPost(result.data.responseData.lstPost);
          setTotalPost(result.data.responseData.totalElement);

        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(showAndHideSpinner(false));
      });
  }
  useEffect(() => {
    if (searchRequest.objectId) {
      _getData();
    }

  }, [searchRequest.page])
  useEffect(() => {
    if (searchRequest.objectId && searchRequest.objectId !== "") {
      _refreshData();
    }
  }, [searchRequest.objectId]);
  const loadMorePosts = () => {
    setSearchRequest({
      ...searchRequest,
      page: searchRequest.page + 1,
    });
  };
  const getPhotoList = (posts: any) => {
    const photoList: any = [];
    if (posts) {
      posts.forEach((post: any) => {
        if (post.attachments) {
          post.attachments.forEach((attachment: any) => {
            if (attachment.atchFleSeq) {
              photoList.push({
                url: process.env.REACT_APP_API_URL + "/public/image-view/" + attachment.atchFleSeq,
                id: post.id
              });
            }
          });
        }
      });
      setPhotoList(photoList);
    }
  }
  const viewPostDetail = (postId: any) => {
    PostService.getInstance().getPostDetail(postId).then((response: any) => {
      if (response.data.status) {
        setPostDetail({
          ...response.data.responseData,
          // user: props.userInfo
        })
        setIsOpenPostDetailPopup(true)
      }

    }).catch((error: any) => {
      // console.log("Server error: " + error);
      toast.error(t("toast.serverError"))
    })
  }

  const onClosePopupAddObject = (data: any) => {
    setIsOpenPopupEditObject(false);
    if (data) {
      props.refreshObject()
    }
  };
  const onClosePostViewPopup = (data: any) => {
    setIsOpenPostDetailPopup(false)
  }
  return (
    <>
      <AppModal
        open={isOpenPopupEditObject}
        onClose={() => setIsOpenPopupEditObject(false)}
        title={"Sửa thông tin"}
      >
        <AddObject onClose={onClosePopupAddObject} object={object}></AddObject>
      </AppModal>
      <AppModal
        open={isOpenPostDetailPopup}
        children={<PostDetail postId={postDetail.id} />}
        onClose={onClosePostViewPopup}
      ></AppModal>
      <div className="row">
        <div className="col-lg-4 d-lg-block d-none widget-block widget-break-lg">
          <div className="widget widget-user-about">
            <div className="widget-heading">
              <h3 className="widget-title">{t("object.menu.introduce")}</h3>
              {myInfo && myInfo.rolesStr?.includes("R000") && <div className="dropdown">
                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  ...
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" onClick={() => setIsOpenPopupEditObject(true)}>{t("object.button.edit")}</a>
                </div>
              </div>
              }
            </div>
            <div className="user-info">
              <p>{object?.description}</p>
              <ul className="info-list">
                {followCount ? <li><i className="icofont-rss text-dark-c info-icon"></i><span className="ml-2 font-weight-bold text-dark-c">{followCount} {t("object.label.follow")}</span></li> : <></>}
                {object?.objectDetail?.email ? <li><i className="icofont-email text-dark-c info-icon"></i><span className="ml-2 font-weight-bold text-dark-c">{object?.objectDetail?.email}</span></li> : <></>}
                {object?.address ? <li><i className="icofont-location-pin text-dark-c info-icon"></i><span className="ml-2 font-weight-bold text-dark-c">{object?.address}</span></li> : <></>}
                {object?.objectDetail?.phoneNumber ? <li><i className="icofont-phone text-dark-c info-icon"></i><span className="ml-2 font-weight-bold text-dark-c">{object?.objectDetail?.phoneNumber}</span></li> : <></>}
                {object?.objectDetail?.website ? <li><i className="icofont-web text-dark-c info-icon"></i><a className="ml-2 font-weight-bold text-dark-c" href={object?.objectDetail?.website}>
                  {object?.objectDetail?.website}</a></li> : <></>}
                {/* <li className="social-share"><span>Social:</span>
                  <div className="social-icon">
                    <a href="#"><i className="icofont-facebook"></i></a>
                    <a href="#"><i className="icofont-twitter"></i></a>
                    <a href="#"><i className="icofont-dribbble"></i></a>
                    <a href="#"><i className="icofont-whatsapp"></i></a>
                    <a href="#"><i className="icofont-instagram"></i></a>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="widget widget-gallery">
            <div className="widget-heading">
              <h3 className="widget-title">{t("object.menu.image")}</h3>
            </div>
            {photoList &&
              <ul className="photo-list">
                {photoList.length > 9 && photoList.slice(0, 8).map((image: any, index: number) => (
                  <li key={index}><a onClick={() => viewPostDetail(image.id)} key={image.id}><img style={{ objectFit: "cover" }} src={image.url}
                    onError={(event: any) => {
                      event.target.src = require("../../../assets/placeholder.jpg")
                    }} /></a></li>
                ))}
                {photoList.length <= 9 && photoList.map((image: any, index: number) => (
                  <li key={index}><a onClick={() => viewPostDetail(image.id)} key={image.id}><img style={{ objectFit: "cover" }} src={image.url}
                    onError={(event: any) => {
                      event.target.src = require("../../../assets/placeholder.jpg")
                    }} /></a></li>
                ))}
                {photoList.length > 9 &&
                  <li>
                    <a onClick={() => props?.onSeeMoreImg()} style={{ objectFit: "cover" }} data-photo={photoList.length - 8 + "+"}>
                      <img style={{ filter: "brightness(0.7)", objectFit: "cover" }} src={photoList[8].url} onError={(event: any) => {
                        event.target.src = require("../../../assets/placeholder.jpg")
                      }} />
                    </a>
                  </li>
                }
                {photoList.length == 0 &&
                  <p style={{ margin: "0 auto" }}>Không có ảnh để hiển thị</p>
                }
              </ul>
            }
          </div>
        </div>
        <div className="col-lg-8 col-12">
          {lstPost && lstPost.map((post: any, index: any) => (
            <div key={index}>
              <PostView key={post.id} post={post}></PostView>
              {
                lstPost.length == 0 && (
                  <p style={{ textAlign: "center", fontSize: "1.25rem" }}>Không có bài viết</p>
                )
              }
            </div>
          ))}
          {lstPost && (
            <div>
              {totalPost > lstPost?.length && (
                <div className="block-box load-more-btn">
                  <a className="item-btn" onClick={loadMorePosts}>
                    <i className="icofont-refresh"></i>Load More Posts
                  </a>
                </div>
              )}
            </div>
          )}
        </div>

      </div>
    </>
  )
}

