import React, { useEffect, useState } from 'react'
import { GroupService } from '../../../../services/group/GroupService'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function GroupInfoMembers(props: any) {

    
    const { t, i18n } = useTranslation();

    const [lstMembers, setLstMembers] = useState<[]>()
    const navigate = useNavigate();

    useEffect(()=>{
        GroupService.getInstance().getMembersInfo({lstMembers: props.data.lstMembers}).then((result:any)=>{
            if(result.data.status){
              setLstMembers(result.data.responseData)  
            }
        }).catch((error:any)=>{
            console.log(error);
        })
    }, [props])

    const changeUser = (uid:any)=>{
        navigate("/profile/" + uid)
    }
  return (
    
    <>
    { lstMembers && lstMembers.length > 0 ?
            <div className="user-list-view forum-member">
                {lstMembers.map((user: any) => (
                        <div className="widget-author block-box" key={user.userUid}>
                            <div className="author-heading">
                                <div className="cover-img">
                                    <img src="media/figure/cover_1.jpg" alt="cover" />
                                </div>
                                <div className="group-img">
                                    <a onClick={()=>changeUser(user.userUid)}>
                                        <img style={{ width: "100px", height: "100px" }} src={user?.imgPathBase64 || ""} onError={(event: any) => {
                                            event.target.src = "media/default-avatar.jpg"
                                        }} alt="author" />
                                    </a>
                                </div>
                                <div className="profile-name mx-2" style={{cursor: "pointer"}} onClick={()=>changeUser(user.userUid)}>
                                    <h4 className="author-name text-dark-c">
                                        <>{user.fullName}</>
                                        { user.legal && <i className="icofont-check legal mx-1" style={{ borderRadius: "50%", color: "var(--deep-lilac-color)", border: "2px solid var(--deep-lilac-color)"}}></i>}
                                    </h4>
                                    <div className="author-location">{user.email}</div>
                                </div>
                            </div>
                            {/* <ul className="author-badge">
                                <li><a href="#" className="bg-salmon-gradient"><i className="icofont-star"></i></a></li>
                                <li><a href="#" className="bg-sun-gradient"><i className="icofont-squirrel"></i></a></li>
                                <li><a href="#" className="bg-jungle-gradient"><i className="icofont-rocket-alt-1"></i></a></li>
                            </ul> */}
                        </div>
                ))}
            </div>    
    :
        <>
            <div className="text-center">
            <h5>{t("group.text.no-data")}</h5>
            </div>
        </>
    }

        </>
  )
}
