import React, { useEffect, useState } from "react";
import { GroupService } from "../../../services/group/GroupService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";
import SearchSelectComp from "../../commons/form/SearchSelect";
import { useTranslation } from "react-i18next";
import "./Group.css";
import GroupClassificationDropdownCustom from "./GroupInfo/component/GroupClassificationDropdownCustom";
import AppModal from "../../commons/ui/Modal/AppModal";
import AddObject from "../WriteReview/components/AddObject";
import AddGroupClassification from "./GroupInfo/component/AddGroupClassification";
import { GroupClassificationService } from "../../../services/group/GroupClassificationService";
import { group } from "console";

export default function CreateGroupForm(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [optionsUser, setOptionsUser] = useState<any>([]);
  const [lstSelected, setLstSelected] = useState<any[]>([]);
  const [groupClassification, setGroupClassification] = useState<any>([]);
  const [lstClassificationSlt, setLstClassificationSlt] = useState<any>([]);
  const [isOpenAddGroupClassification, setIsOpenAddGroupClassification] = useState<any>();

  const onClickClose = () => {
    props.onCloseForm(false);
  };

  useEffect(() => {
    _getGroupClassification();
  }, []);

  const onSelectChange = (data: any) => {
    if (lstSelected.includes(data) === false) {
      setLstSelected([...lstSelected, data]);
    }
  };
  const formatOptionLabel = (data: any) => (
    <div style={{ display: "flex" }}>
      <div style={{ display: "none" }}>{data.userUid}</div>
      <div style={{ marginLeft: "10px" }}>{data.name}</div>
    </div>
  );

  const handleSaveForm = (dataForm: any) => {
    dispatch(showAndHideSpinner(true));
    // searchOptions(newValue)
    dataForm.lstMembers = lstSelected;
    dataForm.classification = lstClassificationSlt;
    GroupService.getInstance()
      .createGroup(dataForm)
      .then((result: any) => {
        if (result.data.status === true) {
          toast.success(t("group.message.create-success"));
          props.onCloseForm(true);
        } else {
          toast.error(t("group.message.create-err"));
        }
        dispatch(showAndHideSpinner(false));
      })
      .catch((error: any) => {
        dispatch(showAndHideSpinner(false));
        toast.error(t("common.message.server-error"));
      });
  };

  const onChangeGroupClassification = (data: any) => {
    setLstClassificationSlt(data);
  };

  const onClickEventNoOptions = () => {
    setIsOpenAddGroupClassification(true);
  };

  const onClosePopupAddGroupClassification = (data: any) => {
    setIsOpenAddGroupClassification(false);

    if (data) {
      _getGroupClassification();
    }
  };

  function _getGroupClassification() {
    GroupClassificationService.getInstance()
      .getAll()
      .then((res: any) => {
        setGroupClassification(res.data.responseData);
      })
      .catch((err: any) => {});
  }

  const handleDeleteMember = (userId: any) => {
    setLstSelected(lstSelected.filter((item) => item.userUid !== userId));
  };

  const loadData = (inputValue: any) => {
    if (inputValue.trim() !== "") {
      GroupService.getInstance()
        .searchUserByName({ keySearch: inputValue.trim() })
        .then((result) => {
          const options: any = [];
          if (result.data.responseData) {
            result.data.responseData.forEach((element: any) => {
              const item = {
                userUid: element.userUid,
                name: element.fullName,
                avt: element.imgPathBase64,
              };
              options.push(item);
            });
          }
          setOptionsUser(options);
        })
        .catch((error: any) => {});
    } else {
      setOptionsUser([]);
    }
  };

  return (
    <>
      <AppModal open={isOpenAddGroupClassification} onClose={() => setIsOpenAddGroupClassification(false)} title={"Add Group Classification"}>
        <AddGroupClassification onClose={onClosePopupAddGroupClassification}></AddGroupClassification>
      </AppModal>
      <div className="modal-content">
        {/* <div className="modal-header">
          <h5 className="modal-title fs-5 text-center">Create group</h5>
          <button
            type="button"
            className="close chat-close chat-open"
            onClick={onClickClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div> */}
        <div id="scroll-modal" className="modal-body" style={{ maxHeight: "700px", overflowY: "scroll" }}>
          <h3 className="heading-title px-4">{props.title}</h3>
          <div className="form px-4 forum-topic-add" id="new-post-form">
            <div className="form-group">
              <label>{t("group.label.group-name")}</label>
              <input {...register("groupName", { required: true })} type="text" className="form-control" name="groupName" />
              {errors?.groupName && <p className="msg-error">{t("group.message.requires-name")}</p>}
            </div>
            <div className="form-group">
              <label>{t("group.label.description")}</label>
              <input {...register("description", { required: true })} name="description" type="text" className="form-control" />
              {errors?.description && <p className="msg-error">{t("group.message.requires-des")}</p>}
            </div>
            <div className="form-group">
              <label>{t("group.label.classification")}</label>
              <GroupClassificationDropdownCustom
                sources={groupClassification}
                bindValue={"id"}
                bindLabel={"classificationName"}
                onChange={onChangeGroupClassification}
                isMulti={true}
                onClickEventNoOptions={onClickEventNoOptions}
                isValid={errors?.selectedGroupCate ? false : true}></GroupClassificationDropdownCustom>
            </div>
            <div className="form-group">
              <label>{t("group.label.members")}</label>
              <SearchSelectComp label="" placeholder="Search..." timeout={1000} options={optionsUser} onInputChange={loadData} formatOptionLabel={formatOptionLabel} onSelectOption={onSelectChange} />
              <div className="group-list mt-3" style={{ overflowY: "scroll", maxHeight: "100px" }}>
                {lstSelected &&
                  lstSelected.map((item) => (
                    <div className="media mb-2 p-1" key={item.userUid} style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 3px", borderRadius: "5px" }}>
                      <div className="item-img">
                        <img
                          style={{ width: "50px", height: "50px", borderRadius: "25px" }}
                          src={item.avt || ""}
                          onError={(event: any) => {
                            event.target.src = "media/default-avatar.jpg";
                          }}
                          alt="group"
                        />
                      </div>
                      <div className="media-body mx-2">
                        <h6 className="item-title mt-2 ">
                          <p>{item.name}</p>
                        </h6>
                      </div>
                      <div>
                        <button type="button" className="delete-member chat-close chat-open" onClick={() => handleDeleteMember(item.userUid)}>
                          <span aria-hidden="true" className="text-danger">
                            ×
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary mr-4" onClick={onClickClose}>
            {t("common.button.cancel")}
          </button>
          <button className="btn btn-info" onClick={handleSubmit(handleSaveForm)}>
            {t("common.button.save")}
          </button>
        </div>
      </div>
    </>
  );
}
