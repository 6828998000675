import React, { useEffect, useState } from 'react'
import "./PostReport.css"
import ReportService from '../../../../../services/report/ReportService';
import { toast } from 'react-toastify';
import { t } from 'i18next';
type Props = {
  post?: any;
  onClose: (data?: any) => void;
};
export default function PostReport(props: Props) {
  const [reportType, setReportType] = useState<any>();
  const [showArea, setShowArea] = useState<any>();
  const [isDisableBtn, setIsDisableBtn] = useState<any>(true);
  const [checkedBoxes, setCheckedBoxes] = useState<any>([]);
  useEffect(() => {
    ReportService.getInstance().getReportType().then((resp) => {
      if (resp.data.status) {
        setReportType(resp.data.responseData);
      }
    })
      .catch((err) => { });
  }, []);
  
  const handleOther = (e : any) => {
    setShowArea(!showArea);
    handleChange(e);
  }
  useEffect(() => {
    setIsDisableBtn(checkedBoxes.length === 0);
  }, [checkedBoxes]);

  const handleChange = (event : any) => {
    const {value, checked } = event.target;
    if (checked) {
      setCheckedBoxes([...checkedBoxes, value]);
    } else {
      setCheckedBoxes(checkedBoxes.filter((box : any) => box !== value));
    }
  };

  function handleSubmit(event: any) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const checkedValues: any = [];
    formData.getAll("reportType").forEach((value) => {
      checkedValues.push(Number(value));
    });
    const textAreaValue = formData?.get("other");
    if(checkedValues.length == 0 && !showArea){
      return;
    }
    if(showArea && textAreaValue === ""){
      toast.warn(t("toast.validReport"));
      return;
    }
    const request: any = {};
    request.postId = props?.post.id;
    request.reportOther = textAreaValue;
    request.reportTypeCode = checkedValues;
    ReportService.getInstance().addPostReport(request).then((resp) => {
      if (resp.data.status) {
        toast.warn(t("toast.reportSuccess"));
        props.onClose();
      }
    })
      .catch((err) => {
        toast.warn(t("toast.reportFailed"));
       });
  }

  return (
    <>
      <div className="report-content">
        <form onSubmit={handleSubmit}>
          {reportType && reportType.map((element: any, index: any) => (
            <div key={index}>
              <label className="form-control">
                <input type="checkbox" name="reportType" value={element.id} onChange={handleChange} />
                {element.reportName}
                {element.reportName != "Khác" ? <i className="fa fa-question-circle-o onhover-div" aria-hidden="true">
                  <ul className="show-div">
                    <span>{element.description}</span>
                  </ul>
                </i> : <></>}
              </label>
            </div>
          ))}
          <label className="form-control">
            <input type="checkbox" onChange={handleOther} />Khác
          </label>
          {showArea ? <textarea name="other" placeholder="Nội dung... " rows={6}></textarea> : <></>}
          <div className="submit-btn" style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
            <button onClick={props.onClose} type='button' className="cancel">Hủy</button>
            <button disabled={isDisableBtn ? true : false} type='submit' className="submit">Gửi</button>
          </div>
        </form>
      </div>
    </>
  )
}
