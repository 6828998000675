import { useEffect, useState } from 'react'
import { ReviewerService } from '../../../services/reviewer/ReviewerService'
import { useAppDispatch, useAppSelector } from '../../../hooks/hook';
import { showAndHideSpinner } from '../../../redux/reducers/spinnerSlice';
import { CommonConstant } from '../../../constants/CommonConstant';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FollowerTab(props: any) {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const myInfo = useAppSelector((state) => state.userInfo.userInfo);
    const [lstUsers, setLstUsers] = useState<[]>()
    const [lstObject, setLstObject] = useState<[]>()

    useEffect(() => {
        if (props.lstUser) {
            _getLstUsers()
        }
        if (props.lstObject) {            
            _getLstObjects()
        }
    }, [props])

    function _getLstUsers() {
        dispatch(showAndHideSpinner(true))
        ReviewerService.getInstance().getLstUserInfo({ lstUser: props.lstUser }).then(res => {
            if (res.data.status) {
                setLstUsers(res.data.responseData)
                dispatch(showAndHideSpinner(false))
            }
        }).catch((error: any) => {
            // console.log(error);
            dispatch(showAndHideSpinner(false))
        })
    }

    function _getLstObjects() {
        dispatch(showAndHideSpinner(true))
        let lstObjectId:any = [];
        props.lstObject.forEach((item:any) => {
            lstObjectId.push(item.objectid);
        })
        ReviewerService.getInstance().getLstObjectInfo({ lstObjectId: lstObjectId }).then(res => {
            if (res.data.status) {
                setLstObject(res.data.responseData)
                dispatch(showAndHideSpinner(false))                
            }
        }).catch((error: any) => {
            // console.log(error);
            dispatch(showAndHideSpinner(false))
        })
    }

    const changeUser = (uid: any) => {
        navigate("/profile/" + uid)
        props.onChangeUser(uid)
    }

    const redirectObjectProfile = (objectId:any) => {
        navigate("/object/" + objectId)
    }
    return (
        <div className="row">

            <div className={props.type === CommonConstant.FOLLOW_TYPE_FOLLOWER ? "user-list-view forum-member col-md-12" : "user-list-view forum-member col-md-6"} >
                {props.type === CommonConstant.FOLLOW_TYPE_FOLLOWING && <h5>{t("profile.following-user")}</h5>}
                {lstUsers && lstUsers.length > 0 ?
                    <>
                        {lstUsers && lstUsers.map((user: any) => (
                            <>
                                <div className="widget-author block-box" key={user.userUid + props.type === CommonConstant.FOLLOW_TYPE_FOLLOWER? 12: 6}>
                                    <div className="author-heading" style={{ flex: "5" }}>
                                        <div className="cover-img">
                                            <img src="media/figure/cover_1.jpg" alt="cover" />
                                        </div>
                                        <div className="group-img">
                                            <a onClick={() => changeUser(user.userUid)}>
                                                <img style={{ width: "100px", height: "100px" }} 
                                                src={process.env.REACT_APP_API_URL +
                                                "/public/image-view/" +
                                                user?.avatarImage?.atchFleSeq}  alt="author" 
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../assets/default-avatar.jpg")
                                                  }}/>
                                            </a>
                                        </div>
                                        <div className="profile-name mx-2" style={{cursor: "pointer"}} onClick={() => changeUser(user.userUid)}>
                                            <h4 className="author-name text-dark-c">
                                                <>{user.fullName}</>
                                                { user.legal && <i className="icofont-check legal mx-1" style={{ borderRadius: "50%", color: "var(--deep-lilac-color)", border: "2px solid var(--deep-lilac-color)"}}></i>}
                                            </h4>
                                            <div className="author-location">{user.email}</div>
                                        </div>
                                    </div>
                                    <div className='author-statistics'>
                                        {/* {myInfo.userUid == user.userUid? <button className='btn btn-secondary'>Unfollow</button>: <button className='btn btn-success'>Follow</button>} */}
                                    </div>
                                    {/* <ul className="author-statistics">
                                        <li>
                                            <a href="#"><span className="item-number">30</span> <span className="item-text">POSTS</span></a>
                                        </li>
                                        <li>
                                            <a href="#"><span className="item-number">12</span> <span className="item-text">COMMENTS</span></a>
                                        </li>
                                        <li>
                                            <a href="#"><span className="item-number">1,125</span> <span className="item-text">VIEWS</span></a>
                                        </li>
                                    </ul> */}
                                </div>
                            </>
                        ))}
                    </>
                    :
                    <>
                        <div className="text-center">
                            <h5>{t("profile.no-data")}</h5>
                        </div>
                    </>
                }

            </div>            

        

            {props.type === CommonConstant.FOLLOW_TYPE_FOLLOWING &&
                <div className="user-list-view forum-member col-md-6">
                    <h5>{t("profile.following-obj")}</h5>
                    { lstObject && lstObject.length > 0 ?
                        <>
                        {
                            lstObject && lstObject.map((object:any)=>(
                                <div className="widget-author block-box" key={object.id}>
                                <div className="author-heading" style={{ flex: "5" }}>
                                    <div className="cover-img">
                                        <img src="media/figure/cover_1.jpg" alt="cover" />
                                    </div>
                                    <div className="group-img check">
                                        <a onClick={() => redirectObjectProfile(object.id)}>
                                            <img style={{ width: "100px", height: "100px" }} 
                                            src={process.env.REACT_APP_API_URL +
                                                "/public/image-view/" +
                                                object?.avatarImage?.atchFleSeq} 
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../assets/default-avatar.jpg")
                                                  }} alt="author" />
                                        </a>
                                    </div>
                                    <div className="profile-name mx-2" style={{cursor: "pointer"}} onClick={() => redirectObjectProfile(object.id)}>
                                        <h4 className="author-name text-dark-c">
                                            <>{object.objectName}</>
                                            { object.legal && <i className="icofont-check legal mx-1" style={{ borderRadius: "50%", color: "var(--deep-lilac-color)", border: "2px solid var(--deep-lilac-color)"}}></i>}
                                        </h4>
                                        <div className="author-location">{object.objectDetail.email}</div>
                                    </div>
                                </div>
                                <div className='author-statistics'>
                                </div>
                            </div>
                            ))
                        }
                        </>
                        :
                        <div className="text-center">
                            <h5>{t("profile.no-data")}</h5>
                        </div>
                    }
                </div>
            }

        </div>
    )
}
