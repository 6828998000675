import { useEffect, useState } from "react";
import AppTimeAgo from "../../../../commons/ui/DateFormat/AppTimeAgo";
import Rating from "../../../../commons/post/Rating";
import Reaction from "../../../../commons/post/Reaction";
import Comment from "../../../../commons/post/Comment/Comment";
import { useForm } from "react-hook-form";
import { CommentService } from "../../../../../services/post/CommentService";
import { useDispatch } from "react-redux";
import { showAndHideSpinner } from "../../../../../redux/reducers/spinnerSlice";
import { DataUtil } from "../../../../../utils/DataUtil";
import PostAction from "../PostAction";
import Share from "../../../../commons/post/Share";
import HoverCard from "../../../../commons/post/HoverCard/HoverCard";

import Carousel from "react-multi-carousel";
import { PostService } from "../../../../../services/post/PostService";
import AvatarImage from "../../../../commons/post/AvatarImage";
import "./PostDetail.css";
import { CommonConstant } from "../../../../../constants/CommonConstant";
import { HabbitService } from "../../../../../services/habbit/HabbitService";
import { useAppSelector } from "../../../../../hooks/hook";
import { useTranslation } from "react-i18next";

type PostDetailProps = {
  // post: any;
  postId?: any;
  refresh?: () => void;
  isShared?: boolean;
  onChangeComment?: (data?: any) => void;
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function PostDetail(props: PostDetailProps) {

  const { t, i18n } = useTranslation();

  const { register, getValues, setValue } = useForm();
  const myInfo = useAppSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();
  const [post, setPost] = useState<any>({});
  const [postId, setPostId] = useState(props.postId);
  const [commentList, setCommentList] = useState<any[]>([]);
  const [moveToComment, setMoveToComment] = useState<any>("");

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    getPostDetail();
    getComments();
  };

  const getPostDetail = () => {
    PostService.getInstance()
      .getPostDetail(postId)
      .then(
        (res) => {
          if (res.data.status) {
            setPost(res.data.responseData);
          }
        },
        (error) => {}
      );
  };

  const handleSubmitComment = (event: any) => {
    if (event.key !== "Enter" || !getValues("content")) {
      return;
    }

    let request = {
      postId: postId,
      upCommentId: null,
      content: getValues("content"),
      attachments: null,
    };

    CommentService.getInstance()
      .save(request)
      .then((res) => {
        if (res.data.status) {
          refresh();
          setPost({ ...post, totalComment: post.totalComment + 1 });
          setValue("content", "");
          setMoveToComment(res.data.responseData.id);
          props.onChangeComment?.("INCREASE");
        }
      });
    //collect rabbit
    const habbit: any = {
      actionType: CommonConstant.ACTION_TYPE_COMMENT,
      postId: postId,
    };
    HabbitService.getInstance()
      .save(habbit)
      .then((resp) => {
        if (resp.data.status) {
          // console.log(resp);
        }
      })
      .catch((err) => {});
  };

  const getComments = () => {
    dispatch(showAndHideSpinner(true));
    CommentService.getInstance()
      .getByPostId(postId)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then((res) => {
        if (res.data.status) {
          let cmts = DataUtil.recursive(
            "upCommentId",
            "id",
            res.data.responseData
          );
          setCommentList(cmts);
          
        }
      });
  };

  const refresh = () => {
    getComments();
  };

  const onChangeEmoji = (type: any, preEmoji: any, currentEmoji: any) => {

    let postTemp = Object.assign({}, post);

    if (type === 'INCREASE') {
      let emoji = {
        commcd: currentEmoji.commCd,
        valueconfig: currentEmoji.valueConfig,
        commnm: currentEmoji.commNm,
        commnmen: currentEmoji.commNmEn,
        commnmvi: currentEmoji.commNmVi,
      };
      postTemp.reactionDetail.totalEmoji += 1;
      let emojis: any[] = postTemp.reactionDetail.emojis || [];
      let isExist = false;
      emojis.forEach((item) => {
        if (item.commcd === emoji.commcd) {
          isExist = true;
          item.total += 1;
        }
      });
  
      if (!isExist) {
        emojis.push(emoji);
      }
      postTemp.reactionDetail.emojis = emojis;

    } else if (type === 'DECREASE') {
      postTemp.reactionDetail.totalEmoji -= 1;
      postTemp.reactionDetail.emojis.forEach((item: any) => {
        if (item.commcd === currentEmoji.commCd) {
          item.total -= 1;
        }
      });

      let emojis: any[] = postTemp.reactionDetail.emojis || [];
      emojis = emojis.filter((ele: any) => ele.total > 0);

      postTemp.reactionDetail.emojis = emojis;

    } else {
      let emojis: any[] = postTemp.reactionDetail.emojis || [];
      emojis.forEach((item: any) => {
        if (item.commcd === preEmoji.commCd) {
          item.total -= 1;
        }
      });
      emojis = emojis.filter((ele: any) => ele.total > 0);

      let emoji = {
        commcd: currentEmoji.commCd,
        valueconfig: currentEmoji.valueConfig,
        commnm: currentEmoji.commNm,
        commnmen: currentEmoji.commNmEn,
        commnmvi: currentEmoji.commNmVi,
      };
   
      let isExist = false;
      emojis.forEach((item) => {
        if (item.commcd === emoji.commcd) {
          isExist = true;
          item.total += 1;
        }
      });
  
      if (!isExist) {
        emojis.push(emoji);
      }
      
      postTemp.reactionDetail.emojis = emojis;
    }
    
    setPost(postTemp);
  }

  return (
    <>
      <div
        className="modal-body post-detail scrollbar"
        style={{ maxHeight: props.isShared ? 'unset':'700px', overflowY: props.isShared? 'unset':'scroll', borderRadius: '8px 8px 0 0', marginBottom: '20px'}}
      >
        <div className="post-view">
          <div className="post-header">
            <div className="media">
              <div className="user-img" style={{ width: "54px" }}>
                <AvatarImage
                  atchFleSeq={post?.creator?.avatarImage?.atchFleSeq}
                ></AvatarImage>
              </div>
              <div className="post-view">
                <div className="user-title">
                  <HoverCard post={post} type={2}></HoverCard>
                  &nbsp;{t("newsfeed.post.postedReviewAbout")}&nbsp;
                  <HoverCard post={post} type={1}></HoverCard>
                  <i className="icofont-check"></i>
                </div>
                <ul className="entry-meta">
                  <li className="meta-privacy">
                    {
                      post.groupId ? 
                      <><i className="icofont-ui-user-group"></i> {t("newsfeed.post.group")}</>
                      :
                      <><i className="icofont-world"></i> {t("newsfeed.post.public")}</>
                    }
                  </li>
                  <li className="meta-time">
                    <AppTimeAgo date={post.createdDate}></AppTimeAgo>
                  </li>
                </ul>
              </div>
            </div>
            <PostAction post={post} refresh={refresh}></PostAction>
          </div>
          <div className="post-body">
            <p className="post-title">{post.title}</p>
            <div className="post-content">{post.content}</div>
            {!post.postSharedId && (
              <>
                <div className="rate-area">
                  <ul>
                    {post.ratingProps?.map((ele: any, idx: any) => (
                      <li key={idx}>
                        <label>{ele.propNm}</label>
                        <Rating rate={ele.rating}></Rating>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  {/* <PostImage attachments={post.attachments} detail = {true}/> */}
                  {post.attachments && (
                    <Carousel responsive={responsive} autoPlaySpeed={3000}>
                      {post.attachments.map((ele: any, idx: any) => (
                        <div key={idx} className="carousel-img">
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              "/public/image-view/" +
                              ele.atchFleSeq
                            }
                            onError={(event: any) => {
                              event.target.src = require("../../../../../assets/placeholder.jpg");
                            }}
                          />
                        </div>
                      ))}
                    </Carousel>
                  )}
                </div>
                <div className="post-meta-wrap">
                  <div className="post-meta">
                    {post.reactionDetail?.totalEmoji > 0 && (
                      <div className="post-reaction">
                        <div className="reaction-icon">
                          {post.reactionDetail?.emojis.map(
                            (ele: any, idx: any) =>
                              idx <= 1 && (
                                <img
                                  key={idx}
                                  src={ele.valueconfig}
                                  alt="icon"
                                />
                              )
                          )}
                        </div>
                        <div className="meta-text">
                          {post.reactionDetail?.totalEmoji}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="post-meta">
                    <div className="meta-text">
                      {post.totalComment} {t("newsfeed.post.comments")}
                    </div>
                    {/* <div className="meta-text">05 Share</div> */}
                  </div>
                </div>
              </>
            )}

            {post.postSharedId && (
              <PostDetail
                postId={post.postSharedId}
                isShared={true}
              ></PostDetail>
            )}
          </div>

          {!props.isShared && (
            <>
              <div className="post-footer">
                <ul>
                  <Reaction
                    post={post}
                    onChangeEmoji={(type, preEmoji, currentEmoji) => onChangeEmoji(type, preEmoji, currentEmoji)}
                  ></Reaction>
                  <li>
                    <a>
                      <i className="icofont-comment"></i>{t("newsfeed.post.comment")}
                    </a>
                  </li>
                  <Share post={post}></Share>
                </ul>
              </div>
              <Comment
                postId={post.id}
                commentList={commentList}
                refresh={refresh}
                moveToComment={moveToComment}
              ></Comment>
            </>
          )}
        </div>
      </div>
      {!props.isShared && (
        <div className="modal-footer-post-view">
          <div className="post-view">
            <div className="post-comment" style={{ borderTop: "unset" }}>
              <div className="comment-reply">
                <div
                  className="user-img"
                  style={{ width: "54px", height: "54px" }}
                >
                  <AvatarImage atchFleSeq={myInfo?.avatarImage?.atchFleSeq}></AvatarImage>
                </div>
                <div className="input-box">
                  <input
                    {...register("content", { required: true })}
                    type="text"
                    name="content"
                    className="form-control"
                    placeholder={t("newsfeed.comment.inputPlaceholder")}
                    onKeyUp={handleSubmitComment}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
