import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { getUserInfo } from "../../../redux/reducers/userSlice";
import { NavLink, createSearchParams, useLocation, useNavigate } from "react-router-dom";
import AvatarImage from "../../commons/post/AvatarImage";
import { AuthUtil } from "../../../utils/AuthUtil";
import { useForm } from "react-hook-form";
import { CommonConstant } from "../../../constants/CommonConstant";
import { HabbitService } from "../../../services/habbit/HabbitService";
import { SocketService } from "../../../services/ws/SocketService";
import AppNotification from "./components/AppNotification";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function Header() {

  const { t, i18n } = useTranslation();

  const currentUserInfo = useAppSelector((state) => state.userInfo.userInfo);
  const { register, getValues, setValue } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [lstHistorySearch, setLstHistorySearch] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<any>();
  const location = useLocation();
  useEffect(() => {
    const searchParam = new URLSearchParams(location.search).get("searchCont");
    if (searchParam) {
      setSearchValue(new URLSearchParams(location.search).get("searchCont"));
    }
  }, [location]);
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  // useEffect(() => {
  //   if (currentUserInfo.userUid) {
  //     SocketService.getInstance().initSocket();
  //     SocketService.getInstance().socketClient.connect({ username: currentUserInfo.userUid }, () => {
  //     SocketService.getInstance().socketClient.subscribe(
  //         '/chat/topic/messages',
  //         (msg: any) => {
  //           toast.info(msg.body);
  //         },
  //         (err: any) => {
  //           console.log(err);
  //         }
  //       );
  //     },
  //       (e: any) => {
  //         console.log(e);

  //       }
  //     )
  //   }
  // }, [currentUserInfo])

  const handleSearch = (event: any) => {
    if (event.key !== "Enter" || !searchValue) {
      return;
    }
    setLstHistorySearch([]);
    const habbit: any = {
      actionType: CommonConstant.ACTION_TYPE_SEARCH,
      searchCont: searchValue,
    };
    HabbitService.getInstance()
      .save(habbit)
      .then((resp) => {
      })
      .catch((err) => { });
    // setValue("searchCont", "");
    navigate({
      pathname: '/search',
      search: `?${createSearchParams({ searchCont: searchValue })}`,
    });
  }

  const handleHistory = () => {
    HabbitService.getInstance()
      .findUserSearchKeywords()
      .then((resp) => {
        if (resp.data.status) {
          setLstHistorySearch(resp.data.responseData)
        }
      })
      .catch((err) => { });
  }

  const handleLogout = () => {
    AuthUtil.logout();
  };

  const handleHistorySearch = (searchCont: any) => {
    const habbit: any = {
      actionType: CommonConstant.ACTION_TYPE_SEARCH,
      searchCont: searchCont,
    };
    setSearchValue(searchCont);
    setLstHistorySearch([]);
    HabbitService.getInstance()
      .save(habbit)
      .then((resp) => {
      })
      .catch((err) => { });
    // setValue("searchCont", "");
    navigate({
      pathname: '/search',
      search: `?${createSearchParams({ searchCont: searchCont })}`,
    });
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setLstHistorySearch([]);
    }
  };

  const deleteHistorySearch = (habbit: any) => {
    HabbitService.getInstance()
      .delete(habbit)
      .then((resp) => {
        const updatedHistorySearch = lstHistorySearch?.filter((item: any) => item.searchCont !== habbit.searchCont);
        setLstHistorySearch(updatedHistorySearch);
      })
      .catch((err) => { });
  }

  return (
    <>
      <header className="fixed-header">
        <div className="header-menu">
          <div className="navbar">
            <div className="nav-item d-none d-sm-block">
              {/* <div className="header-logo" style={{height: '75px'}}>
                            RNS logo
                        </div> */}
            </div>
            <div className="nav-item header-search2" ref={inputRef}>
              <div className="input-group">
                <span className="icon-search">
                  <i className="icofont-ui-search"></i>
                </span>
                <input
                  {...register("searchCont", { required: true })}
                  type="text"
                  name="searchCont"
                  className="form-control"
                  placeholder={t("header.search.inputPlaceholder")}
                  onKeyUp={handleSearch}
                  onClick={handleHistory}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                {lstHistorySearch && <div className="history-search">
                  <ul>
                    {lstHistorySearch.map(
                      (ele: any, idx: any) => (
                        <li onClick={() => handleHistorySearch(ele.searchCont)} key={idx} className="history-search-item"><span><i className="fa fa-history mr-2"></i>{ele.searchCont}</span><i onClick={(event) => {
                          event.stopPropagation();
                          deleteHistorySearch(ele)
                        }} className="fa fa-remove" ></i></li>
                      )
                    )}
                  </ul>
                </div>
                }
              </div>
            </div>
            <div className="nav-item header-control">
              <div className="header-control-wrap">
                <div className="inline-item">
                  <div className="dropdown dropdown-admin">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="media">
                        <span className="item-img" style={{ width: "45px", height: "45px" }}>
                          <AvatarImage atchFleSeq={currentUserInfo?.avatarImage?.atchFleSeq}></AvatarImage>
                          <span className="acc-verified">
                            <i className="icofont-check"></i>
                          </span>
                        </span>
                        <span className="media-body">
                          <span
                            className="item-title"
                            title={currentUserInfo?.fullName}
                          >
                            {currentUserInfo?.fullName}
                          </span>
                        </span>
                      </span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <ul className="admin-options">
                        <li>
                          <NavLink to={"/profile/" + currentUserInfo?.userUid}>
                            <i className="icofont-user"></i>

                            {t("menu.profile")}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/groups"}>
                            <i className="icofont-group"></i>
                            {t("menu.groups")}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/setting"}>
                            <i className="icofont-settings-alt"></i>
                            {t("menu.setting")}
                          </NavLink>
                        </li>
                        <li>
                          <a onClick={handleLogout}><i className="icofont-logout"></i>
                            {t("menu.logout")}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="inline-item d-flex align-items-center">
                  <AppNotification></AppNotification>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >
    </>
  );
}
