import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./SidebarLeft.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMultiLang,
  langSelector,
} from "../../../../../redux/reducers/multiLangSilce";
import { TranslationConstant } from "../../../../../constants/TranslationConstant";


type SideBarProps = {
  onLogin: () => void;
  getPostsTrending: () => void;
  getPostsLatest: () => void;
  clear: () => void;
};

export default function SidebarLeft(props: SideBarProps) {
  const { t, i18n } = useTranslation();
  const [isLgMenuOpen, setIsLgMenuOpen] = useState(true);
  const [tabFilter, setTabFilter] = useState("TRENDING");

  const dispatch = useDispatch();
  const currentLang = useSelector(langSelector);

  const [languages, setLanguages] = useState<any[]>(() => {
    return TranslationConstant.LANGUAGES;
  });
  const [currentLangInfo, setCurrentLangInfo] = useState<any>({});

  useEffect(() => {
    var temp = languages.find((ele) => ele.key === currentLang);
    setCurrentLangInfo(temp);
  }, [currentLang]);


  // const handleToggleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  //   if (!isLgMenuOpen) {
  //     setIsLgMenuOpen(true);
  //   } else {
  //     removeSideMenu();
  //   }
  // };

  // const removeSideMenu = () => {
  //   setIsLgMenuOpen(false);
  // };

  // const showSideMenu = () => {
  //   setIsLgMenuOpen(true);
  // };

  const onChangeLang = (lang: any) => {
    dispatch(changeMultiLang(lang));
    localStorage.setItem(TranslationConstant.STORAGE_KEY, lang);
    i18n.changeLanguage(lang);
  };

  const login = () => {
    props.onLogin();
  };
  const getPostsTrending = () => {
    props.clear();
    props.getPostsTrending();
    setTabFilter("TRENDING")
  };
  
  const getPostsLatest = () => {
    props.clear();
    props.getPostsLatest();
    setTabFilter("LASTEST")
  };

  return (
    <>
      <div className={`fixed-sidebar ${isLgMenuOpen ? "lg-menu-open" : ""}`}>
        <div className="fixed-sidebar-left small-sidebar">
          <div className="sidebar-toggle">
            <div className="sidebar-logo">{/* RNS logo */}</div>
          </div>
          <div className="sidebar-menu-wrap">
            <div data-mcs-theme="dark" data-mcs-axis="y">
              <ul className="side-menu">
                <li>
                  <a onClick={getPostsTrending} className={"menu-link " + (tabFilter === 'TRENDING'? ' active': '')}>
                    <i className="icofont-chart-histogram-alt"></i>
                  </a>
                </li>
                <li>
                  <a onClick={getPostsLatest} className={"menu-link " + (tabFilter === 'LASTEST'? ' active': '')}>
                    <i className="icofont-news"></i>
                  </a>
                </li>
              </ul>
              <ul
                className="side-menu"
                style={{ position: "absolute", bottom: "200px" }}
              >
                <li>
                  <a onClick={login} className="menu-link">
                    <i className="icofont-login"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="fixed-sidebar-left large-sidebar">
          <div className="sidebar-toggle">
            <div className="sidebar-logo">
              <a href="/">
                {/* <img style={{marginLeft: '5px', height: '86px'}} src="/xemreview_logo2.png"/> */}
                <img
                  style={{ marginLeft: "5px", height: "60px" }}
                  src="/xemreview_logo_white.png"
                />
              </a>
            </div>
            
          </div>
          <div className="sidebar-menu-wrap">
            <div data-mcs-theme="dark" data-mcs-axis="y">
              <ul className="side-menu">
                <li>
                  <a onClick={getPostsTrending} className={"menu-link " + (tabFilter === 'TRENDING'? ' active': '')}>
                    <i className="icofont-chart-histogram-alt"></i>
                    <span className="menu-title">{t("sidebar.trending")}</span>
                  </a>
                </li>
                <li>
                  <a onClick={getPostsLatest} className={"menu-link " + (tabFilter === 'LASTEST'? ' active': '')}>     
                    <i className="icofont-news"></i>
                    <span className="menu-title">{t("sidebar.lastest")}</span>
                  </a>
                </li>
              </ul>
              <ul
                className="side-menu"
                style={{ position: "absolute", bottom: "200px" }}
              >
                <li>
                  <a onClick={login} className="menu-link">
                    <i className="icofont-login"></i>
                    <span className="menu-title">{t("common.button.signIn")}</span>
                    
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer">
              <p className="footer-navigator">
                <NavLink to="/about">{t("menu.aboutUs")}</NavLink>
                <NavLink to="/setting">{t("menu.setting")}</NavLink>
                <NavLink to="/newsfeed">{t("menu.newsfeed")}</NavLink>
                {/* <li><NavLink to="">Profile</NavLink></li> */}
                <NavLink to="/discovery">{t("menu.discovery")}</NavLink>
              </p>
              <div>
                <NavLink to="/privacy">{t("menu.privacy")}</NavLink>
                <NavLink to="/term">{t("menu.termsOfUse")}</NavLink>
                <NavLink to="/guide">{t("menu.userManual")}</NavLink>
                <NavLink to="/q&a">{t("menu.q&a")}</NavLink>
                <div
                  className="dropdown show"
                  style={{ display: "inline-block" }}
                >
                  <a
                    className="dropdown-toggle"
                    href="#"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {currentLangInfo.name}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {languages.map((ele, idx) => (
                      <a
                        key={idx}
                        className="dropdown-item text-dark-c"
                        onClick={() => onChangeLang(ele.key)}
                      >
                        {ele.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <p className="copyright">
                ©<a href="https://atwom.com.vn">AtwoM Vietnam</a> 2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
