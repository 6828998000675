const overlayErr: any = {
  backgroundColor: "rgba(4,19,36,.95)",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
};

export default function Error500() {
  return (
    <>
      <div style={overlayErr}></div>
      <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100" style={{backgroundImage: 'url("media/bg-error500.png")'}}>
        <div className="auth-page-content overflow-hidden p-0">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-8 text-center">
                <div className="error-500 position-relative">
                  <img
                    src="media/error500.png"
                    alt=""
                    className="img-fluid error-500-img error-img"
                  />
                  {/* <h1 className="title text-muted">500</h1> */}
                </div>
                <div>
                  <h4 className="text-muted">Internal Server Error!</h4>
                  <p className="text-muted w-75 mx-auto">
                    Server Error 500. We're not exactly sure what happened, but
                    our servers say something is wrong.
                  </p>
                  <a href="/" className="btn btn-success">
                    <i className="mdi mdi-home me-1"></i>Back to home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
