export const bubbles = [
  {
    id: 1,
    title: "Xu hướng",
    titleEn: "Trending",
    titleKr: "추세",
    type: 1,
    ico: "icofont-chart-histogram"
  },
  {
    id: 2,
    title: "Theo dõi",
    titleEn: "Following",
    titleKr: "수행원",
    type: 1,
    ico: "icofont-search-user"
  },
  {
    id: 3,
    title: "Xem sau",
    titleEn: "Read later",
    titleKr: "나중에 보다",
    type: 1,
    ico:"icofont-clock-time"
  },

];
