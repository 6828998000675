import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";

export class CategoryService {

    private static _categoryService: CategoryService

    public static getInstance(): CategoryService {
        if (!CategoryService._categoryService) {
            CategoryService._categoryService = new CategoryService()
        }
        return CategoryService._categoryService;
    }

    public getAll() {
        const url = process.env.REACT_APP_API_URL + "/category/get-all.exclude";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    public getById(cateId: any) {
        const url = process.env.REACT_APP_API_URL + "/category/get-by-id.exclude?cateId=" + cateId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    public getEvalStandard(cateId: any) {
        const url = process.env.REACT_APP_API_URL + "/category/get-eval-standard.exclude?cateId=" + cateId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    
}

