import React, { useEffect, useRef, useState } from "react";
import { ReviewerService } from "../../../../services/reviewer/ReviewerService";
import HomeTab from "../HomeTab";
import MediaTab from "../MediaTab";
import FollowerTab from "../FollowerTab";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { PostWishlistService } from "../../../../services/post/PostWishlistService";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { toast } from "react-toastify";
import { CommonConstant } from "../../../../constants/CommonConstant";
import { PostReadLaterService } from "../../../../services/post/PostReadLaterService";
import MyGroup from "../MyGroup";
import { showAndHideSpinner } from "../../../../redux/reducers/spinnerSlice";
import ObjectLikeTab from "../ObjectLikeTab";
import AppModal from "../../../commons/ui/Modal/AppModal";
import AppAvatarEditor from "../../../commons/ui/AppAvatarEditor";
import AvatarEditor from "react-avatar-editor";
import { FileService } from "../../../../services/common/FileService";
import { getUserInfo, updateUserInfo } from "../../../../redux/reducers/userSlice";
import { userInfo } from "os";
import { useTranslation } from "react-i18next";
import { PaginationConstant } from "../../../../constants/PaginationConstant";
import "./Profile.css"
import WriteReview from "../../WriteReview/WriteReview";

export default function Profile(props: any) {
  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });
  const myInfo = useAppSelector((state) => state.userInfo.userInfo);
  const params = useParams<{ uid: string }>();
  const [reviewerUid, setReviewerUid] = useState<any>(params.uid);
  const [reviewer, setReviewer] = useState<any>();
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [postNum, setPostNum] = useState<any>(0);
  const [lstReadLater, setLstReadLater] = useState<[]>();
  const [tabSelected, setTabSelected] = useState<any>(0)
  const location = useLocation()
  const dispatch = useAppDispatch();
  const [isOpenUploadAvatar, setIsOpenUploadAvatar] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [fileCover, setFileCover] = useState<File>();
  const editor = React.createRef<AvatarEditor>()
  const [position, setposition] = useState<any>({ x: 0, y: 0 });
  const fileCoverInputRef = useRef<any>();
  const { t, i18n } = useTranslation();
  const [isOpenWriteReview, setIsOpenWriteReview] = useState(false);



  useEffect(() => {
    setReviewerUid(params.uid);
  }, [location])

  useEffect(() => {
    getReviewerInfo()
    setTabSelected(0);
  }, [reviewerUid])


  const getReviewerInfo = () => {
    dispatch(showAndHideSpinner(true))
    ReviewerService.getInstance().getReviewerInfo({ userUid: reviewerUid }).then((result: any) => {
      if (result.data.status) {
        setReviewer(result.data.responseData.userResponse)
        setIsFollowing(result.data.responseData.isFollowing);
        setPostNum(result.data.responseData.postNum)
      }
      dispatch(showAndHideSpinner(false))
    }).catch((error: any) => {
      console.log(error);
      dispatch(showAndHideSpinner(false))
    })
    getLstReadLater();
  }
  const getLstReadLater = () => {
    PostReadLaterService.getInstance().getReadLater(searchRequest).then(resp => {
      if (resp.data.status) {
        setLstReadLater(resp.data.responseData)
      }
    })
      .catch(err => {
      }).catch((error: any) => {
        console.log(error);
      })
  }

  const handleFollow = (actionType: boolean) => {
    const param = {
      reviewer: reviewer.userInfo.userUid,
      actionType: actionType
    }
    ReviewerService.getInstance().handleFollow(param).then((result: any) => {
      if (result.data.status) {
        getReviewerInfo()
      }
    }).catch((error: any) => {
      console.log(error);
    })
  }

  const onChangeUser = (uid: any) => {
    setReviewerUid(uid)
  }

  const seeMoreImg = (data: any) => {
    setTabSelected(2)
  }
  const onCloseOpenUploadAvatarPopup = () => {
    setIsOpenUploadAvatar(false);
  }
  const onUploadAvatar = () => {
    setIsOpenUploadAvatar(true);
  }

  const saveAvatar = (file: any) => {

    let data: any = Object.assign({}, reviewer.userInfo);
    let avatarImage = Object.assign({}, reviewer.userInfo.avatarImage);
    if (file.atchFleSeq) {
      avatarImage['atchFleSeq'] = file.atchFleSeq;
    }
    avatarImage['positionX'] = file.positionX;
    avatarImage['positionY'] = file.positionY;
    data.avatarImage = { ...avatarImage }
    dispatch(updateUserInfo({ ...myInfo, userInfo: data }))
    ReviewerService.getInstance().saveImage(data)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {

            toast.success(t("profile.message.success"));
            getReviewerInfo();
            dispatch(getUserInfo())
            setReviewer({ ...reviewer, userInfo: { ...reviewer.userInfo, avatarImage: {...avatarImage} } });
            setIsOpenUploadAvatar(false)
          } else {
            toast.warning(t("profile.message.unsuccess"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  const handlePositionChange = (position: any) => {
    setposition(position)
  };
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    setFileCover(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const saveImageCover = async () => {
    let data = Object.assign({}, reviewer.userInfo);
    let coverImage = Object.assign({}, reviewer.userInfo.coverImage);
    if (fileCover && editor) {
      await FileService.getInstance()
        .uploadFile(fileCover)
        .then((res) => {
          if (res.data.status) {
            coverImage['atchFleSeq'] = res.data.responseData.atchFleSeq;
            const rect = editor.current?.getCroppingRect();
            if (rect) {
              coverImage['positionX'] = rect.x / (1.001 - rect.width);
              coverImage['positionY'] = rect.y / (1.001 - rect.height);
            } else {
              coverImage['positionX'] = 0;
              coverImage['positionY'] = 0;
            }
          }
          data.coverImage = coverImage;
        }).catch((error) => {
          console.log(error);

        });
    }
    dispatch(showAndHideSpinner(true));
    ReviewerService.getInstance().saveImage(data)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            toast.success(t("profile.message.success"));
            getReviewerInfo();
            setPreviewUrl(null);
          } else {
            toast.warning(t("profile.message.unsuccess"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const refreshData = () => {
    getReviewerInfo()
  }

  const onCloseWriteReview = () => {
    setIsOpenWriteReview(false);
  }

  return (
    <>
      <div className="edit-avatar-modal">
        <AppModal open={isOpenUploadAvatar} title={t("group.text.change-avt")} onClose={onCloseOpenUploadAvatarPopup}>
          <AppAvatarEditor
            onClose={saveAvatar}
            border={50}
            height={250}
            width={250}
            image={reviewer?.userInfo?.avatarImage?.atchFleSeq}
            borderRadius={100}
            rotate={0} />
        </AppModal>
      </div>

      <AppModal open={isOpenWriteReview} onClose={onCloseWriteReview}>
        <div className="write-review-modal">
          <div
            className="modal-body"
            style={{ maxHeight: "700px", overflowY: "scroll" }}
          >
            <WriteReview userId={reviewerUid} refresh={refreshData} />
          </div>
        </div>
      </AppModal>

      <div className="page-content object-page-content">
        <div className="container">
          <div className="banner-user" style={{
            backgroundImage: `url(${previewUrl != null ? previewUrl : process.env.REACT_APP_API_URL +
              "/public/image-view/" +
              reviewer?.userInfo?.coverImage?.atchFleSeq})`, backgroundRepeat: 'no-repeat', backgroundPosition: `${reviewer?.userInfo?.coverImage?.positionX * 100}% ${reviewer?.userInfo?.coverImage?.positionY * 100}%`
          }}>
            {previewUrl != null && <>
              <div className="preview-image-cover">
                <AvatarEditor
                  ref={editor}
                  image={previewUrl}
                  height={342}
                  width={1170}
                  border={0}
                  position={position}
                  scale={1}
                  rotate={0}
                  onPositionChange={handlePositionChange}
                />
                <div className="button-image-cover-arrow" role="button">
                  <i className="fa fa-arrows"></i>{t("group.text.drag")}
                </div></div></>}
            <div className="image-cover">
              {previewUrl == null && <>
                <input type="file" ref={fileCoverInputRef} style={{ display: 'none' }} onChange={handleImageUpload} />
                {myInfo.userUid === reviewer?.userInfo?.userUid && <div className="button-image-cover" role="button" onClick={() => { fileCoverInputRef.current.click() }}>
                  <i className="fa fa-camera"></i>{t("profile.upload-cover")}
                </div>}
              </>}
              {previewUrl != null && <>
                <div className="button-image-cover" style={{ marginRight: "5px" }} role="button" onClick={() => setPreviewUrl(null)}>
                {t("common.button.cancel")}
                </div>
                <div className="button-image-cover" role="button" onClick={() => saveImageCover()} >
                {t("common.button.save")}
                </div>
              </>}
            </div>
            <div className="banner-content">
              <div className="media" style={{ alignItems: 'baseline' }}>
                <div className="item-img" style={{
                  width: "115px", height: "115px",
                }} >
                  <img onClick={() => {
                    myInfo.userUid == reviewerUid && onUploadAvatar()
                  }} style={{
                    objectPosition: reviewer?.userInfo?.avatarImage && `${reviewer?.userInfo?.info?.avatarImage.positionX * 100}% ${reviewer?.userInfo?.avatarImage.positionY * 100}%`,
                    objectFit: 'cover',
                    width: "100%",
                    height: "100%",
                  }} src={process.env.REACT_APP_API_URL +
                    "/public/image-view/" +
                    reviewer?.userInfo?.avatarImage?.atchFleSeq}
                    onError={(event: any) => {
                      event.target.src = require("../../../../assets/default-avatar.jpg")
                    }} />
                </div>
                {/* <div className="item-img">
                  <img style={{ width: "115px", height: "115px" }} src={userInfo?.imgPathBase64 || ""} onError={(event: any) => {
                    event.target.src = "media/default-avatar.jpg"
                  }} alt="User" />
                </div> */}
                <div className="media-body">
                  <h3 className={"item-title " + (reviewer?.userInfo?.legal === true? "legal": "")}>{myInfo.userUid === reviewerUid ? myInfo?.fullName : reviewer?.userInfo?.fullName}</h3>
                  <div className="item-subtitle">{myInfo.userUid === reviewerUid ? myInfo.email : reviewer?.userInfo?.email}</div>
                  <ul className="user-meta">
                    <li>{t("profile.posts")}: <span>{postNum}</span></li>
                    <li>{t("profile.follower")}: <span>{reviewer?.lstFollower && reviewer?.lstFollower.length}</span></li>
                    <li>{t("profile.following")}: <span>{reviewer?.lstFollowingUser && reviewer?.lstFollowingObject && (reviewer?.lstFollowingUser?.length + reviewer?.lstFollowingObject?.length)}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ padding: '0 15px' }}>
            {
              myInfo.userUid !== reviewerUid &&
              <div className="user-top-header-btn col-12 d-block d-lg-none" style={{ marginBottom: '30px' }}>
                <div className="post-input-tab d-flex align-items-center justify-content-center h-100">
                  <div className="post-footer p-0">
                    <div className="submit-btn">
                      {isFollowing == false ? <a onClick={() => handleFollow(true)}>{t("profile.button.follow")}</a> : <a onClick={() => handleFollow(false)}>{t("profile.button.following")}</a>}
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="user-top-header col-lg-9 p-0">
              <ul className="menu-list">
                <li className={tabSelected === 0 ? 'active' : ''} onClick={() => { setTabSelected(0) }}><a>{t("profile.nav.home")}</a></li>
                <li className={tabSelected === 1 ? 'active' : ''} onClick={() => { setTabSelected(1) }}><a>{t("profile.nav.video")}</a></li>
                <li className={tabSelected === 2 ? 'active' : ''} onClick={() => { setTabSelected(2) }}><a>{t("profile.nav.img")}</a></li>
                <li className={tabSelected === 3 ? 'active' : ''} onClick={() => { setTabSelected(3) }}><a>{t("profile.follower")}</a></li>
                <li className={tabSelected === 4 ? 'active' : ''} onClick={() => { setTabSelected(4) }}><a>{t("profile.following")}</a></li>
                <li className={tabSelected === 5 ? 'active' : ''} onClick={() => { setTabSelected(5) }}><a>{t("profile.nav.interested")}</a></li>
                {myInfo?.userUid === reviewerUid && <li className={tabSelected === 6 ? 'active' : ''} onClick={() => { setTabSelected(6) }}><a>{t("profile.nav.group")}</a></li>}
              </ul>
            </div>

            <div className="user-top-header-btn col-lg-3 d-none d-lg-block" style={{ marginBottom: '30px' }}>
                <div className="post-input-tab d-flex align-items-center justify-content-end h-100">
                  <div className="post-footer p-0">
                    <div className="submit-btn">
                    {
              myInfo.userUid !== reviewerUid ?
                <>
                    {isFollowing === false ?
                        <a onClick={() => handleFollow(true)}>{t("profile.button.follow")}</a>
                      :
                        <a className="did" onClick={() => handleFollow(false)}>{t("profile.button.following")}</a>
                    }                
                </>
                :
                <>
                  <a onClick={() => setIsOpenWriteReview(true)}>{t("profile.button.writeReview")}</a>
                </>

            }
                    </div>
                  </div>
                </div>
            </div>

          </div>
          {tabSelected === 0 && <HomeTab userInfo={reviewer?.userInfo} lstWishlist={lstReadLater} onSeeMoreImg={seeMoreImg} getListReadLater={getLstReadLater} />}
          {tabSelected === 1 && <MediaTab type={CommonConstant.MEDIA_TYPE_VIDEO} userInfo={{ ...reviewer?.userInfo }}></MediaTab>}
          {tabSelected === 2 && <MediaTab type={CommonConstant.MEDIA_TYPE_IMAGE} userInfo={{ ...reviewer?.userInfo }}></MediaTab>}
          {tabSelected === 3 && <FollowerTab type={CommonConstant.FOLLOW_TYPE_FOLLOWER} lstUser={{ ...reviewer }.lstFollower} onChangeUser={onChangeUser}></FollowerTab>}
          {tabSelected === 4 && <FollowerTab type={CommonConstant.FOLLOW_TYPE_FOLLOWING} lstUser={{ ...reviewer }.lstFollowingUser} lstObject={[...reviewer?.lstFollowingObject]} onChangeUser={onChangeUser}></FollowerTab>}
          {tabSelected === 5 && <ObjectLikeTab userUid={reviewer?.userInfo.userUid}></ObjectLikeTab>}
          {myInfo?.userUid === reviewerUid && tabSelected === 6 && <MyGroup></MyGroup>}
        </div >
      </div >
    </>
  );
}
