import Cookies from "universal-cookie";
import { AuthConstant } from "../constants/authConstant";
import jwtDecode from "jwt-decode";
import { AuthService } from "../services/auth/AuthService";
import { toast } from "react-toastify";
import { t } from "i18next";

export class AuthUtil {
  
  public static getToken() {
    const cookies = new Cookies();
    return cookies.get(AuthConstant.ACCESS_TOKEN);
  }

  public static saveToken(token: any) {
    const cookies = new Cookies();
    cookies.remove(AuthConstant.ACCESS_TOKEN);
    cookies.set(AuthConstant.ACCESS_TOKEN, token);
  }

  public static login() {
    let domain = window.location.origin;
    let url = process.env.REACT_APP_AUTH_URL + "/login?redirect_uri=" + domain + "&type_login=POPUP";
    window.open(url, "popup", "width=600,height=600");
  }

  public static logout() {
    const cookies = new Cookies();
    cookies.remove(AuthConstant.ACCESS_TOKEN);
    // let domain = window.location.origin;
    // window.location.href = process.env.REACT_APP_AUTH_URL + "/login?pre_action=logout&redirect_uri=" + domain || "";
    AuthService.getInstance().logout().then(res => {
      if(res.data.status) {
        toast.error(t("toast.success"))
        window.location.href = "/";
      }
    });   
  }

  public static getCurrentUserUid() {
    let token = this.getToken();
    let decodedToken: any = {};
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      console.log(error);
    }
    return decodedToken.sub;
  }

  public static isLogin() {
    let token = this.getToken();
    if (token) {
      return true;
    }

    return false;
  }
}
