import { useEffect, useState } from "react";
import Select from "react-select";

type DropdownProps = {
  sources: any[];
  bindLabel: string;
  bindValue: any;
  value?: any;
  onChange: (data?: any) => void;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: any;
  isMulti?: boolean;
  isClearable?: boolean;
};

export default function Dropdown(props: DropdownProps) {
  const [sources, setSources] = useState<any[]>([]);
  const [defaultValue, setDefaultValue] = useState<any>({});
  const [isMulti, setMulti] = useState(false);
  const [value, setValue] = useState<any>({});

  useEffect(() => {
    if (props.defaultValue) {
      let temp: any = {
        label: props.defaultValue[props.bindLabel],
        value: props.defaultValue[props.bindValue],
      };
      setDefaultValue(temp);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.value) {
      let temp: any = {
        label: props.value[props.bindLabel],
        value: props.value[props.bindValue],
      };
      setValue(temp);
    }
  }, [props.value]);


  useEffect(() => {
    if (props.isMulti) {
      setMulti(props.isMulti);
    }
  }, [props.isMulti]);

  useEffect(() => {
    let temps: any[] = [];
    props.sources.forEach((ele) => {
      let temp: any = {
        label: ele[props.bindLabel],
        value: ele[props.bindValue],
      };
      temps.push(temp);
    });
    setSources(temps);
  }, [props.sources]);

  const onChange = (data: any) => {
    if (data) {
      let selected = props.sources.find(
        (ele) => ele[props.bindValue] === data.value
      );
      props.onChange(selected);
    } else {
      props.onChange(null);
    }
  };

  return (
    <>
      <Select
        // components={{
        //   NoOptionsMessage: customNoOptionsMessage,
        // }}
        options={sources}
        className="select"
        classNamePrefix={props.placeholder ? props.placeholder : "Select"}
        defaultValue={defaultValue}
        value={value}
        isDisabled={props.disabled}
        onChange={onChange}
        isMulti={isMulti}
        isClearable = {props.isClearable ? props.isClearable: false}
        isSearchable={true}
      />
    </>
  );
}
