import React, { useEffect, useState } from "react";
import DateFormat from "../../../commons/ui/DateFormat/DateFormat";
import AppModal from "../../../commons/ui/Modal/AppModal";
import WriteReview from "../../WriteReview/WriteReview";
import { GroupService } from "../../../../services/group/GroupService";
import { PaginationConstant } from "../../../../constants/PaginationConstant";
import PostView from "../../Newsfeed/components/PostView";
import EditGroupInfo from "../GroupInfo/form/EditGroupInfo";
import "./GroupInfoHome.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function GroupInfoHome(props: any) {
  const { t, i18n } = useTranslation();

  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });

  const navigate = useNavigate();

  const [searchGroupSggt, setSearchGroupSggt] = useState<any>();
  const [listGroupSggt, setListGroupSggt] = useState<any>([]);
  const [groupInfo, setGroupInfo] = useState<any>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [posts, setPosts] = useState<any[]>([]);
  const [totalPost, setTotalPost] = useState(0);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [groupClassification, setGroupClassification] = useState<any>();

  useEffect(() => {
    if (props.groupClassification) {
      let temp: any = [];
      props.groupClassification.forEach((ele: any) => {
        let tmp: any = {};
        tmp.label = ele.classificationName;
        tmp.value = ele.id;
        temp.push(tmp);
      });
      setGroupClassification(temp);
    }
  }, [props.groupClassification]);

  function _getPosts() {
    GroupService.getInstance()
      .getPostInGroup(searchRequest)
      .then((result: any) => {
        let lstTemp = posts.concat(result.data.responseData.lstPost);
        setPosts(lstTemp);
        setTotalPost(result.data.responseData.totalElement);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function _refresh() {
    GroupService.getInstance()
      .getPostInGroup(searchRequest)
      .then((result: any) => {
        setPosts(result.data.responseData.lstPost);
        setTotalPost(result.data.responseData.totalElement);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function _getGroupSggt() {
    GroupService.getInstance()
      .getGroupSggtByClassification(searchGroupSggt)
      .then((res: any) => {
        res.data.responseData = res.data.responseData.filter((ele: any) => ele.id !== props.group.id);
        setListGroupSggt(res.data.responseData);
      })
      .catch((err: any) => {});
  }

  useEffect(() => {
    if (searchRequest.groupId) {
      if (isOpenEdit) {
        _refresh();
        setIsOpenEdit(false);
      } else {
        _getPosts();
      }
    }
  }, [searchRequest]);

  useEffect(() => {
    if (searchGroupSggt) {
      _getGroupSggt();
    }
  }, [searchGroupSggt]);

  useEffect(() => {
    if (props.group) {
      setSearchRequest({
        ...searchRequest,
        groupId: props.group?.id,
      });
      setGroupInfo(props.group);

      let classificationIds = "";
      props.groupClassification.forEach((ele: any) => {
        classificationIds = classificationIds + ele.id + ",";
      });
      setSearchGroupSggt({ ...setSearchGroupSggt, classificationIds: classificationIds.substring(0, classificationIds.length - 1) });
    }
  }, [props.group]);

  const onCloseModal = (data: any) => {
    setIsOpenModal(false);
  };

  const refreshData = () => {
    _refresh();
    setIsOpenModal(false);
  };

  const loadMorePosts = () => {
    setSearchRequest({
      ...searchRequest,
      page: searchRequest.page + 1,
    });
  };

  const handleOpenEditGroupInfo = () => {
    setIsOpenEdit(true);
  };

  const onCloseEdit = (data: any) => {
    setIsOpenEdit(false);
    if (data) {
      setGroupInfo({ ...groupInfo, ...data });
      props.onUpdate(data);
    }
    // setIsOpenEdit(false)
  };

  const redirectGroupDetail = (data: any) => {
    navigate("/groups/" + data);
  };

  return (
    <>
      <AppModal open={isOpenModal} onClose={onCloseModal}>
        <div className="write-review-modal">
          <div className="modal-body" style={{ maxHeight: "700px", overflowY: "scroll" }}>
            <WriteReview groupId={groupInfo?.id} refresh={refreshData} />
          </div>
        </div>
      </AppModal>

      <AppModal open={isOpenEdit} onClose={onCloseEdit}>
        <EditGroupInfo groupData={groupInfo} groupClassification={groupClassification} onClose={onCloseEdit} title={t("group.button.edit-group-info")}></EditGroupInfo>
      </AppModal>

      <div className="row">
        <div className="col-lg-8">
          {props.isMember ? (
            <>
              {props.isMember === true && (
                <div className="mb-3">
                  <button className="btn btn-info p-3 w-100 create-post-btn" onClick={() => setIsOpenModal(true)}>
                    {t("group.button.writepost")}
                  </button>
                </div>
              )}

              <div className="posts-group">
                {posts && posts.length > 0 ? (
                  <>
                    {posts && posts.map((post: any, index: any) => <PostView key={post.id + index} post={post} refresh={_refresh}></PostView>)}
                    {posts.length < totalPost && (
                      <div className="block-box load-more-btn">
                        <a className="item-btn" onClick={loadMorePosts}>
                          <i className="icofont-refresh"></i>
                          {t("common.button.loadmore")}
                        </a>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center">
                    <h5>{t("group.text.no-post")}</h5>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="widget-block widget-break-lg">
                <div className="widget widget-user-about">
                  <div className="widget-heading">
                    <h3 className="widget-title text-dark">{t("group.text.group-inf")}</h3>
                    <div className="dropdown">
                      <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        ...
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        {/* <a className="dropdown-item" href="#">
                    Close
                  </a> */}
                        <a className="dropdown-item" onClick={() => handleOpenEditGroupInfo()}>
                          {t("group.text.title-edit")}
                        </a>
                        {/* <a className="dropdown-item" href="#">
                    Delete
                  </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="user-info">
                    <h5 className="text-dark">{groupInfo?.groupName}</h5>
                    <p>{groupInfo?.description}</p>
                    <ul className="info-list">
                      {groupInfo?.createdDate && (
                        <li>
                          <span>
                            {t("group.label.created-date")}: <DateFormat date={groupInfo?.createdDate}></DateFormat>
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="col-lg-4 widget-block widget-break-lg">
          {props.isMember ? (
            <>
              <div className="widget widget-user-about">
                <div className="widget-heading">
                  <h3 className="widget-title text-dark">{t("group.text.group-inf")}</h3>
                  <div className="dropdown">
                    <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      ...
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {/* <a className="dropdown-item" href="#">
                    Close
                  </a> */}
                      <a className="dropdown-item" onClick={() => setIsOpenEdit(true)}>
                        {t("group.text.title-edit")}
                      </a>
                      {/* <a className="dropdown-item" href="#">
                    Delete
                  </a> */}
                    </div>
                  </div>
                </div>
                <div className="user-info">
                  <h5 className="text-dark">{groupInfo?.groupName}</h5>
                  <p>{groupInfo?.description}</p>
                  <ul className="info-list">
                    {groupInfo?.createdDate && (
                      <li>
                        <span>
                          {t("group.label.created-date")}: <DateFormat date={groupInfo?.createdDate}></DateFormat>
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="widget widget-groups">
                <div className="widget-heading">
                  <h3 className="widget-title text-dark">{t("group.text.similarGroup")}</h3>
                </div>
                <div className="tab-content">
                  {listGroupSggt.map((group: any) => (
                    <div className="group-list d-flex align-items-center mb-3" key={group.id}>
                      <div className="item-img mx-2">
                        <a onClick={() => redirectGroupDetail(group.id)}>
                          <img
                            src={process.env.REACT_APP_API_URL + "/public/image-view/" + group.groupInf?.avatarImage?.atchFleSeq}
                            alt="author"
                            onError={(event: any) => {
                              event.target.src = require("../../../../assets/default-group.png");
                            }}
                            style={{
                              objectPosition: group.groupInf?.avatarImage && `${group.groupInf?.avatarImage?.positionX * 100}% ${group.groupInf?.avatarImage?.positionY * 100}%`,
                              objectFit: "cover",
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        </a>
                      </div>
                      <div className="media-body">
                        <span className="item-title text-dark">
                          <b>{group.groupName}</b>
                        </span>
                        {/* <div className="item-post">200 bài đăng</div> */}
                        <div className="item-member">
                          {group.members.length} {t("group.label.members")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
