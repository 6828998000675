import React from 'react'
import "./ImageModal.css"
type ImageModalProps = {
    onClose?: (data?: any) => void;
    src: any;
    alt?: any;
    caption?: any;
};

export default function ImageModal(props: ImageModalProps) {
    const { src, alt, onClose, caption } = props;
    return (
        <div className="image-modal">
            <span className="btn-close" onClick={onClose}>
                &times;
            </span>
            <img className="modal-content" src={process.env.REACT_APP_API_URL +
                "/public/image-view/" + src} onError={(event: any) => {
                    event.target.src = require("../../../../assets/placeholder.jpg")
                }} alt={alt} />
            {caption.length > 0 && <div className="caption">{caption}</div>}
        </div>
    )
}
