import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { NotificationService } from "../../../services/notification/NotificationService";
import AppTimeAgo from "../../commons/ui/DateFormat/AppTimeAgo";
import { useTranslation } from "react-i18next";

export default function Notification() {

  const { t, i18n } = useTranslation();

  const [notifications, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    getNotifications();
  }, []);

  const handleReadAll = () => {};

  const getNotifications = () => {
    NotificationService.getInstance()
      .getNotification({})
      .then(
        (res) => {
          if (res.data.status) {
            setNotifications(res.data.responseData?.notifications);
          }
        },
        (error) => {}
      );
  };

  // const navigateProfileUser = () => {

  // }

  return (
    <>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 widget-block widget-break-lg">
              <div className="widget widget-groups">
                <div className="widget-heading">
                  <h3 className="widget-title">{t("notification.title")}</h3>
                </div>
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#active-group"
                      role="tab"
                      aria-selected="true"
                    >
                      {t("notification.all")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#popular-group"
                      role="tab"
                      aria-selected="false"
                    >
                      {t("notification.unread")}
                    </a>
                  </li>
                </ul>
                <div className="group-list">
                  {notifications.map((ele: any) => (
                    <div className="media" key={ele.notiId}>
                      <div className="item-img">
                        <NavLink to={"/profile/" + ele.senderInfo?.userUid}>
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              "/public/avatar-view/" +
                              ele.senderInfo?.imgPath
                            }
                            width={50}
                          />
                        </NavLink>
                      </div>
                      <div className="media-body">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 className="item-title">
                            <NavLink to={"/profile/" + ele.senderInfo?.userUid}>
                              {ele.senderInfo?.fullName}
                            </NavLink>
                          </h4>
                          <div className="item-member">
                            <AppTimeAgo date={ele.createdDate}></AppTimeAgo>
                          </div>
                        </div>
                        <div
                          className="item-member"
                          style={{ fontSize: "16px" }}
                        >
                          {ele.content}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="item-body scrollbar">
            {notifications.map((ele: any) => (
              <div key={ele.notiId} className="media" style={{backgroundColor: ele.readYn === 'N' ? '#f6f9fd': '#ffffff'}}>
                <div className="item-img">
                  <img src="media/figure/notifiy_1.png" alt="Notify" />
                </div>
                <div className="media-body">
                  <h6 className="item-title">Diana Jameson</h6>
                  <div className="item-time"><AppTimeAgo date={new Date(ele.createdDate)}></AppTimeAgo></div>
                  <p>{ele.content}</p>
                </div>
              </div>
            ))}

          </div> */}
    </>
  );
}
