import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./HoverCard.css"
import { ObjectService } from "../../../../services/object/ObjectService";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { ReviewerService } from "../../../../services/reviewer/ReviewerService";
import { CommonConstant } from "../../../../constants/CommonConstant";
import { useCompareHandler } from "../../../layout/SidebarRight/SidebarRight";
import { refreshCompare } from "../../../../redux/reducers/compareSlice";
import { GroupService } from "../../../../services/group/GroupService";
import { ObjectFavoritesService } from "../../../../services/object/ObjectFavoritesService";
import { ObjectFollow } from "../../../../models/objectFollow";
import { t } from "i18next";
import { Object } from "../../../../models/object";
import { ObjectFollowService } from "../../../../services/object/ObjectFollowService";
import { TranslationConstant } from "../../../../constants/TranslationConstant";
type HoverCardProps = {
    post: any;
    type: number;
};
export default function HoverCard(props: HoverCardProps) {
    const lang = useAppSelector((state) => state.language.lang);
    const [isHovering, setIsHovering] = useState(false);
    const [post, setPost] = useState(props.post);
    const [object, setObject] = useState<any>();
    const [isFollowing, setIsFollowing] = useState<any>();
    const [isLike, setIsLike] = useState<any>();
    const [userInfo, setUserInfo] = useState<any>();
    const dispatch = useAppDispatch()
    const { handleAddCompareClick } = useCompareHandler();
    const [group, setGroup] = useState<any>()
    const [isMember, setIsMember] = useState<any>(false)
    const myInfo = useAppSelector((state) => state.userInfo.userInfo);
    useEffect(() => {
        setPost(props.post)
    }, [props.post])

    useEffect(() => {
        if (isHovering && props.type === CommonConstant.HOVER_CARD_OBJECT && post.objectId) {
            ObjectService.getInstance().getObjectById(post.objectId).then(resp => {
                if (resp.data.status) {
                    setObject(resp.data.responseData.object);
                    setIsFollowing(resp.data.responseData.isFollowing)
                    setIsLike(resp.data.responseData.isLike);
                }
            })
                .catch(err => {

                })
        }
        if (isHovering && props.type === CommonConstant.HOVER_CARD_USER && post.creator) {
            ReviewerService.getInstance().getReviewerInfo({ userUid: post.creator.userUid }).then((result: any) => {
                if (result.data.status) {
                    setUserInfo(result.data.responseData.userResponse)
                    setIsFollowing(result.data.responseData.isFollowing);
                }

            }).catch((error: any) => {
            })
        }
        if (isHovering && props.type === CommonConstant.HOVER_CARD_GROUP && post.groupId) {
            GroupService.getInstance().getGroupById({ groupId: post.groupId }).then((result: any) => {
                if (result.data.status) {
                    setGroup(result.data.responseData.group)
                    setIsMember(result.data.responseData.isMember)
                }

            }).catch((error: any) => {
            })
        }
    }, [isHovering]);

    const handleFollow = (actionType: boolean) => {
        const param = {
            reviewer: userInfo.userInfo.userUid,
            actionType: actionType
        }
        ReviewerService.getInstance().handleFollow(param).then((result: any) => {
            if (result.data.status) {
                ReviewerService.getInstance().getReviewerInfo({ userUid: post.creator.userUid }).then((result: any) => {
                    if (result.data.status) {
                        setUserInfo(result.data.responseData.userResponse)
                        setIsFollowing(result.data.responseData.isFollowing);
                    }

                }).catch((error: any) => {

                })
            }
        }).catch((error: any) => {

        })
    }
    function getObject() {
        ObjectService.getInstance().getObjectById(post.objectId).then(resp => {
            if (resp.data.status) {
                setObject(resp.data.responseData.object);
                setIsFollowing(resp.data.responseData.isFollowing)
                setIsLike(resp.data.responseData.isLike);
            }
        })
            .catch(err => {

            })
    }
    function handleLike(object: Object | undefined) {

        if (object) {
            ObjectFavoritesService.getInstance().addFavorites(new ObjectFollow(object.id)).then(resp => {
                if (resp.data.status) {
                    handleAddFollow(object)
                }
            })
                .catch(err => {
                    // console.log(err);
                })
        }
    }

    function handleDislike(object: Object | undefined) {
        if (object) {
            ObjectFavoritesService.getInstance().deleteFavorites(object.id).then(resp => {
                if (resp.data.status) {
                    handleDeleteFollow(object)
                }
            })
                .catch(err => {
                    // console.log(err);
                })
        }
    }
    function handleAddFollow(object: Object | undefined) {
        if (object) {
            ObjectFollowService.getInstance().addFollow(new ObjectFollow(object.id)).then(resp => {
                if (resp.data.status) {
                    getObject()
                }
            })
                .catch(err => {
                    // console.log(err);
                })
        }
    }

    function handleDeleteFollow(object: Object | undefined) {
        if (object) {
            ObjectFollowService.getInstance().deleteFollow(object.id).then(resp => {
                if (resp.data.status) {
                    getObject()
                }
            })
                .catch(err => {
                    // console.log(err);
                })
        }
    }
    return (
        <>
            {props.type && props.type === CommonConstant.HOVER_CARD_OBJECT && (
                <>
                    <span onMouseEnter={() => setIsHovering(true)} className="onhover-div"><NavLink to={"/object/" + props.post.object?.id}>
                        {props.post.object?.objectName}
                    </NavLink>
                        {
                            object && <ul className="show-div">
                                <div className="show-div-object preview-banner">
                                    <div className="banner-user" style={{
                                        backgroundImage: `url(${process.env.REACT_APP_API_URL +
                                            "/public/image-view/" +
                                            object?.coverImage?.atchFleSeq})`, backgroundRepeat: 'no-repeat', backgroundPosition: `${object?.coverImage?.positionX * 100}% ${object?.coverImage?.positionY * 100}%`
                                    }}>
                                        <div className="banner-content">
                                            <div className="media">
                                                <div className="item-img">
                                                    <img style={{ objectFit: "cover" }} src={process.env.REACT_APP_API_URL +
                                                        "/public/image-view/" +
                                                        object?.avatarImage?.atchFleSeq}
                                                        onError={(event: any) => {
                                                            event.target.src = require("../../../../assets/default-avatar.jpg")
                                                        }} />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className={"item-title " + (object?.legal ? "check" : "")}> {object?.objectName}</h3>
                                                    <div className="item-subtitle">
                                                        <button className="button-category" role="button"><i className="fa fa-tag"></i>{lang === TranslationConstant.SOUTH_KOREA ? object?.category?.cateNameKr :
                                                            lang === TranslationConstant.ENGLISH ? object?.category?.cateNameEn : object?.category?.cateName}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget widget-user-about mb-3">
                                        <div className="user-info">
                                            <ul className="info-list">
                                                <li><span><i className="fa fa-rss" aria-hidden="true"></i></span>{object?.countFollow} {t("object.label.follow")}</li>
                                                {object?.address ? <li><span><i className="fa fa-map-marker" aria-hidden="true"></i></span>{object?.address}</li> : <></>}
                                                {object?.objectDetail?.phoneNumber ? <li><span><i className="fa fa-phone" aria-hidden="true"></i></span>{object?.objectDetail?.phoneNumber}</li> : <></>}
                                                {object?.objectDetail?.website ? <li><span><i className="fa fa-globe" aria-hidden="true"></i></span><a href={object?.objectDetail?.website}>
                                                    {object?.objectDetail?.website}</a></li> : <></>}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="post-input-tab d-flex align-items-center justify-content-center h-100 ">
                                        <div className="post-footer p-0">
                                            <div className="submit-btn">
                                                <NavLink to={"/object/" + object?.id}><i className="icofont-home"></i></NavLink>
                                            </div>
                                            <div className="submit-btn">
                                                {!isLike &&
                                                    <a onClick={() => {
                                                        handleLike(object);
                                                        dispatch(refreshCompare(new Date().getTime()))
                                                    }} ><i className="icofont-like mr-1"></i>{t("object.menu.like")}</a>
                                                }
                                                {isLike &&
                                                    <>
                                                        <a className="did" onClick={() => {
                                                            handleDislike(object);
                                                            dispatch(refreshCompare(new Date().getTime()))
                                                        }} type="button" ><i className="icofont-like mr-1"></i>{t("object.menu.liked")}</a>
                                                    </>
                                                }
                                            </div>
                                            <div className="submit-btn">
                                                <a onClick={() => {
                                                    handleAddCompareClick(object);
                                                    dispatch(refreshCompare(new Date().getTime()))
                                                }}><i className="icofont-rounded-expand mr-1"></i>{t("object.menu.compare")}</a>
                                            </div>
                                            {/* <div className="btn-more">
                                                <button className="submit-btn-more" type="button" data-toggle="dropdown" aria-expanded="false">...</button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    {!isFollowing &&
                                                        <a className="dropdown-item"
                                                        // onClick={() => {
                                                        //     handleAddFollow(object);
                                                        //     dispatch(refreshCompare(new Date().getTime()))
                                                        // }} 
                                                        ><i className="icofont-toggle-off" aria-hidden="true"></i>Theo dõi</a>
                                                    }
                                                    {isFollowing &&
                                                        <a className="dropdown-item"
                                                        //  onClick={() => {
                                                        //     handleDeleteFollow(object);
                                                        //     dispatch(refreshCompare(new Date().getTime()))
                                                        // }}
                                                        ><i className="icofont-toggle-on" aria-hidden="true"></i>Đã theo dõi</a>
                                                    }
                                                    <a className="dropdown-item"><i className="icofont-rounded-expand" aria-hidden="true"></i>So sánh</a>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        }
                    </span></>
            )
            }
            {props.type && props.type === CommonConstant.HOVER_CARD_USER && (
                <>
                    <span onMouseEnter={() => setIsHovering(true)} className="onhover-div"><NavLink to={"/profile/" + props.post.creator?.userUid}>
                        {props.post.creator?.fullName}
                    </NavLink>
                        {
                            userInfo && myInfo?.userUid !== props?.post?.creator?.userUid && <ul className="show-div">
                                <div className="show-div-user preview-banner">
                                    <div className="banner-user mb-3">
                                        <div className="banner-content">
                                            <div className="media">
                                                <div className="item-img">
                                                    <img src={process.env.REACT_APP_API_URL + "/public/image-view/" + userInfo?.userInfo.avatarImage?.atchFleSeq} onError={(event: any) => {
                                                        event.target.src = require("../../../../assets/default-avatar.jpg")
                                                    }} />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="item-title"> {userInfo?.userInfo.fullName}</h3>
                                                    <div className="widget widget-user-about mb-3">
                                                        <div className="user-info">
                                                            <ul className="info-list">
                                                                {userInfo?.lstFollower.length ? <li><span><i className="fa fa-rss" aria-hidden="true"></i></span>{userInfo?.lstFollower.length} {t("object.info.count.followers")}</li> : <></>}
                                                                {userInfo?.userInfo.cellPhone ? <li><span><i className="fa fa-phone" aria-hidden="true"></i></span>{userInfo?.userInfo.cellPhone}</li> : <></>}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-input-tab d-flex align-items-center justify-content-center h-100 ">
                                        <div className="post-footer p-0">
                                            <div className="submit-btn">
                                                {
                                                    myInfo.userUid !== props.post.creator?.userUid && !isFollowing &&
                                                    <a
                                                        onClick={() => handleFollow(true)}
                                                    ><i className="icofont-folder-open mr-1"></i>{t("object.menu.follow")}</a>
                                                }
                                                {isFollowing &&
                                                    <>
                                                        <a className="did" type="button" onClick={() => handleFollow(false)}><i className="icofont-folder-open mr-1"></i>{t("object.menu.followed")}</a>

                                                    </>
                                                }
                                            </div>
                                            <div className="submit-btn">
                                                <NavLink to={"/profile/" + props.post.creator?.userUid}>
                                                    <i className="fa fa-user" aria-hidden="true"></i></NavLink>
                                            </div>
                                            <div className="btn-more">
                                                <a className="submit-btn-more" type="button" data-toggle="dropdown" aria-expanded="false"><div>...</div></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item"><i className="icofont-ui-block"></i>{t("newsfeed.post.block")}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        }
                    </span></>
            )
            }
            {props.type && props.type === CommonConstant.HOVER_CARD_GROUP && (
                <>
                    <span onMouseEnter={() => setIsHovering(true)} className="onhover-div"><NavLink to={"/groups/" + props.post.groupId}>
                        {props.post.group.groupName}
                    </NavLink>
                        {
                            group && <ul className="show-div">
                                <div className="show-div-user preview-banner">
                                    <div className="banner-user mb-3">
                                        <div className="banner-content">
                                            <div className="media">
                                                <div className="item-img">
                                                    <img src={process.env.REACT_APP_API_URL + "/public/image-view/" + group.avatarImage?.atchFleSeq} onError={(event: any) => {
                                                        event.target.src = require("../../../../assets/default-avatar.jpg")
                                                    }} />
                                                </div>
                                                <div className="media-body">
                                                    <h3 style={{ fontSize: "18px", maxWidth: "300px" }} className="item-title"> {group.groupName}</h3>
                                                    <div className="widget widget-user-about mb-3">
                                                        <div className="user-info">
                                                            <ul className="info-list">
                                                                {group.members ? <li><i className="fa fa-solid fa-users"></i>{group.members.length} {t("newsfeed.hover.groupMember")}</li> : <></>}
                                                                {userInfo?.userInfo.cellPhone ? <li><span>{t("object.label.phone")}:</span>{userInfo?.userInfo.cellPhone}</li> : <></>}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-input-tab d-flex align-items-center justify-content-center h-100 ">
                                        <div className="post-footer p-0">
                                            <div className="submit-btn">
                                                {isMember &&
                                                    <a className="did"
                                                    ><i className="icofont-folder-open"></i>{t("newsfeed.hover.joined")}</a>
                                                }
                                            </div>
                                            <div className="submit-btn">
                                                <NavLink to={"/groups/" + props.post.groupId}>
                                                    <i className="icofont-home"></i>{t("newsfeed.hover.visitGroup")}</NavLink>
                                            </div>
                                            <div className="btn-more">
                                                <button className="submit-btn-more" type="button" data-toggle="dropdown" aria-expanded="false">...</button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item"><i className="icofont-ui-block"></i>{t("newsfeed.hover.cancelGroup")}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        }
                    </span></>
            )
            }
        </>
    );
}
