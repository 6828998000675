import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";

export class StatisticVisitsService {
  private static _statisticVisitsService: StatisticVisitsService;

  public static getInstance(): StatisticVisitsService {
    if (!StatisticVisitsService._statisticVisitsService) {
        StatisticVisitsService._statisticVisitsService = new StatisticVisitsService();
    }
    return StatisticVisitsService._statisticVisitsService;
  }

  public addCount(){
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/public/statistic-visits/addCount.exclude");
      return axios.post(url, {
          headers: HeadersUtil.getHeadersAuth(),
      });
  }

}
