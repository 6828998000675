import React, { useState } from 'react'
import { GroupService } from '../../../../../services/group/GroupService'
import SearchSelectComp from '../../../../commons/form/SearchSelect'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';

export default function InviteMember(props: any) {

    const { t, i18n } = useTranslation();
    const [optionsUser, setOptionsUser] = useState<any>([])
    const [lstSelected, setLstSelected] = useState<any[]>([])
    const onSelectChange = (data: any) => {
        if (lstSelected.includes(data) === false) {
            setLstSelected([...lstSelected, data])
        }
    }
    const formatOptionLabel = (data: any) => (
        <div style={{ display: "flex" }}>
            <div style={{ display: "none" }}>{data.userUid}</div>
            <div style={{ marginLeft: "10px" }}>
                {data.name}
            </div>
        </div>
    );
    const handleDeleteMember = (userId: any) => {
        setLstSelected(lstSelected.filter(item => item.userUid !== userId))
    }

    const loadData = (inputValue: any) => {
        if (inputValue.trim() !== '') {
            GroupService.getInstance().searchUserByName({ keySearch: inputValue.trim(), lstMember: props.members }).then((result) => {
                const options: any = [];
                if (result.data.responseData) {
                    result.data.responseData.forEach((element: any) => {
                        const item = {
                            userUid: element.userUid,
                            name: element.fullName,
                            avt: element.imgPathBase64
                        }
                        options.push(item);
                    });
                }
                setOptionsUser(options);
            }).catch((error: any) => {
                console.log(error);
            })
        } else {
            setOptionsUser([]);
        }

    }
    const onClickClose = () => {
        props.onCloseForm(false)
    };

    const handleSave = () => {
        GroupService.getInstance().inviteMemeber({groupId: props.groupId, lstUser: lstSelected}).then((result:any) => {
            if (result.data.status){
                toast.success(t("group.message.invite-success"))
                props.onCloseForm(false)
            }else{
                toast.error(t("group.message.invite-err"))
            }

        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <div className="modal-content">
            <div
                id="scroll-modal"
                className="modal-body"
                style={{ maxHeight: "700px", overflowY: "scroll" }}
            >
                <h3 className="heading-title px-4">{props.title}</h3>
                <div className="forum-topic-add px-4" id="new-post-form">
                    <div className="form-group">
                        <label>{t("group.label.members")}</label>
                        <SearchSelectComp
                            label=''
                            placeholder="Search..."
                            timeout={1000}
                            options={optionsUser}
                            onInputChange={loadData}
                            formatOptionLabel={formatOptionLabel}
                            onSelectOption={onSelectChange}
                        />
                        <div className="group-list mt-3" style={{ overflowY: "scroll", height: "300px" }}>
                            {lstSelected && lstSelected.map((item) => (
                                <div className="media mb-2 p-1" key={item.userUid} style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 3px", borderRadius: "5px" }}>
                                    <div className="item-img">
                                        <img style={{ width: "50px", height: "50px", borderRadius: "25px" }} src={item.avt || ''} onError={(event: any) => {
                                            event.target.src = "media/default-avatar.jpg"
                                        }} alt="group" />
                                    </div>
                                    <div className="media-body mx-2">
                                        <h6 className="item-title mt-2 ">
                                            <p>{item.name}</p>
                                        </h6>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="close chat-close chat-open"
                                            onClick={() => handleDeleteMember(item.userUid)}
                                        >
                                            <span aria-hidden="true" className='text-danger'>×</span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>                    
                </div>

            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary mr-4" onClick={onClickClose}>{t("common.button.cancel")}</button>
                <button className="btn btn-info" onClick={handleSave}>{t("common.button.save")}</button>
            </div>
        </div>
    )
}
