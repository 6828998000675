import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";

export class ReviewerService {
  private static _reviewerService: ReviewerService;

  public static getInstance(): ReviewerService {
    if (!ReviewerService._reviewerService) {
      ReviewerService._reviewerService = new ReviewerService();
    }
    return ReviewerService._reviewerService;
  }

  public getReviewerInfo(userUid: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(userUid);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/getReviewerInfo.exclude",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public searchPostByUser(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/searchPostByUser.exclude",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public handleFollow(param: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/handleFollow.exclude"
    );
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getLstUserInfo(param: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/getLstUserInfo.exclude"
    );
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getLstObjectInfo(param: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/getLstObjectInfo.exclude"
    );
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getMedia(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/getMedia.exclude",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public updateUserInfo(param: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/updateUserInfo.exclude"
    );
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getLikeObjectByUser(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/getLikeObjectByUser.exclude",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public saveImage(data: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/saveImage.exclude"
    );
    return axios.post(url, data, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getFollowingUser() {
    const url = process.env.REACT_APP_API_URL + "/reviewer/getFollowingUser"
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getFollowingObject() {
    const url = process.env.REACT_APP_API_URL + "/reviewer/getFollowingObject"
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }
  
  
  public getTopUser(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/reviewer/getTopUser.exclude",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getFollowing() {
    const url = process.env.REACT_APP_API_URL + "/reviewer/getFollowing"
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }
}
