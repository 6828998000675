import React, { useEffect, useState } from 'react'
import { Object } from '../../../models/object';
import { t } from 'i18next';

export default function About(props: any) {
    const [object, setObject] = useState<Object>();
    const [followCount, setFollowCount] = useState<number>();
    useEffect(() => {
        setObject(props.data.object)
        setFollowCount(props.data.followCount)
    }, [props])
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="block-box user-about">
                        <div className="widget-heading">
                            <h3 className="widget-title">{t("object.menu.introduce")}</h3>
                            {/* <div className="dropdown">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">...</button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#">Close</a>
                                    <a className="dropdown-item" href="#">{t("object.button.edit")}</a>
                                    <a className="dropdown-item" href="#">Delete</a>
                                </div>
                            </div> */}
                        </div>
                        <ul className="user-info">
                            <li>
                                <label>{t("object.menu.introduce")}</label>
                                <p>{object?.objectName}</p>
                            </li>
                            {object?.description ? <li>
                                <label>{t("object.label.info")}:</label>
                                <p>{object?.description}</p>
                            </li> : <></>}
                            <li>
                                <label>{t("object.info.count.followers")}:</label>
                                <p>{followCount} {t("object.label.follow")}</p>
                            </li>
                            {object?.objectInfo?.email ? <li>
                                <label>{t("object.label.email")}:</label>
                                <p>{object?.objectInfo?.email}</p>
                            </li> : <></>}
                            {object?.address ? <li>
                                <label>{t("object.label.address")}:</label>
                                <p>{object?.address}</p>
                            </li> : <></>}
                            {object?.objectInfo?.phoneNumber ? <li>
                                <label>{t("object.label.phone")}:</label>
                                <p>{object?.objectInfo?.phoneNumber}</p>
                            </li> : <></>}
                            {object?.objectInfo?.website ? <li>
                                <label>{t("object.label.web")}:</label>
                                <p>{object?.objectInfo?.website}</p>
                            </li> : <></>}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
