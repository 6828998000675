import React, { useEffect, useState } from "react";
import { GroupService } from "../../../services/group/GroupService";
import { toast } from "react-toastify";
import AppModal from "../../commons/ui/Modal/AppModal";
import CreateGroupForm from "./CreateGroupForm";
import { useDispatch } from "react-redux";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";
import { PaginationConstant } from "../../../constants/PaginationConstant";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Groups() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [lstGroupMap, setLstGroupMap] = useState<any[]>([]);
  const [lstMyGroup, setLstMyGroup] = useState<[]>()
  const [lstInvitationJoinGroup, setLstInvitationJoinGroup] = useState<[]>()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [nameSearch, setNameSearch] = useState<string>("")
  const [viewType, setViewType] = useState<string>("grid")
  const dispatch = useDispatch();
  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SUGGEST_GROUP_SIZE,
    nameSearch: ""
  });
  const [totalEleSuggest, setTotalEleSuggest] = useState(0)


  useEffect(() => {
    _refreshSuggestGroup()
    _getMyGroup()
    _getInvitationJoinGroup()
  }, [])

  const handleSearch = () => {
    // if (nameSearch.trim() !== "") {
      setLstGroupMap([])
      setSearchRequest({
        ...searchRequest,
        nameSearch: nameSearch.trim()
      })
    // }
  }

  useEffect(() => {
    if(searchRequest.page >=1){
      _getSuggestGroup();
    }   
  }, [searchRequest]);

  function _getMyGroup() {
    GroupService.getInstance().getMyGroup().then((result: any) => {
      if (result.data.status) {
        setLstMyGroup(result.data.responseData);
      }
    }).catch((error: any) => {
      
    })
  }
  function _getInvitationJoinGroup() {
    GroupService.getInstance().getInvitationJoinGroup().then((result: any) => {
      if (result.data.status) {
        setLstInvitationJoinGroup(result.data.responseData);
      }

    }).catch((error: any) => {
      // console.log("Error: " + error);
    })
  }

  function _getSuggestGroup() {
    dispatch(showAndHideSpinner(true));
    GroupService.getInstance().search(searchRequest).then((result) => {
      if (result.data.status) {
        let lstTemp = lstGroupMap.concat(result.data.responseData.lstSuggest)
        setTotalEleSuggest(result.data.responseData.totalElement)
        setLstGroupMap(lstTemp)
      }
      dispatch(showAndHideSpinner(false));
    }).catch((error: any) => {
      // console.log("Error: " + error);
      dispatch(showAndHideSpinner(false));
    })
  }

  function _refreshSuggestGroup() {
    dispatch(showAndHideSpinner(true));
    GroupService.getInstance().search(searchRequest).then((result) => {
      if (result.data.status) {
        setTotalEleSuggest(result.data.responseData.totalElement)
        setLstGroupMap(result.data.responseData.lstSuggest)
      }
      dispatch(showAndHideSpinner(false));
    }).catch((error: any) => {
      // console.log("Error: " + error);
      dispatch(showAndHideSpinner(false));
    })
  }

  const redirectGroupDetail = (id: string) => {
    navigate("/groups/" + id)

  }
  const requestJoin = (group: any) => {
    GroupService.getInstance().requestJoinGroup({groupId: group.id}).then((result: any) => {
      if (result.data.status) {
        toast.success(t("group.message.requested"))
        _refreshSuggestGroup()
        _getMyGroup()
      } else {
        toast.error(t("group.message.requested-err"))
      }
    }).catch((error: any) => {
      // console.log("Error: " + error);
    })
  }
  const closeForm = (isUpdate: any) => {
    setIsOpenModal(false)
    if (isUpdate) {
      _getMyGroup()
    }
  }
  const removeCss = () => {
    var element: any = document.getElementById("wrapper");
    element.style.position = "unset";
  }

  useEffect(() => {
    removeCss()
  }, [isOpenModal])

  const handleAnswerInvite = (isAccept: any, groupId: any) => {
    if (isAccept) {
      GroupService.getInstance().acceptInviteJoinGroup({ groupId: groupId }).then((result: any) => {
        if (result.data.status) {
          toast.success(t("group.message.joined"))
          _getSuggestGroup()
          _getMyGroup()
          _getInvitationJoinGroup()
        } else {
          toast.error(t("common.message.server-error"))
        }
      }).catch((error: any) => {
        // console.log("Error: " + error);

      })
    } else {
      GroupService.getInstance().rejectInviteJoinGroup({ groupId: groupId }).then((result: any) => {
        if (result.data.status) {
          toast.success(t("group.message.joined"))
          _getSuggestGroup()
          _getMyGroup()
          _getInvitationJoinGroup()
        } else {
          toast.error(t("group.message.joined-err"))
        }
      }).catch((error: any) => {
        // console.log("Error: " + error);
      })
    }
  }

  const loadMoreGroups = () => {
    setSearchRequest({
      ...searchRequest,
      page: searchRequest.page + 1
    });
  };

  const handleSeeMember = (groupId:any) => {
    navigate("/groups/" + groupId + "?tab=1")
  }

  const cancelRequest = (groupId:any) =>{
    GroupService.getInstance().cancelRequestJoin({ groupId: groupId }).then((result: any) => {
        if (result.data.status) {
            toast.success(t("group.message.cancel-request"))
            _refreshSuggestGroup()
        } else {
            toast.error(t("group.message.cancel-request-err")) 
        }
    }).catch((error: any) => {
        console.log(error);
    })
}
  return (
    <>
      <AppModal open={isOpenModal} onClose={closeForm}>
        <CreateGroupForm open={isOpenModal} onCloseForm={closeForm} title={t("group.text.title-create")}></CreateGroupForm>
      </AppModal>
      <div className="page-content">
        <div className="container">
          <div className="block-box user-search-bar">
            <div className="box-item search-box">
              <div className="input-group">
                <input type="text" className="form-control" placeholder={t("group.text.search-placeholder")} onChange={(event: any) => { setNameSearch(event.target.value) }} />
                <div className="input-group-append">
                  <button className="search-btn" type="button" onClick={handleSearch}><i className="icofont-search"></i></button>
                </div>
              </div>
            </div>
            {/* <div className="box-item search-filter">
            <div className="dropdown">
              <label>Order By:</label>
              <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">Newest Groups</button>
              <div className="dropdown-menu">
                <a className="dropdown-item">All Groups</a>
                <a className="dropdown-item">Newest Groups</a>
                <a className="dropdown-item">Oldest Groups</a>
              </div>
            </div>
          </div> */}
            <div className="box-item search-switcher">
              <ul className="user-view-switcher">
                <li className={viewType === 'grid' ? 'active': ''}>
                  <a className="user-view-trigger" data-type="user-grid-view" onClick={()=>setViewType("grid")}>
                    <i className="icofont-layout"></i>
                  </a>
                </li>
                <li className={viewType === 'list' ? 'active': ''}>
                  <a className="user-view-trigger" data-type="user-list-view" onClick={()=>setViewType("list")}>
                    <i className="icofont-listine-dots"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div id="user-view" className={'user-'+ viewType +'-view'}>
            <div className="row gutters-20">
              {lstGroupMap && lstGroupMap.length > 0 ?
                <>
                  {lstGroupMap.map((groupMap: any) => (
                    <div className="col-xl-3 col-lg-4 col-md-6" key={groupMap.group.id}>
                      <div className="widget-author user-group">
                        <div className="author-heading">
                          <div className="cover-img">
                          <img src={process.env.REACT_APP_API_URL +
                            "/public/image-view/" +
                            groupMap.group?.coverImage?.atchFleSeq}
                            onError={(event: any) => {
                              event.target.src = require("../../../assets/cover_1.jpg")
                            }}
                            style={{
                              objectPosition: groupMap.group?.coverImage && `${groupMap.group?.coverImage?.positionX * 100}% ${groupMap.group?.coverImage?.positionY * 100}%`,
                              objectFit: 'cover',
                            }} alt="cover" />
                          </div>
                          <div className="group-img">
                            <a onClick={() => redirectGroupDetail(groupMap.group.id)}>
                            <img style={{
                              objectPosition: groupMap.group?.avatarImage && `${groupMap.group?.avatarImage?.positionX * 100}% ${groupMap.group?.avatarImage?.positionY * 100}%`,
                              objectFit: 'cover',
                              width: "100px",
                              height: "100px",
                            }} src={process.env.REACT_APP_API_URL +
                              "/public/image-view/" +
                              groupMap.group?.avatarImage?.atchFleSeq} alt="author" 
                              onError={(event: any) => {
                                event.target.src = require("../../../assets/default-group.png")
                              }}/>
                            </a>
                          </div>
                          <div className="profile-name">
                            <h4 className="author-name"><a onClick={() => redirectGroupDetail(groupMap.group.id)}>{groupMap.group.groupName}</a></h4>
                            {/* <div className="author-location">@tourist</div> */}
                          </div>
                        </div>
                        <div className="member-thump">
                          {groupMap.requested ?
                            <button className="btn btn-info mb-3" onClick={()=>{cancelRequest(groupMap.group.id)}}>{t("group.button.cancel-request")}</button>
                            :
                            <>
                              {groupMap.invited ?
                                <>
                                  <button className="btn btn-info mb-3 mx-1" onClick={() => handleAnswerInvite(false, groupMap.group.id)}>{t("group.button.reject")}</button>
                                  <button className="btn btn-info mb-3 mx-1" onClick={() => handleAnswerInvite(true, groupMap.group.id)}>{t("group.button.accept")}</button>
                                </>
                                :
                                <button className="btn btn-info mb-3" onClick={() => requestJoin(groupMap.group)}>{t("group.button.join")}</button>
                              }
                            </>
                          }

                        </div>
                        <ul>
                          <li>
                            <a><span className="item-number font-weight-bold">{groupMap.numPost}</span> <span className="item-text">{t("group.text.group-post")}</span></a>
                          </li>
                          <li>
                            <a onClick={()=>handleSeeMember(groupMap.group.id)}><span className="item-number font-weight-bold">{groupMap.numMember}</span> <span className="item-text">{t("group.text.all-member")}</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </>


                :
                <div className="col-md-12 text-center">
                  <h5 className="text-secondary">{t("group.text.no-data")}</h5>
                </div>
              }
            </div>
            {lstGroupMap && lstGroupMap.length > 0 && lstGroupMap.length < totalEleSuggest &&
              <div className="block-box load-more-btn">
                <a className="item-btn" onClick={loadMoreGroups}>
                  <i className="icofont-refresh"></i>{t("common.button.loadmore")}
                </a>
              </div>}
          </div>
        </div>

        <div className="widget-block widget-break-lg quick-access">
          <div className="widget widget-groups">
            <div className="widget-heading">
              <h3 className="widget-title text-dark-c">{t("group.text.your-group")}</h3>
            </div>
            <div className="group-list scrollbar" style={{ overflowY: "scroll", maxHeight: "200px" }}>
              {
                lstMyGroup && lstMyGroup.length > 0 ?
                  <>
                    {lstMyGroup.map((group: any) => (
                      <div className="media" key={group.groupInf?.id}>
                        <div className="item-img">
                          <a onClick={() => redirectGroupDetail(group.groupInf?.id)}>
                            <img src={process.env.REACT_APP_API_URL +
                              "/public/image-view/" +
                              group.groupInf?.avatarImage?.atchFleSeq} alt="author" 
                              onError={(event: any) => {
                                event.target.src = require("../../../assets/default-group.png")
                              }} 
                              style={{
                                objectPosition: group.groupInf?.avatarImage && `${group.groupInf?.avatarImage?.positionX * 100}% ${group.groupInf?.avatarImage?.positionY * 100}%`,
                                objectFit: 'cover',
                                width: "50px",
                                height: "50px",
                              }}/>
                          </a>
                        </div>
                        <div className="media-body">
                          <h4 className="item-title">
                            <a onClick={() => redirectGroupDetail(group.groupInf.id)}>{group.groupInf.groupName}</a>
                          </h4>
                          <div className="item-member">{group.groupInf.members?.length} {t("group.label.members")}</div>
                        </div>
                      </div>
                    ))}
                  </>
                  :
                  <>
                    <div className="text-center">
                      <h5 className="text-secondary">{t("group.text.no-data")}</h5>
                    </div>
                  </>
              }
            </div>
            <div className="see-all-btn"><a className="item-btn" onClick={() => { setIsOpenModal(true) }}>{t("group.button.create")}</a></div>
          </div>


          <div className="widget widget-groups">
            <div className="widget-heading">
              <h3 className="widget-title text-dark-c">{t("group.text.invitation")}</h3>
            </div>
            <div className="group-list scrollbar" style={{ overflowY: "scroll", maxHeight: "250px" }}>
              {
                lstInvitationJoinGroup && lstInvitationJoinGroup.length > 0 ?
                  <>
                    {lstInvitationJoinGroup.map((group: any) => (
                      <div className="media" key={group.id}>
                        <div className="item-img">
                          <a onClick={() => redirectGroupDetail(group.id)}>
                            <img src={process.env.REACT_APP_API_URL +
                              "/public/image-view/" +
                              group?.avatarImage?.atchFleSeq} alt="author"
                              onError={(event: any) => {
                                event.target.src = require("../../../assets/default-group.png")
                              }}
                              style={{
                                objectPosition: group?.avatarImage && `${group?.avatarImage?.positionX * 100}% ${group?.avatarImage?.positionY * 100}%`,
                                objectFit: 'cover',
                                width: "50px",
                                height: "50px",
                              }}/>
                          </a>
                        </div>
                        <div className="media-body">
                          <h4 className="item-title">
                            <a onClick={() => redirectGroupDetail(group.id)}>{group.groupName}</a>
                          </h4>
                          <div className="item-member">{group.members.length} {t("group.label.members")}</div>
                        </div>
                      </div>
                    ))}
                  </>
                  :
                  <>
                    <div className="text-center">
                      <h5 className="text-secondary">{t("group.text.no-data")}</h5>
                    </div>
                  </>
              }

            </div>
          </div>
        </div>


      </div>

    </>

  );
}
