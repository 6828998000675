import axios from "axios";
import { RequestParam } from "../../models/request-params";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { ParamUtil } from "../../utils/ParamUtil";


export class ComStdService {
  private static _comStdService: ComStdService;

  public static getInstance(): ComStdService {
    if (!ComStdService._comStdService) {
      ComStdService._comStdService = new ComStdService();
    }
    return ComStdService._comStdService;
  }

  public getByCommNm(commCd: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(commCd);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/comStd/getComNm.do",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getByUpCommCd(request: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(request);
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_API_URL + "/comStd/getTccoStd.exclude",
      params
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth()
    })
  }

}
