import { useEffect, useState } from "react";
import Select from "react-select";

type DropdownProps = {
  sources: any[];
  bindLabel: string;
  bindValue: any;
  value?: any;
  onChange: (data?: any) => void;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: any;
  isMulti?: boolean;
  onClickEventNoOptions?: () => void;
  isValid?: boolean;
};

export default function GroupClassificationDropdownCustom(props: DropdownProps) {
  const [sources, setSources] = useState<any[]>([]);
  const [defaultValue, setDefaultValue] = useState<any>(props.defaultValue);
  const [isMulti, setMulti] = useState(props.isMulti);

  useEffect(() => {
    let temps: any[] = [];
    props.sources.forEach((ele) => {
      let temp: any = {
        label: ele[props.bindLabel],
        value: ele[props.bindValue],
      };
      temps.push(temp);
    });
    setSources(temps);
  }, [props.sources]);

  useEffect(() => {
    if (props.defaultValue) {
      onChange(defaultValue);
    }
  }, [props.sources]);

  useEffect(() => {
    if (props.isMulti) {
      setMulti(props.isMulti);
    }
  }, [props.isMulti]);

  const onChange = (data: any) => {
    if (data) {
      let selectedLst: any = [];
      props.sources.forEach((source: any) => {
        data.forEach((ele: any) => {
          if (source.id === ele.value) {
            selectedLst.push(source);
          }
        });
      });

      props.onChange(selectedLst);
    } else {
      props.onChange(null);
    }
  };

  const onClickEventNoOptions = () => {
    props.onClickEventNoOptions?.();
  };

  const customNoOptionsMessage = () => {
    return (
      <div>
        <div style={{ padding: "20px" }}>
          <p className="widget-title">No Option found...</p>
          <a className="btn-link" onClick={onClickEventNoOptions}>
            Add new group classification !
          </a>
          {/* <button
            type="button"
            className="btn btn-secondary"
          >
            Add New Object
          </button> */}
        </div>
      </div>
    );
  };

  return (
    <>
      <Select
        components={{
          NoOptionsMessage: customNoOptionsMessage,
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            // primary25: 'hotpink',
            primary: "#C2B089",
          },
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            borderBottom: "1px solid",
            borderRadius: "0px",
            borderBottomColor: props.isValid ? "#e0eaf0" : "red",
            borderColor: state.isFocused ? "#e0eaf0" : "#e0eaf0",
          }),
        }}
        options={sources}
        className="select"
        classNamePrefix={props.placeholder ? props.placeholder : "Select"}
        defaultValue={defaultValue}
        isDisabled={props.disabled}
        onChange={onChange}
        isMulti={isMulti}
        // isClearable
        isSearchable={true}
      />
    </>
  );
}
