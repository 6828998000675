import { useEffect, useState } from "react";
type ModalProps = {
  onClose?: (data?: any) => void;
  open: any;
  title?: any;
  iconUrl?: any;
  children: any;
};

export default function PostDetailModal(props: ModalProps) {
  const { open, title, iconUrl, children, onClose } = props;

  const [isShow, setIsShow] = useState(false);


  useEffect(() => {
    const escFunction = (event: any) => {
      if (event.key === "Escape") {
        onClickClose();
      }
    }

    document.addEventListener("keydown", escFunction, false);
    setIsShow(open);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    }
  }, [open]);

 

  const onClickClose = () => {
    setIsShow(false);
    onClose?.(false);
  };

  return (
    <>
      {isShow && (
        <div
          className="modal fade show "
          style={{ display: "block", }}
        >
          <div className="overlay" onClick={onClickClose}></div>
          <div
            className="modal-dialog modal-dialog-centered modal-xl post-detail"
            style={{ zIndex: 999 }}
          >
            <div className="modal-content">
              <div className="modal-header post-detail" style={{padding: '0px'}}> 
                <h5 className="modal-title fs-5 text-center">{title}</h5>
                <img src={iconUrl}></img>
                <button
                  type="button"
                  className="close post-detail"
                  onClick={onClickClose}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div>
              {children}
              </div>
            </div>

            
          </div>
        </div>
      )}
    </>
  );
}
