import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";

export class ObjectService {
    private static _objectService: ObjectService

    public static getInstance(): ObjectService {
        if (!ObjectService._objectService) {
            ObjectService._objectService = new ObjectService()
        }
        return ObjectService._objectService;
    }

    getObjectById(id: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/object/getObjectById.exclude?id=" + id);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    getObjectByIds(ids: any[]) {
        const queryIds = ids.map(id => `ids=${id}`).join('&');
        const url = `${process.env.REACT_APP_API_URL}/object/getObjectByIds.exclude?${queryIds}`;
        return axios.get(url, {
          headers: HeadersUtil.getHeadersAuth()
        });
    }

    getAll() {
        const url = process.env.REACT_APP_API_URL + "/object/getAll.exclude";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    save(request : any){
        const url = process.env.REACT_APP_API_URL + "/object/save.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
    searchPostByObject(param: any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(param);
        const url = ApiUrlUtil.buildQueryString(
          process.env.REACT_APP_API_URL + "/object/searchPostByObject.exclude",
          params
        );
        return axios.get(url, {
          headers: HeadersUtil.getHeadersAuth(),
        });
      }
}

