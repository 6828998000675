import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { PostService } from '../../../services/post/PostService';
import { toast } from 'react-toastify';
import { GroupService } from '../../../services/group/GroupService';
import { useTranslation } from 'react-i18next';
import GroupDropdownCustom from '../../pages/Groups/GroupInfo/component/GroupDropdownCustom';

export default function ShareFormModal(props: any) {

  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [lstMyGroup, setLstMyGroup] = useState<any>([])
  const [selectedGroup, setSelectedGroup] = useState<any>({})

  useEffect(() => {
    if (props.isGroup) {
      _getMyGroup();
    }
  }, [])

  const onClickClose = () => {
    props.onClose();
  }

  const handleSaveForm = (data: any) => {
    if (props.isGroup) {
      if (selectedGroup.id) {
        _saveForm({ ...data, postId: props.postId, groupId: selectedGroup.id})
      } else {
        toast.error(t("share.text.reqGroup"))
      }
    } else {
      _saveForm({ ...data, postId: props.postId })
    }
  }

  function _saveForm(data: any) {
    PostService.getInstance().sharePost(data).then((result: any) => {
      if (result.data.status) {
        toast.success(t("share.message.shareSucc"))
        props.onClose();
      }
      else {
        toast.error(t("share.message.shareErr"))
      }
    }).catch((error: any) => {
      toast.error(t("share.message.shareErr"))
    })
  }


  function _getMyGroup() {
    GroupService.getInstance().getMyGroupInf().then((result: any) => {
      if (result.data.status) {
        setLstMyGroup(result.data.responseData);
      }
    }).catch((error: any) => {
    })
  }

  const onSelectGroup = (data: any) => {    
    setSelectedGroup(data)
  }

  return (
    <div className="modal-content">
      <div
        id="scroll-modal"
        className="modal-body"
        style={{ maxHeight: "800px", overflowY: "scroll" }}
      >
        <h3 className="heading-title px-4">{props.title}</h3>
        <div className="form px-4 forum-topic-add" id="new-post-form">
          <div className="form-group">
            <label>{t("share.label.title")}:</label>
            <input {...register("title", { required: true })} type="text" className="form-control" name='title' />
            {errors?.title && <p className="msg-error">{t("share.text.reqTitle")}</p>}
          </div>
          <div className="form-group">
            <label>{t("share.label.content")}:</label>
            <textarea {...register("content", { required: true })} name="content" className="form-control textarea" />
            {errors?.content && <p className="msg-error">{t("share.text.reqContent")}</p>}
          </div>
          {props.isGroup && 
          <div className="form-group">
            <div className="group-list scrollbar">
              {
                lstMyGroup && lstMyGroup.length > 0 ?
                  <>
                    <label>{t("share.label.group")}:</label>

                    <GroupDropdownCustom
                      sources={lstMyGroup}
                      bindValue={"id"}
                      bindLabel={"groupName"}
                      onChange={onSelectGroup}
                      isValid={errors?.selectedGroup ? false : true}
                    />
                    {/* {lstMyGroup.map((group: any) => (
                      <div className="media my-1" onClick={() => onSelectGroup(group?.groupInf)} key={group.groupInf?.id}>
                        <div className="item-img">
                          <a onClick={() => onSelectGroup(group?.groupInf)}>
                            <img src={process.env.REACT_APP_API_URL +
                              "/public/image-view/" +
                              group.groupInf?.avatarImage?.atchFleSeq} alt="author"
                              onError={(event: any) => {
                                event.target.src = require("../../../assets/default-avatar.jpg")
                              }}
                              style={{
                                objectPosition: group.groupInf?.avatarImage && `${group.groupInf?.avatarImage?.positionX * 100}% ${group.groupInf?.avatarImage?.positionY * 100}%`,
                                objectFit: 'cover',
                                width: "50px",
                                height: "50px",
                              }} />
                          </a>
                        </div>
                        <div className="media-body d-flex">
                          <h6 className="item-title text-dark-c mx-2 ">
                            <a onClick={() => onSelectGroup(group?.groupInf)}>{group.groupInf?.groupName}</a>
                          </h6>
                          {group.groupInf?.id === selectedGroup.id &&
                            <div className='mx-3'>
                              <a className="color-fb">
                                <i className="icofont-check-circled"></i>
                              </a>
                            </div>}
                        </div>
                      </div>
                    ))} */}
                  </>
                  :
                  <>
                    <div className="text-center">
                      <h5>{t("share.text.no-data")}</h5>
                    </div>
                  </>
              }
            </div>
          </div>}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-secondary mr-4" onClick={onClickClose}>{t("common.button.cancel")}</button>
        <button className="btn btn-info" onClick={handleSubmit(handleSaveForm)}>{t("common.button.save")}</button>
      </div>
    </div>
  )
}
