import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CompareService } from "../../../services/object/CompareService";
import { ObjectService } from "../../../services/object/ObjectService";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import Swal from "sweetalert2";
import { refreshCompare } from "../../../redux/reducers/compareSlice";
import { CommonConstant } from "../../../constants/CommonConstant";
import { PostService } from "../../../services/post/PostService";
import { toast } from "react-toastify";
import AppModal from "../../commons/ui/Modal/AppModal";
import PostDetail from "../../pages/Newsfeed/components/PostDetail/PostDetail";
import Compare from "./Compare";
import { ReviewerService } from "../../../services/reviewer/ReviewerService";
import { t } from "i18next";
export function useCompareHandler() {
    const [listCompare, setListCompare] = useState<string[]>([]);
    const [listObject, setListObject] = useState<any[]>([]);
    const refresh = useAppSelector((state) => state.compare.refresh)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    useEffect(() => {
        setListCompare(CompareService.getObject())
    }, [refresh])

    useEffect(() => {
        setOpenModal(false)
        if (listCompare.length > 0) {
            ObjectService.getInstance().getObjectByIds(listCompare)
                .then(resp => {
                    if (resp.data.status) {
                        setListObject(resp.data.responseData.lstObject);
                    }

                })
                .catch(err => {

                })
        } else {
            setListObject([]);
        }
    }, [listCompare])
    function handleClearCompareClick() {
        CompareService.clearObjects();
        dispatch(refreshCompare(new Date().getTime()))
    }

    function handleAddCompareClick(object: any | undefined) {
        if (object && listObject && listObject.length < CommonConstant.LIMIT_COMPARE) {
            if (listObject.length === 0) {
                CompareService.addObject(object.id);
                dispatch(refreshCompare(new Date().getTime()))
            } else {
                if (listObject[0]?.category?.cateId === object?.category?.cateId) {
                    CompareService.addObject(object.id);
                    dispatch(refreshCompare(new Date().getTime()))
                } else {
                    Swal.fire({
                        title: t("toast.sure"),
                        text: "Làm mới danh sách so sánh",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "OK"
                    }).then(function (result) {
                        if (result.value) {
                            CompareService.clearObjects();
                            CompareService.addObject(object.id);
                            dispatch(refreshCompare(new Date().getTime()))
                        }
                    });
                }
            }
        } else {
            toast.info(t("toast.fullCompare"))
        }
    }
    function handleRemoveCompareClick(object: any | undefined) {
        if (object) {
            CompareService.removeObject(object.id);
            dispatch(refreshCompare(new Date().getTime()))
        }
    }
    const openCompare = (event: any) => {
        if (event.code !== 'Escape') {
            setOpenModal(true)
        }
    }
    const closeCompare = (event: any) => {
        setOpenModal(false)
    }
    return {
        listObject,
        openModal,
        handleAddCompareClick,
        handleRemoveCompareClick,
        handleClearCompareClick,
        openCompare,
        closeCompare
    };
}
export default function SidebarRight() {
    const { listObject, openModal, handleRemoveCompareClick, handleClearCompareClick, openCompare, closeCompare } = useCompareHandler();
    const [isOpenModalChat, setIsOpenModalChat] = useState(false);
    const [isOpenShrink, setIsOpenShrink] = useState(false);
    const [isShowChatAction, setIsShowChatAction] = useState(false);
    const [isOpenPostDetailPopup, setIsOpenPostDetailPopup] = useState(false);
    const [postDetail, setPostDetail] = useState<any>({});
    const [lstFollower, setLstFollower] = useState<[]>();
    const [tooltipIndex, setTooltipIndex] = useState(null);
    const [objectIndex, setObjectIndex] = useState(null);
    const handleMouseEnter = (index: any) => {
        setTooltipIndex(index);
    };

    const handleMouseLeave = () => {
        setTooltipIndex(null);
    };
    const handleObjectEnter = (index: any) => {
        setObjectIndex(index);
    };

    const handleObjectLeave = () => {
        setObjectIndex(null);
    };
    const viewPostDetail = (postId: any) => {
        PostService.getInstance().getPostDetail(postId).then((response: any) => {
            if (response.data.status) {
                setPostDetail({
                    ...response.data.responseData,
                    // user: props.userInfo
                })
                setIsOpenPostDetailPopup(true)
            }

        }).catch((error: any) => {
            toast.error(t("toast.serverError"))
        })
    }
    const onClosePostViewPopup = (data: any) => {
        setIsOpenPostDetailPopup(false)
    }
    useEffect(() => {
        ReviewerService.getInstance().getFollowing().then((response: any) => {
            if (response.data.status) {
                setLstFollower(response.data.responseData)
            }
        }).catch((error: any) => {
            toast.error(t("toast.serverError"))
        })
    }, []);
    return (
        <>
            <AppModal open={openModal && listObject?.length > 0} children={<Compare listObject={listObject} viewPostDetail={viewPostDetail} />} onClose={closeCompare}></AppModal>
            <AppModal
                open={isOpenPostDetailPopup}
                children={<PostDetail postId={postDetail.id} />}
                onClose={onClosePostViewPopup}
            ></AppModal>
            <div className="fixed-sidebar right">
                <div className="fixed-sidebar-right small-sidebar">
                    <div className="sidebar-menu-wrap" style={{ height: "100%" }}>
                        <div
                            className="mCustomScrollbar chat-list-area"
                        >
                            <ul className="user-chat-list">
                                {
                                    lstFollower?.map((element: any, index: any) => {
                                        return (
                                            <li key={index} className="chat-item chat-open" onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={handleMouseLeave} style={{ position: "relative" }}>
                                                <div className="author-img" onClick={() => setIsOpenModalChat(!isOpenModalChat)}>
                                                    <img src={process.env.REACT_APP_API_URL + "/public/image-view/" + element.avatarImage?.atchFleSeq} onError={(event: any) => {
                                                        event.target.src = require("../../../assets/default-avatar.jpg")
                                                    }} alt="chat" />
                                                    <span className="chat-status online"></span>
                                                </div>
                                                {tooltipIndex === index && (
                                                    <div className="tooltip-chat">
                                                        <span>{element.fullName}</span>
                                                    </div>
                                                )}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'compare-area ' + (listObject?.length > 0 ? 'compare-area-show' : 'compare-area-hidden')}>
                            <div className="compare-icon"><img src="/media/icon-compare-1.png" /></div>
                            <div className="compare-item">
                                <ul className="user-chat-list">
                                    {listObject && listObject.map((element, index) => {
                                        return (
                                            <li key={index} className="chat-item chat-open" style={{position: "relative"}} onMouseEnter={() => handleObjectEnter(index)}
                                            onMouseLeave={handleObjectLeave} >
                                                <NavLink to={"/object/" + element?.id}>
                                                    <div className="author-img" style={{ width: "45px", height: "45px" }}>
                                                        <img style={{ width: '100%', height: '100%', objectFit: "cover" }} src={process.env.REACT_APP_API_URL +
                                                            "/public/image-view/" +
                                                            element?.avatarImage?.atchFleSeq} onError={(event: any) => {
                                                                event.target.src = require("../../../assets/default-avatar.jpg")
                                                            }} alt="chat" />
                                                        <span onClick={(event) => {
                                                            event.preventDefault();
                                                            handleRemoveCompareClick(element)
                                                        }} className="compare-remove"><i className="icofont-error"></i></span>
                                                    </div>
                                                    {objectIndex === index && (
                                                    <div className="tooltip-chat-object">
                                                        <span>{element?.objectName}</span>
                                                    </div>
                                                )}
                                                </NavLink>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                            <div className="compare-speeddial">
                                <ul className="dials">
                                    <li><a onClick={() => handleClearCompareClick()} className={"compare-btn-child " + (listObject && listObject.length === 0 ? "disabled-link" : "")} ><span className="icofont-refresh"></span></a></li>
                                    {/* <li><a className="compare-btn-child"><span className="icofont-ui-add"></span></a></li> */}
                                </ul>
                                <div className="compare-btn submit-btn">
                                    <a onClick={(event) => openCompare(event)}><i className="icofont-rounded-expand"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <div className={"chat-conversion-box" + (isOpenModalChat ? " modal-show" : "") + (isOpenShrink ? " shrink" : "")} id="chat-box-modal" aria-labelledby="chat-modal-label"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="chat-modal-label">Fahim Rahman <span className="online"></span></h6>
                            <div className="action-icon">
                                <button className="chat-shrink" onClick={() =>setIsOpenShrink(!isOpenShrink)}><i className="icofont-minus"></i></button>
                                <button onClick={() =>setIsOpenModalChat(false)} type="button" className="close chat-close chat-open" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body" style={{height: "250px", overflow:"auto"}}>
                            <ul className="chat-conversion">
                                <li className="chat-others">
                                    <div className="author-img">
                                        <img src="media/figure/chat_12.jpg" alt="Chat" />
                                    </div>
                                    <div className="author-text">
                                        <span>Xin chào! Bạn tên là gì?</span>
                                    </div>
                                </li>
                                <li className="chat-you">
                                    <div className="author-img">
                                        <img src="media/figure/chat_11.jpg" alt="Chat" />
                                    </div>
                                    <div className="author-text">
                                        <span>hmm </span>
                                    </div>
                                </li>
                                <li className="chat-you">
                                    <div className="author-img">
                                        <img src="media/figure/chat_11.jpg" alt="Chat" />
                                    </div>
                                    <div className="author-text">
                                        <span>hmm </span>
                                    </div>
                                </li>
                                <li className="chat-you">
                                    <div className="author-img">
                                        <img src="media/figure/chat_11.jpg" alt="Chat" />
                                    </div>
                                    <div className="author-text">
                                        <span>hmm </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <form>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Write your text here....." />
                                    <div className="chat-plus-icon" onClick={() => setIsShowChatAction(!isShowChatAction)}><i className="icofont-plus-circle"></i></div>
                                    <div className={"file-attach-icon" + (isShowChatAction ? " show" : "")}>
                                        <a><i className="icofont-slightly-smile"></i></a>
                                        <a><i className="icofont-camera"></i></a>
                                        <a><i className="icofont-image"></i></a>
                                        <a><i className="icofont-mic"></i></a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}
