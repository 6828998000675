import { useState } from "react";
import { transform } from "typescript";

export default function Rating(props: any) {
  const arrRate = [1, 2, 3, 4, 5];

  const [rate, setRate] = useState(props.rate);

  return (
    <>
      <div className="eval_item" style={{paddingBottom: '2px', paddingTop: '0'}}>
        {arrRate.map((ele) =>
          Math.floor(rate) >= ele ? (
            <span key={ele}>
              &nbsp;
              <img src="/media/star-2.png" alt="start" width={15} />
              &nbsp;
            </span>
          ) : Math.ceil(rate) === ele ? (
            <span key={ele}>
              &nbsp;
              <img src="/media/star-3.png" alt="start" width={15} />
              &nbsp;
            </span>
          ) : (
            <span key={ele}>
              &nbsp;
              <img src="/media/star-1.png" alt="start" width={15} />
              &nbsp;
            </span>
          )
        )}
        <div style={{transform: 'translate(10px, 2px)', display: 'inline-block'}}>{'   '+Math.floor(rate)+ '/5'}</div>
      </div>
    </>
  );
}
