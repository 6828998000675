import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { PaginationConstant } from "../../../constants/PaginationConstant";
import Spinner from "../../commons/ui/Spinner/Spinner";
import Header from "./components/Header/Header";
import SidebarLeft from "./components/SidebarLeft/SidebarLeft";
import { bubbles } from "../Newsfeed/bubble";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";
import { PostService } from "../../../services/post/PostService";

import { CommonConstant } from "../../../constants/CommonConstant";
import { AuthUtil } from "../../../utils/AuthUtil";
import PostView from "./components/PostView";
import "./HomePage.css";
import AppModal from "../../commons/ui/Modal/AppModal";
import Login from "./components/Login/Login";
import { useLocation } from "react-router-dom";
import { TranslationConstant } from "../../../constants/TranslationConstant";
import { t } from "i18next";
import { useAppSelector } from "../../../hooks/hook";

export default function HomePage() {
  const dispatch = useDispatch();
  const lang = useAppSelector((state) => state.language.lang);
  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });
  const [posts, setPosts] = useState<any[]>([]);

  const [bubblesSelected, setBubblesSelected] = useState<any>();
  const [bubble, setBubble] = useState<any[]>(bubbles);

  const [isOpenLoginPopup, setIsOpenLoginPopup] = useState(false);
  const [trendingCategory, setTrendingCategory] = useState<any[]>([]);
  const [topHashtags, setTopHashtags] = useState<any[]>([]);
  // useEffect(() => {
  //   window.addEventListener("message", addEventListener);
  //   return () => {
  //     window.removeEventListener("message", addEventListener);
  //   };
  // }, []);

  // const addEventListener = useCallback((event: any) => {
  //   if ( event.data && event.data.type === "login") {
  //     AuthUtil.saveToken(event.data.data);
  //     window.location.href = "/";
  //   }
  // }, []);
  const location = useLocation()
  useEffect(() => {
    if (!AuthUtil.isLogin()) {
      const token = new URLSearchParams(location.search).get("access_token");
      if (token) {
        setIsOpenLoginPopup(true)
      }
    }

  }, [location])

  useEffect(() => {
    getPosts();
  }, [bubblesSelected]);

  const getPosts = () => {
    dispatch(showAndHideSpinner(true));
    if (!bubblesSelected) {
      PostService.getInstance()
        .trending(searchRequest)
        .finally(() => {
          setTimeout(() => {
            dispatch(showAndHideSpinner(false));
          }, 500);
        })
        .then((res) => {
          if (res.data.status) {
            let temps = res.data.responseData?.listPost.content;
            setPosts(temps);
            setTrendingCategory(res.data.responseData?.trendingCategory)
            setTopHashtags(res.data.responseData?.topHashtags)
          } else {
            toast.error(t("toast.serverError"))
          }
        });
    }
    if (bubblesSelected) {
      filterTrending();
    }
  };
  const getPostsLastest = () => {
    dispatch(showAndHideSpinner(true));
    PostService.getInstance()
      .getPostLastest(searchRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          let temps = res.data.responseData?.lstPost;
          setPosts(temps);
        } else {
          toast.error(t("toast.serverError"))
        }
      });
  };
  const clear = () =>{
    setPosts([])
    setTrendingCategory([])
    setTopHashtags([])
  }
  const filterTrending = () => {
    PostService.getInstance()
      .filterTrending(searchRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then((res) => {
        let temps = posts.concat(res.data.responseData?.content);
        setPosts(temps);
      });
  };
  const loadMorePosts = () => {
    searchRequest.page = searchRequest.page + 1;
    setSearchRequest(searchRequest);
    getPosts();
  };

  const onLogin = () => {
    // AuthUtil.login();
    setIsOpenLoginPopup(true);
  };

  const onRegister = () => {
    window.location.href =
      process.env.REACT_APP_AUTH_URL +
      "/sign-up?redirect_uri=" +
      window.location.origin;
  };
  const setNavSelected = (bubble: any, type?: any) => {
    if (bubble !== bubblesSelected) {
      setPosts([]);
      if (type === "category") {
        setSearchRequest({
          page: 0,
          size: PaginationConstant.PAGE_SIZE,
          categoryName: bubble,
        });
      } else if (type === "hashtag") {
        setSearchRequest({
          page: 0,
          size: PaginationConstant.PAGE_SIZE,
          tagName: bubble
        });
      } else {
        setSearchRequest({
          page: 0,
          size: PaginationConstant.PAGE_SIZE,
        });
      }
      setBubblesSelected(bubble);
    } else if (bubblesSelected !== 0) {
      setPosts([]);
      setSearchRequest({
        page: 0,
        size: PaginationConstant.PAGE_SIZE,
      });
      setBubblesSelected(null);
    }
  };
  return (
    <>
      <Spinner></Spinner>

      <AppModal
        open={isOpenLoginPopup}
        size="lg"
        onClose={() => setIsOpenLoginPopup(false)}
      >
        <Login></Login>
      </AppModal>

      <div id="wrapper" className="wrapper open public">
        <Header onLogin={onLogin} onRegister={onRegister}></Header>

        <SidebarLeft onLogin={onLogin} getPostsTrending={getPosts} getPostsLatest={getPostsLastest} clear={clear}></SidebarLeft>

        <div className="page-content">
          <div className="container">
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <div className="post-view-wrap">
                <div className="col">
                  {posts?.map((ele: any, idx) =>
                    idx % 2 == 0 ? (
                      <PostView
                        key={ele.id}
                        post={ele}
                        onLogin={onLogin}
                      ></PostView>
                    ) : (
                      ""
                    )
                  )}
                </div>
                <div className="col">
                  {posts?.map((ele: any, idx) =>
                    idx % 2 != 0 ? (
                      <PostView
                        key={ele.id}
                        post={ele}
                        onLogin={onLogin}
                      ></PostView>
                    ) : (
                      ""
                    )
                  )}
                </div>

                {/* <div className="block-box load-more-btn">
                  <a className="item-btn" onClick={loadMorePosts}>
                    <i className="icofont-refresh"></i>Load More Posts
                  </a>
                </div> */}
              </div>

              {trendingCategory && <div className="widget-block widget-break-lg quick-access">
                <div className="widget-bubbles">
                  {trendingCategory.map((element, index) => (
                    <div
                      key={index}
                      className={
                        "bubble bubble-category " +
                        (bubblesSelected === element.cateName ? "active" : "")
                      }
                      onClick={() => {
                        setNavSelected(element.cateName, "category");
                      }}
                    >
                      <i className="fa fa-tag"></i><span>{lang === TranslationConstant.SOUTH_KOREA ? element.cateNameKr :
                      lang === TranslationConstant.ENGLISH ? element.cateNameEn : element.cateName}</span>
                    </div>
                  ))}
                  {topHashtags && topHashtags.map((element, index) => (
                    <div
                      key={index}
                      className={
                        "bubble bubble-hashtag " +
                        (bubblesSelected === element ? "active" : "")
                      }
                      onClick={() => {
                        setNavSelected(element, "hashtag");
                      }}
                    >
                      #{element}
                    </div>
                  ))}
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
