import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Rating from "../../../commons/post/Rating";
import AppTimeAgo from "../../../commons/ui/DateFormat/AppTimeAgo";
import Reaction from "../../../commons/post/Reaction";
import PostAction from "./PostAction";
import PostImage from "../../../commons/form/PostImage";
import HoverCard from "../../../commons/post/HoverCard/HoverCard";
import { CommonConstant } from "../../../../constants/CommonConstant";
import { CommentService } from "../../../../services/post/CommentService";
import Comment from "../../../commons/post/Comment/Comment";
import Share from "../../../commons/post/Share";
import AvatarImage from "../../../commons/post/AvatarImage";
import { HabbitService } from "../../../../services/habbit/HabbitService";
import PostDetailModal from "./PostDetailModal";
import PostDetail from "./PostDetail/PostDetail";
import { PostService } from "../../../../services/post/PostService";
import { useTranslation } from "react-i18next";

type PostViewProps = {
  post: any;
  onSelect?: (data?: any) => void;
  refresh?: () => void;
  isShared?: boolean;
};

export default function PostView(props: PostViewProps) {
  // const { register, getValues, setValue } = useForm();
  // const [comments, setComments] = useState<any[]>([]);
  const { t, i18n } = useTranslation();

  const [post, setPost] = useState(props.post);
  const [postShared, setPostShared] = useState<any>();

  const [isOpenPostDetailPopup, setIsOpenPostDetailPopup] = useState(false);



  useEffect(() => {    
    setPost(props.post);
    if (props.post?.postSharedId) {
      PostService.getInstance()
        .getPostDetail(props.post.postSharedId)
        .then(
          (res: any) => {
            if (res.data.status) {
              setPostShared(res.data.responseData);
            }
          },
          (error) => { }
        );
    }
  }, [props.post]);

  function shortenParaghap(content: string) {
    if (typeof content !== 'string') {
      return '';
    }
    const words = content.split(' ');
    const sub = words.slice(0, 50).join(' ');
    const hasMore = words.length > 50;

    return (
      <>
        {sub + (hasMore ? '...' : ' ')}
        {hasMore && (
          <a className="" onClick={onSelect}>
            <i>{t("newsfeed.post.seeDetails")}</i>
          </a>
        )}
      </>
    );
  }

  const onSelect = () => {
    const habbit: any = {
      actionType: CommonConstant.ACTION_TYPE_VIEW,
      postId: post?.id,
    };
    HabbitService.getInstance()
      .save(habbit)
      .then((resp) => {
        if (resp.data.status) {
          // console.log(resp);
        }
      })
      .catch((err) => { });
    onOpenPostDetailPopup();
  };

  // const handleSubmitComment = (event: any) => {
  //   if (event.key !== "Enter" || !getValues("content")) {
  //     return;
  //   }

  //   let request = {
  //     postId: post.id,
  //     upCommentId: null,
  //     content: getValues("content"),
  //     attachments: null,
  //   };

  //   CommentService.getInstance()
  //     .save(request)
  //     .then(
  //       (res) => {
  //         if (res.data.status) {
  //           let temps = JSON.parse(JSON.stringify(comments));
  //           temps.push(res.data.responseData);
  //           setComments(temps);
  //           setValue("content", "");
  //         }
  //       },
  //       (error) => {}
  //     );
  // };

  const onChangeComment = (type: any) => {
    if (type === "INCREASE") {
      let temp = {
        ...post,
        totalComment: post.totalComment + 1,
      };
      setPost(temp);
    } else {
      let temp = {
        ...post,
        totalComment: post.totalComment - 1,
      };
      setPost(temp);
    }
  };

  const onOpenPostDetailPopup = () => {
    setIsOpenPostDetailPopup(true);
  };

  const onClosePostDetailPopup = () => {
    setIsOpenPostDetailPopup(false);
  };

  const onChangeEmoji = (type: any, preEmoji: any, currentEmoji: any) => {

    console.log(type, preEmoji, currentEmoji);
    let postTemp = Object.assign({}, post);

    if (type === 'INCREASE') {
      let emoji = {
        commcd: currentEmoji.commCd,
        valueconfig: currentEmoji.valueConfig,
        commnm: currentEmoji.commNm,
        commnmen: currentEmoji.commNmEn,
        commnmvi: currentEmoji.commNmVi,
      };
      postTemp.reactionDetail.totalEmoji += 1;
      let emojis: any[] = postTemp.reactionDetail.emojis || [];
      let isExist = false;
      emojis.forEach((item) => {
        if (item.commcd === emoji.commcd) {
          isExist = true;
          item.total += 1;
        }
      });

      if (!isExist) {
        emojis.push(emoji);
      }
      postTemp.reactionDetail.emojis = emojis;

    } else if (type === 'DECREASE') {
      postTemp.reactionDetail.totalEmoji -= 1;
      postTemp.reactionDetail.emojis.forEach((item: any) => {
        if (item.commcd === currentEmoji.commCd) {
          item.total -= 1;
        }
      });

      let emojis: any[] = postTemp.reactionDetail.emojis || [];
      emojis = emojis.filter((ele: any) => ele.total > 0);

      postTemp.reactionDetail.emojis = emojis;

    } else {
      let emojis: any[] = postTemp.reactionDetail.emojis || [];
      emojis.forEach((item: any) => {
        if (item?.commcd === preEmoji?.commCd) {
          item.total -= 1;
        }
      });
      emojis = emojis.filter((ele: any) => ele.total > 0);

      let emoji = {
        commcd: currentEmoji.commCd,
        valueconfig: currentEmoji.valueConfig,
        commnm: currentEmoji.commNm,
        commnmen: currentEmoji.commNmEn,
        commnmvi: currentEmoji.commNmVi,
      };

      let isExist = false;
      emojis.forEach((item) => {
        if (item.commcd === emoji.commcd) {
          isExist = true;
          item.total += 1;
        }
      });

      if (!isExist) {
        emojis.push(emoji);
      }

      postTemp.reactionDetail.emojis = emojis;
    }

    setPost(postTemp);
  }

  return (
    <>
      <PostDetailModal
        open={isOpenPostDetailPopup}
        children={
          <PostDetail
            postId={post?.id}
            onChangeComment={(type) => onChangeComment(type)}
          />
        }
        onClose={onClosePostDetailPopup}
      ></PostDetailModal>

      {post && <div
        className="block-box post-view"
        style={{
          boxShadow: props.isShared
            ? "inset 0 0 3px 0 #c7c7c7"
            : "rgba(0, 0, 0, 0.16) 0px 1px 4px 0px",
          border: "1px solid #e3e3e3",
          minHeight: "600px"
        }}
      >
        {post?.id && post?.status &&
          <div className="post-header">
            <div className="media">
              <div className="user-img" style={{ width: "54px", height: "54px" }}>
                <AvatarImage
                  atchFleSeq={post?.creator?.avatarImage?.atchFleSeq}
                ></AvatarImage>
              </div>
              <div className="post-view">
                {
                  (post.postSharedId && postShared) ?
                    <div className="user-title">
                      <HoverCard
                        post={post}
                        type={CommonConstant.HOVER_CARD_USER}
                      ></HoverCard>
                      {postShared?.id && postShared?.status && <>&nbsp;{t("newsfeed.post.sharedPostOf")}&nbsp;
                        <HoverCard
                          post={postShared}
                          type={CommonConstant.HOVER_CARD_USER}
                        ></HoverCard>
                      </>}
                    </div>
                    :
                    <div className="user-title">
                      <HoverCard
                        post={post}
                        type={CommonConstant.HOVER_CARD_USER}
                      ></HoverCard>
                      &nbsp;{t("newsfeed.post.postedReviewAbout")}&nbsp;
                      <HoverCard
                        post={post}
                        type={CommonConstant.HOVER_CARD_OBJECT}
                      ></HoverCard>
                      {post && post.object?.legal && (
                        <i className="icofont-check legal"></i>
                      )}
                    </div>
                }
                <ul className="entry-meta">
                  <li className="meta-privacy">
                    {
                      post.group ?
                        <><i className="icofont-ui-user-group"></i> <HoverCard
                          post={post}
                          type={CommonConstant.HOVER_CARD_GROUP}
                        ></HoverCard></>
                        :
                        <><i className="icofont-world"></i> {t("newsfeed.post.public")}</>
                    }
                  </li>
                  <li className="meta-time">
                    <AppTimeAgo date={new Date(post?.createdDate)}></AppTimeAgo>
                  </li>
                </ul>
              </div>
            </div>
            <PostAction post={post} refresh={props.refresh}></PostAction>
          </div>
        }
        {
          post?.id && post?.status ?
            <>
              <div className="post-body">
                <p className="post-title">{post.title}</p>
                <div className="post-content">
                  <div>{shortenParaghap(post.content)}</div>
                </div>

                {!post.postSharedId && (
                  <>
                    <div style={{ position: "relative" }}>
                      <PostImage attachments={post.attachments} detail={false} />
                      <div
                        className={
                          post.attachments ? "image_cover" : "not_image_cover"
                        }
                        onClick={onSelect}
                      >
                        <div className={post.attachments ? "" : "rating-not-attch"}>
                          {post.ratingProps?.map(
                            (ele: any, idx: any) =>
                              idx <= 5 && (
                                <Fragment key={idx}>
                                  <div className="eval_item" title={ele.propNm}>
                                    {ele.propNm}
                                  </div>
                                  <Rating rate={ele.rating}></Rating>
                                </Fragment>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      !props.isShared &&
                      <div className="post-meta-wrap">
                        <div className="post-meta">
                          <div className="post-reaction">
                            {post.reactionDetail?.totalEmoji > 0 && (
                              <>
                                {post.reactionDetail?.emojis.map(
                                  (ele: any, idx: any) =>
                                    idx <= 1 && (
                                      <img key={idx} src={ele.valueconfig} alt="icon" />
                                    )
                                )}
                                <div className="meta-text">
                                  {post.reactionDetail?.totalEmoji}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="post-meta">
                          {post.totalComment > 0 && (
                            <div className="meta-text">
                              {post.totalComment} {t("newsfeed.post.comments")}
                            </div>
                          )}
                        </div>
                      </div>
                    }

                  </>
                )}
                {post.postSharedId && postShared && (
                  <>
                    <PostView post={postShared} isShared={true}></PostView>
                    <div className="post-meta-wrap">
                      <div className="post-meta">
                        <div className="post-reaction">
                          {post.reactionDetail?.totalEmoji > 0 && (
                            <>
                              {post.reactionDetail?.emojis.map(
                                (ele: any, idx: any) =>
                                  idx <= 1 && (
                                    <img key={idx} src={ele.valueconfig} alt="icon" />
                                  )
                              )}
                              <div className="meta-text">
                                {post.reactionDetail?.totalEmoji}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="post-meta">
                        {post.totalComment > 0 && (
                          <div className="meta-text">
                            {post.totalComment} {t("newsfeed.post.comments")}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {!props.isShared && (
                <div className="post-footer">
                  <ul>
                    <Reaction
                      post={post}
                      onChangeEmoji={(type, preEmoji, currentEmoji) => onChangeEmoji(type, preEmoji, currentEmoji)}
                    ></Reaction>
                    <li>
                      <a onClick={onSelect}>
                        <i className="icofont-comment"></i>{t("newsfeed.post.comment")}
                      </a>
                    </li>
                    {!post?.groupId && <Share post={post}></Share>}
                  </ul>
                </div>
              )}
            </>
            :
            <div>
              <h5>This post is not available</h5>
            </div>
        }
        {/* <div className="post-comment">
          <ul className="comment-list">
            {comments.map((ele) => (
              <li key={ele.id} className="main-comments">
                <div className="each-comment">
                  <div className="each-comment-children">
                    <div className="media">
                      <div className="user-img">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "/public/avatar-view/" +
                            ele.user?.imgPath
                          }
                          width={30}
                        />
                      </div>
                      <div className="media-body">
                        <div>
                          <div className="user-title">
                            <NavLink to={"/profile/" + ele.user?.userUid}>
                              {ele.user?.fullName}
                            </NavLink>
                          </div>
                          <ul className="entry-meta">
                            <div className="post-body">
                              <div className="react-icon">
                                <img
                                  src="media/figure/reaction_1.png"
                                  alt="icon"
                                />
                                <img
                                  src="media/figure/reaction_2.png"
                                  alt="icon"
                                />
                              </div>
                              <p>{ele.content}</p>
                            </div>
                          </ul>
                        </div>
                        <div className="post-footer">
                          <ul>
                            <Reaction comment={ele}></Reaction>
                            <li className="meta-time">
                              <span>
                                <AppTimeAgo
                                  date={new Date(ele.createdDate)}
                                ></AppTimeAgo>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="post-comment" style={{ borderTop: 'unset' }}>
          <div className="comment-reply">
            <div className="user-img">
              <img src="media/figure/chat_15.jpg" alt="Aahat" />
            </div>
            <div className="input-box">
              <input
                {...register("content", { required: true })}
                type="text"
                name="content"
                className="form-control"
                placeholder="Your Reply...."
                onKeyUp={handleSubmitComment}
              />
            </div>
          </div>
        </div> */}
      </div>
      }
    </>
  );
}
