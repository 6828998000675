import React, { useEffect, useState } from 'react'
import { ReviewerService } from '../../../services/reviewer/ReviewerService'
import AppModal from '../../commons/ui/Modal/AppModal'
import PostDetail from '../Newsfeed/components/PostDetail/PostDetail';
import { PostService } from '../../../services/post/PostService';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../hooks/hook';
import { showAndHideSpinner } from '../../../redux/reducers/spinnerSlice';
import { PaginationConstant } from '../../../constants/PaginationConstant';
import { useTranslation } from 'react-i18next';

export default function MediaTab(props: any) {

  const { t, i18n } = useTranslation();
  const [lstMedia, setLstMedia] = useState<any[]>([])
  const [isOpenPostDetailPopup, setIsOpenPostDetailPopup] = useState(false);
  const [postDetail, setPostDetail] = useState<any>({});
  const dispatch = useAppDispatch();
  const [searchImageRequest, setSearchImageRequest] = useState<any>({
    page: 0,
    postSize: PaginationConstant.PAGE_SUGGEST_GROUP_SIZE,
    type: props.type
  });
  const [totalElement, setTotalElement] = useState(0)



  useEffect(() => {
    setSearchImageRequest({
      ...searchImageRequest,
      userUid: props.userInfo.userUid
    })
  }, [props.userInfo.userUid]);

  useEffect(() => {
    if (searchImageRequest.userUid) {
      _getData()
    }
  }, [searchImageRequest])



  function _getData() {
    dispatch(showAndHideSpinner(true))
    ReviewerService.getInstance().getMedia(searchImageRequest).then(response => {
      if (response.data.status) {
        let lstTmp = lstMedia.concat(response.data.responseData.lstMedia)
        setLstMedia(lstTmp)
        setTotalElement(response.data.responseData.totalElement)
        dispatch(showAndHideSpinner(false))
      }
    }).catch((error: any) => {
      console.log(error);
      dispatch(showAndHideSpinner(false))
    })
  }
  const showPostDetail = (postId: any) => {
    PostService.getInstance().getPostDetail(postId).then((response: any) => {
      if (response.data.status) {
        setPostDetail({
          ...response.data.responseData,
          user: props.userInfo
        })
        setIsOpenPostDetailPopup(true)
      }

    }).catch((error: any) => {
      console.log(error);
      toast.error(t("toast.serverError"))
    })
  }
  const onClosePostViewPopup = (data: any) => {
    setIsOpenPostDetailPopup(false)
  }

  const loadMoreImg = () => {
    setSearchImageRequest({
      ...searchImageRequest,
      page: searchImageRequest.page + 1
    });
  }
  return (
    <>
      <AppModal
        open={isOpenPostDetailPopup}
        children={<PostDetail postId={postDetail.id} />}
        onClose={onClosePostViewPopup}
      ></AppModal>
      <div className="row gutters-20 zoom-gallery">
        {
          lstMedia && lstMedia.length > 0 ?
            <>
              {
                lstMedia.map((media: any, index: any) => (
                  <div className="col-lg-3 col-md-4 col-6 mb-3" key={index} onClick={() => { showPostDetail(media.postId) }} style={{ height: "250px" }}>
                    <div className="user-group-photo"  style={{ width: "100%", height: "100%" }}>
                      <a className="popup-zoom"  style={{ width: "100%", height: "100%" }}>
                        <img src={
                          process.env.REACT_APP_API_URL +
                          "/public/image-view/" + media.attachment.atchFleSeq} 
                          onError={(event: any) => {
                            event.target.src = require("../../../assets/placeholder.jpg")
                        }}
                          alt="Image" 
                          style={{ objectFit: "cover", width: "100%", height: "100%" }}/>
                      </a>
                    </div>
                  </div>

                ))
              }
            </>
            :
            <>
              <div className="col-lg-12">
                <h5 className='text-center'>{t("profile.no-data")}</h5>
              </div>
            </>
        }



      </div>
      { lstMedia.length < totalElement && <div className="block-box load-more-btn mt-4">
        <a className="item-btn" onClick={loadMoreImg}>
          <i className="icofont-refresh"></i>{t("common.button.loadmore")}
        </a>
      </div>}
    </>

  )
}
