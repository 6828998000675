import { Suspense, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import './App.css';
import './assets/dependencies/icofont/icofont.min.css'
import 'react-multi-carousel/lib/styles.css';
import 'react-quill/dist/quill.snow.css';

import { ToastContainer } from 'react-toastify';
import Spinner from './components/commons/ui/Spinner/Spinner';

import TimeAgo from "javascript-time-ago";

import vi from "javascript-time-ago/locale/vi.json";
import en from "javascript-time-ago/locale/en.json";
import kr from "javascript-time-ago/locale/ko.json";

import "./config/interceptor/interceptor";

import { indexRoutes } from './routes/indexRoutes';
import Cookies from 'universal-cookie';
import { StatisticVisitsService } from './services/common/StatisticVisitsService';

function App() {

  // const routes = useRoutes([
  //   { path: "/", element: <Navigate to="/newsfeed" replace /> },
  //   { path: "error-500", element: <Error500/> },
  //   { path: "*", element: <Error404/> },
  //   layoutRoutes,
  // ]);
  const routes = useRoutes(indexRoutes);
  const cookie = new Cookies()
  useEffect(() => {
    TimeAgo.addLocale(vi);
    TimeAgo.addLocale(en);
    TimeAgo.addLocale(kr);
    if(!cookie.get("isVisited")){
      cookie.set("isVisited", true)
      StatisticVisitsService.getInstance().addCount().then((result:any) =>{
      }).catch((error:any) =>{
        // console.log(error);
      });
    }
  }, [])

  const handleTabClose = (event:any) => {
    event.preventDefault();
    cookie.remove("isVisited")
    console.log('beforeunload event triggered');

    // return (event.returnValue =
    //   'Are you sure you want to exit?');
  };

  return (
    <>
      <ToastContainer icon={false} position="bottom-left" theme="light" hideProgressBar={true} autoClose={3000} pauseOnHover={false}></ToastContainer>
      
      <Suspense fallback={<Spinner/>}>
        {
          routes
        }
      </Suspense>
    </>
    
  );
}

export default App;
