import { useEffect, useState } from 'react'
import { SettingService } from '../../../../services/setting/SettingService'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hook';
import TwoFAEnableModal from './TwoFAEnableModal';
import { showAndHideSpinner } from '../../../../redux/reducers/spinnerSlice';
import { useTranslation } from 'react-i18next';

export default function VerifyPasswordModal(props: any) {

    const { t } = useTranslation();
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);
    const [otpAuth, setOtpAuth] = useState<string>("");
    const [secretKey, setSecretKey] = useState<string>("");
    const [password, setPassword] = useState("")
    const [isEnableTwoFaModal, setIsEnableTwoFaModal] = useState(false)
    const dispatch = useAppDispatch()

    const genOtpAuth = () => {
        const value =
            "otpauth://totp/" +
            userInfo.userUid +
            "?secret=" +
            secretKey +
            "&issuer=" +
            userInfo.fullName;
        setOtpAuth(value);
    };

    useEffect(() => {
        genOtpAuth();
    }, [secretKey]);

    function _generateSecretKey() {
        dispatch(showAndHideSpinner(true))
        SettingService.getInstance().generateSecretKey().then((result: any) => {
            dispatch(showAndHideSpinner(false))
            if (result.data.status) {
                setSecretKey(result.data.responseData);
            } else {
                toast.error(t("setting.message.secret-err"));
            }
        }).catch((error: any) => {
            toast.error(t("setting.message.secret-err"));
        })
    }

    const onClickClose = () => {
        props.onCloseModal(false)
    }

    const handleSubmit = () => {
        const param = {
            password: password,
        }
        SettingService.getInstance().verifyPassword(param).then((result: any) => {
            if (result.data.status) {
                setIsEnableTwoFaModal(true)
                _generateSecretKey()
            } else {
                toast.error(t("setting.message.pwd-err"))
            }
        }).catch((error: any) => {
            console.log(error);
        })
    }

    return (
        <>
            {
                !isEnableTwoFaModal ?
                    <div className='modal-content'>
                        <div className='modal-body text-center'>
                            <p className="my-2">{t("setting.label.pwd")}</p>
                            <div className="px-4">
                                <input onChange={(event: any) => setPassword(event.target.value)} className='form-control text-center' type="password" />
                            </div>

                        </div>
                        <div className="modal-footer">
                            <div className='btn-dark-c mr-4'>
                                <a className="act-click" onClick={onClickClose}>{t("common.button.cancel")}</a>

                            </div>

                            <div className='btn-light-c'>
                                <a  className="act-click" onClick={handleSubmit}>{t("common.button.save")}</a>

                            </div>
                        </div>
                    </div>
                    :
                    <TwoFAEnableModal qrData={otpAuth} faKey={secretKey} onCloseModal={onClickClose}></TwoFAEnableModal>
            }
        </>

    )
}
