import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";

export class CommentService {

    private static _commentService: CommentService

    public static getInstance(): CommentService {
        if (!CommentService._commentService) {
            CommentService._commentService = new CommentService()
        }
        return CommentService._commentService;
    }

    public save(request: any) {
        const url = process.env.REACT_APP_API_URL + "/comment/save.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    getByPostId(postId: any) {
        const url = process.env.REACT_APP_API_URL + "/comment/getByPostId.exclude?postId=" + postId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    delete(id: any) {
        const url = process.env.REACT_APP_API_URL + "/comment/delete.exclude?id=" + id;
        return axios.delete(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    
}

