import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../reducers/userSlice";
import spinnerSlice from "../reducers/spinnerSlice";
import multiLangSilce from "../reducers/multiLangSilce";
import compareSlice from "../reducers/compareSlice";
import postSlice from "../reducers/postSlice";


const store = configureStore({
    reducer: {
        userInfo: userSlice,
        language: multiLangSilce,
        spinner: spinnerSlice,
        compare: compareSlice,
        postSlice: postSlice
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ serializableCheck: false })
        // .concat(createLogger())
    }
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch