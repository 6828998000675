import React, { useEffect, useState } from 'react'
import { PostService } from '../../../services/post/PostService';
import AppModal from '../../commons/ui/Modal/AppModal';
import { toast } from 'react-toastify';
import PostDetail from '../Newsfeed/components/PostDetail/PostDetail';
import { t } from 'i18next';

export default function Photo(props: any) {
    const [photoList, setPhotoList] = useState<any[]>([]);
    const [postDetail, setPostDetail] = useState<any>({});
    const [isOpenPostDetailPopup, setIsOpenPostDetailPopup] = useState(false);
    useEffect(() => {
        getPhotoList(props.data.lstPost);
    }, [props])

    const getPhotoList = (posts: any) => {
        const photoList: any = [];
        if (posts) {
            posts.forEach((post: any) => {
                if (post.attachments) {
                    post.attachments.forEach((attachment: any) => {
                        if (attachment.atchFleSeq) {
                            photoList.push({
                                url: process.env.REACT_APP_API_URL + "/public/image-view/" + attachment.atchFleSeq,
                                id: post.id
                            });
                        }
                    });
                }
            });
            setPhotoList(photoList);
        }
    }
    const viewPostDetail = (postId: any) => {
        PostService.getInstance().getPostDetail(postId).then((response: any) => {
            if (response.data.status) {
                setPostDetail({
                    ...response.data.responseData,
                    // user: props.userInfo
                })
                setIsOpenPostDetailPopup(true)
            }

        }).catch((error: any) => {
            // console.log("Server error: " + error);
            toast.error(t("toast.serverError"))
        })
    }
    const onClosePostViewPopup = (data: any) => {
        setIsOpenPostDetailPopup(false)
      }
    return (
        <>
            <AppModal
                open={isOpenPostDetailPopup}
                children={<PostDetail postId={postDetail.id} />}
                onClose={onClosePostViewPopup}
            ></AppModal>
            <div className="row gutters-20 zoom-gallery">
                {photoList && photoList.map((image: any, index: number) => (
                    <div key={index} className="col-lg-3 col-md-4 col-6 mb-3" style={{ height: "250px" }}>
                        <div className="user-group-photo" style={{ width: "100%", height: "100%" }}>
                            <a onClick={() => viewPostDetail(image.id)} className="popup-zoom" style={{ width: "100%", height: "100%" }}>
                                <img style={{ objectFit: "cover", width: "100%", height: "100%" }} src={image.url} alt="Gallery" onError={(event: any) => {
                                    event.target.src = require("../../../assets/placeholder.jpg")
                                }} />
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className="load-more-post">
                <a href="#" className="item-btn"><i className="icofont-refresh"></i>Load More Posts</a>
            </div> */}
        </>
    )
}
