import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";

export class PostService {

    private static _postService: PostService

    public static getInstance(): PostService {
        if (!PostService._postService) {
            PostService._postService = new PostService()
        }
        return PostService._postService;
    }

    public save(request: any) {
        const url = process.env.REACT_APP_API_URL + "/post/save.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    getAll() {
        const url = process.env.REACT_APP_API_URL + "/post/getAll.exclude";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    search(request: any) {
        const url = process.env.REACT_APP_API_URL + "/post/recommendPost.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    trending(request: any) {
        const url = process.env.REACT_APP_API_URL + "/public/trendingPost";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    following(request: any) {
        const url = process.env.REACT_APP_API_URL + "/post/following.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    filterTrending(request: any) {
        const url = process.env.REACT_APP_API_URL + "/public/filterTrending";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    discovery(request: any) {
        const url = process.env.REACT_APP_API_URL + "/post/discoveryPost.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    searchPublic(request: any) {
        const url = process.env.REACT_APP_API_URL + "/post/search-public.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    delete(postId: any) {
        const url = process.env.REACT_APP_API_URL + "/post/delete.exclude?postId=" + postId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    
    public getPostDetail(postId:any){
        const url = process.env.REACT_APP_API_URL + "/post/getPostDetail.exclude?postId=" +  postId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth(),
      });
    }

    sharePost(param: any) {
        const url = process.env.REACT_APP_API_URL + "/post/share-post.exclude";
        return axios.post(url, param, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    public getPostShared(postId:any){
        const url = process.env.REACT_APP_API_URL + "/post/get-shared-post.exclude?postId=" +  postId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth(),
      });
    }

    getPostLastest(param: any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(param);
        const url = ApiUrlUtil.buildQueryString(
          process.env.REACT_APP_API_URL + "/public/getPostLastest.exclude",
          params
        );
        return axios.get(url, {
          headers: HeadersUtil.getHeadersAuth(),
        });
      }
    
      public getFollowingPost(param: any){
        const params: RequestParam[] = ParamUtil.toRequestParams(param);
        const url = ApiUrlUtil.buildQueryString(
          process.env.REACT_APP_API_URL + "/post/get-following-post.exclude",
          params
        );
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth(),
      });
    }
}

