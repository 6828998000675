import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";

export class HashtagService {

    private static _hashtagService: HashtagService

    public static getInstance(): HashtagService {
        if (!HashtagService._hashtagService) {
            HashtagService._hashtagService = new HashtagService()
        }
        return HashtagService._hashtagService;
    }

    public getAll() {
        const url = process.env.REACT_APP_API_URL + "/hashtag/getAll";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }    
    
}

