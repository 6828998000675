import { Navigate } from 'react-router-dom';
import Error500 from '../components/pages/Errors/Error500';
import Error404 from '../components/pages/Errors/Error404';
import { layoutRoutes } from './layoutRoutes';
import HomePage from '../components/pages/Public/HomePage';
import { AuthUtil } from '../utils/AuthUtil';

export const indexRoutes = [
  { path: "/", element: AuthUtil.isLogin() ? <Navigate to="/newsfeed" replace /> : <HomePage/>},
  // { path: "public", element:  <HomePage/>},
  { path: "error-500", element: <Error500 /> },
  { path: "*", element: <Error404 /> },
  layoutRoutes,
];
