import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";

export class ReactionService {

    private static _reactionService: ReactionService

    public static getInstance(): ReactionService {
        if (!ReactionService._reactionService) {
            ReactionService._reactionService = new ReactionService()
        }
        return ReactionService._reactionService;
    }

    save(request: any) {
        const url = process.env.REACT_APP_API_URL + "/reaction/save.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    delete(param:any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(param);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/reaction/delete.exclude", params);    
        return axios.delete(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    
}

