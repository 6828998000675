import Carousel from "react-multi-carousel";

type PropsPostImage = {
    attachments: any[];
    detail: boolean;
}

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

export default function PostImage(props: PropsPostImage) {
    return (
        <>  {props && props.attachments && (<>
            {
                !props.detail ? <div className="post-img row" style={{ margin: "unset" }}>
                    <img className="img-thumbnail" src={
                        process.env.REACT_APP_API_URL +
                        "/public/image-view/" +
                        props?.attachments[0]?.atchFleSeq
                    }
                        onError={(event: any) => {
                            event.target.src = require("../../../assets/placeholder.jpg")
                        }} />

                </div> :
                    <div className="post-img row" style={{ margin: 'unset' }}>
                        <Carousel responsive={responsive}>
                            {/* {props.attachments.map((ele: any, idx: any) => (
                            <div key={idx} className="col-lg-12">
                                <img src={process.env.REACT_APP_API_URL + "/public/image-view/" + ele.atchFleSeq} onError={(event: any) => {
                                    event.target.src = require("../../../assets/dependencies/icofont/placeholder.jpg")
                                }} />
                            </div>
                            ))} */}
                            <div>Item 1</div>
                            <div>Item 2</div>
                            <div>Item 3</div>
                            <div>Item 4</div>
                            <div>Item 5</div>

                        </Carousel>
                        {/* {props.attachments.map((ele: any, idx: any) => (
                            idx < 4 &&
                            <div key={idx} className={(props.attachments.length === 3 && idx === 2) ? 'col-lg-12' : 'col-lg-6'} style={{ padding: '1px', position: idx == 3 ? 'relative' : 'unset' }}>
                                <img src={process.env.REACT_APP_API_URL + "/public/image-view/" + ele.atchFleSeq} onError={(event: any) => {
                                    event.target.src = require("../../../assets/dependencies/icofont/placeholder.jpg")
                                }} />
                                {
                                    idx === 3 &&
                                    <div className="overlay-image">
                                        <span className="text">+{props.attachments.length - 4}</span>
                                    </div>
                                }
                            </div>
                        ))} */}
                    </div>
            }

        </>
        )
        }
        </>
    )
}
