import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { AuthConstant } from "../constants/authConstant";

export default function AuthGuard(props: any) {
    const cookie = new Cookies()
    const navigate = useNavigate()
    const [auth, setAuth] = useState(false)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _token = params.get(AuthConstant.ACCESS_TOKEN);

    if (_token) {
        const expires = new Date();
        expires.setDate(expires.getDate() + AuthConstant.EXPIRES_TOKEN)
        cookie.remove(AuthConstant.ACCESS_TOKEN)
        cookie.remove(AuthConstant.PUBLIC_KEY)
        cookie.set(AuthConstant.ACCESS_TOKEN, _token, { path: '/', expires: expires })
        window.location.href = "/newsfeed"
    }

    useEffect(() => {
        if (cookie.get(AuthConstant.ACCESS_TOKEN) !== undefined && cookie.get(AuthConstant.ACCESS_TOKEN) !== '') {
            setAuth(true)
        } else {
            navigate("/")
        }
    }, [navigate])

    return (
        auth && props.children
    )
}