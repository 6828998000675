export class TranslationConstant {
//   public static readonly LANGUAGE = "lang";
  public static readonly ENGLISH = "en";
  public static readonly SOUTH_KOREA = "kr";
  public static readonly VIETNAMESE = "vi";
  public static readonly STORAGE_KEY = "lang";

  public static readonly LANGUAGES: LanguageItem[] = [
    {
      key: "vi",
      name: "Tiếng Việt",
      icon: "/vietnam.png",
    },
    {
      key: "en",
      name: "English",
      icon: "/kingdom.png",
    },
    {
      key: "kr",
      name: "한국어",
      icon: "/south-korea.png",
    },
  ];
}

export class LanguageItem {
  key?: string;
  name?: string;
  icon?: string;
}
