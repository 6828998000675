import { useState } from "react";
import PrivacyTab from "./PrivacyTab";
import LanguageTab from "./LanguageTab";
import { useTranslation } from "react-i18next";

export default function Setting() {
  const [tabSelected, setTabSelected] = useState<any>(0)
  const { t } = useTranslation();


  return (
    <div className="page-content object-page-content">
      <div className="container">
        <h3 className="heading-title">{t("setting.text.title")}</h3>
        <div className="setting-container">
        <div className="user-top-header p-0">
              <ul className="menu-list">
                <li className={tabSelected === 0 ? 'active' : ''} onClick={() => { setTabSelected(0) }}><a>{t("setting.nav.secur")}</a></li>
                <li className={tabSelected === 1 ? 'active' : ''} onClick={() => { setTabSelected(1) }}><a>{t("setting.nav.lang")}</a></li>
              </ul>
            </div>
        </div>
        {tabSelected === 0 && <PrivacyTab />}
        {tabSelected === 1 && <LanguageTab />}
      </div>
    </div>
  );
}
