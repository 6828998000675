import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: 0,
};

const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    refreshCompare: (state, action) => {
      state.refresh = action.payload;
    },
  },
});

export default compareSlice.reducer;
export const { refreshCompare } = compareSlice.actions;
