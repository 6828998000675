import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";

export class NotificationService {

    private static _notificationService: NotificationService

    public static getInstance(): NotificationService {
        if (!NotificationService._notificationService) {
            NotificationService._notificationService = new NotificationService()
        }
        return NotificationService._notificationService;
    }

    getNotification(request: any) {
        const url = process.env.REACT_APP_API_URL + "/notification/getByUser";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    deleteByNotiId(notiId: any) {
        const url = process.env.REACT_APP_API_URL + "/notification/deleteByNotiId?notiId=" + notiId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    deleteAllByUser() {
        const url = process.env.REACT_APP_API_URL + "/notification/deleteAllByUser";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    readByNotiId(notiId: any) {
        const url = process.env.REACT_APP_API_URL + "/notification/readByNotiId?notiId=" + notiId;
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    readAllByUser() {
        const url = process.env.REACT_APP_API_URL + "/notification/readAllByUser";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    
}

