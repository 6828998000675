export class CompareService {
    public static getObject(): any[] {
        const objects = localStorage.getItem("compare");
        if (objects) {
            return JSON.parse(objects);
        }
        return [];
    }
    public static addObject(object: any): any[] {
        const objects = this.getObject();
        if (!objects.find((item) => item === object)) {
            const newobjects = [...objects, object];
            localStorage.setItem(
                "compare",
                JSON.stringify(newobjects)
            );
            return newobjects;
        }
        return objects;
    }

    public static removeObject(object: any): any[] {
        const objects = this.getObject();
        const newobjects = objects.filter(
            (item) => item !== object
        );
        localStorage.setItem(
            "compare",
            JSON.stringify(newobjects)
        );
        return newobjects;
    }

    public static clearObjects(): void {
        localStorage.removeItem("compare");
    }
}