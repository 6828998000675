import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";

export class FileService {
  private static _fileService: FileService;

  public static getInstance(): FileService {
    if (!FileService._fileService) {
      FileService._fileService = new FileService();
    }
    return FileService._fileService;
  }

  public uploadFile(request: any) {
    const formData = new FormData();
    formData.append("multipartFile", request);

    const url = process.env.REACT_APP_API_URL + "/commonFile/upload.exclude";
    return axios.post(url, formData, {
      headers: HeadersUtil.getHeadersAuthFormData(),
    });
  }

  public uploadMulFile(request: any[]) {
    const formData = new FormData();
    for (let idx = 0; idx < request.length; idx++) {
      formData.append("multipartFileList", request[idx]);
    }

    const url = process.env.REACT_APP_API_URL + "/commonFile/mulUpload.exclude";
    return axios.post(url, formData, {
      headers: HeadersUtil.getHeadersAuthFormData(),
    });
  }
}
