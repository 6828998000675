import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { langSelector } from '../../../../redux/reducers/multiLangSilce';
import { TranslationConstant } from '../../../../constants/TranslationConstant';

type DateFormatProps = {
    date: any
}

export default function DateFormat(props: DateFormatProps) {

    const lang = useSelector(langSelector);
    const [pattern, setPattern] = useState("YYYY-MM-DD");

    useEffect(() => {
        if (lang === TranslationConstant.ENGLISH) {
            setPattern("YYYY-MM-DD")
        } else if (lang === TranslationConstant.VIETNAMESE) {
            setPattern("DD-MM-YYYY")
        } else {
            setPattern("YYYY-MM-DD")
        }
    }, [lang])



    return (
      <>
        {props.date && dayjs(props.date).format(pattern)}
      </>
    );
  }
  