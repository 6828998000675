import React, { ReactElement, useEffect, useRef, useState } from 'react'

type SearchSelectProps = {
    label: string;
    placeholder: string;
    timeout?: number;
    options: any[];
    onInputChange: (keySearch?: string) => void;
    formatOptionLabel: (data: any) => ReactElement;
    onSelectOption: (selected:any) => void;
}

export default function SearchSelectComp(props: SearchSelectProps) {

    const [keySearch, setKeySearch] = useState<any>("")
    const [options, setOptions] = useState<any[]>(props.options)
    const [isShowOptionBar, setIsShowOptionBar] = useState<boolean>(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            props.onInputChange(keySearch)
        }, props.timeout);
        return () => {
            clearTimeout(timeout);
        };
    }, [keySearch])


    useEffect(() => {
        setOptions(props.options)
    }, [props.options])


    const optionLabel:any = props.formatOptionLabel

    const handleFocus = () => {
        setIsShowOptionBar(true)
    };

    const handleSelectOption = (item: any) => {
        props.onSelectOption(item)
        setIsShowOptionBar(false);
        setKeySearch("")
    }

    const divRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event:MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsShowOptionBar(false);
        setKeySearch("")
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

    return (
        <div >
            {props?.label&&<label>{props?.label}</label>}
            <div className="form-group" ref={divRef}>
                <input type="text" className="form-control" value={keySearch} placeholder={props?.placeholder} name='keySearch' onFocus={handleFocus} onChange={(event: any) => { setKeySearch(event.target.value) }} />
                { isShowOptionBar && <div className="option-bar" >
                    <div className="lst-option">
                        { options?.length > 0?
                            <>
                            {options && options.map((item: any, index:any) =>
                                <div className="option" onClick={()=>handleSelectOption(item)} key={index}>
                                    {optionLabel(item)}
                                </div>
                            )}
                            </>
                            :
                            <div className='text-center'>No result found</div>  
                        }
                    </div>

                </div>}
            </div>
        </div>
    )
}
