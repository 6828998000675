import { useState } from 'react';

type PropsRating = {
  onChange?: (data?: any) => void;
  defaultValue?: any;
  disabled?: boolean;
  required?: boolean;
  onError?: (msg: any) => void;
}

export default function RatingForm(props: PropsRating) {
  const arrRate = [1, 2, 3, 4, 5];
  const [rate, setRate] = useState(props.defaultValue ? props.defaultValue : 0);

  const handleRating = (data: any) => {
    setRate(data);
    props.onChange?.(data);
  }

  return (
    <>
      <div className="eval_item" style={{whiteSpace: 'nowrap'}}>
        {
          arrRate.map((ele) => (
            rate >= ele
              ?
              <span key={ele}>
                &nbsp;
                <img
                  src="/media/star-2.png"
                  alt="start"
                  width={15}
                  onClick={() => !props.disabled && handleRating(ele)}
                />
              </span>
              :
              <span key={ele}>
                &nbsp;
                <img
                  src="/media/star-1.png"
                  alt="start"
                  width={15}
                  onClick={() => !props.disabled && handleRating(ele)}
                />
              </span>
          ))
        }

        {/* {
          (props.required && rate > 0 ) && <span>adsadsadd</span>
        } */}
        
      </div>
        
    </>
  );
}