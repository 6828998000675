export class CommonConstant {
  public static readonly FOLLOW_TYPE_FOLLOWER = "follower";
  public static readonly FOLLOW_TYPE_FOLLOWING = "following";

  public static readonly REACTION_TYPE = "01";

  public static readonly ROWS_OF_PAGE = 10;

  public static readonly MEDIA_OF_PAGE = 10;

  public static readonly BUBBLE_TYPE = 1;
  public static readonly BUBBLE_OBJECT = 2;
  public static readonly BUBBLE_HASHTAG = 3;

  public static readonly MEDIA_TYPE_IMAGE = "image";
  public static readonly MEDIA_TYPE_VIDEO = "video";

  public static readonly HOVER_CARD_OBJECT = 1;
  public static readonly HOVER_CARD_USER = 2;
  public static readonly HOVER_CARD_GROUP = 3;
  public static readonly LIMIT_COMPARE = 3;

  public static readonly MESSAGE_REVIEW_SUCCESS = "001";
  public static readonly MESSAGE_REVIEW_FAILED = "002";
  
  public static readonly ACTION_TYPE_SCROLL = "scroll";
  public static readonly ACTION_TYPE_LIKE = "like";
  public static readonly ACTION_TYPE_VIEW = "view";
  public static readonly ACTION_TYPE_COMMENT = "comment";
  public static readonly ACTION_TYPE_SEARCH = "search";

  public static readonly ELASTICSEARCH_TYPE = {
    USER: "USER",
    POST: "POST",
    OBJECT: "OBJECT",
    GROUP: "GROUP",
    ALL: "ALL",
  };

  public static readonly PERIODS = [
    { name: "Any Time", value: null },
    { name: "1 day ago", value: "1" },
    { name: "3 day ago", value: "3" },
    { name: "7 day ago", value: "7" },
    { name: "14 day ago", value: "14" },
    { name: "1 month ago", value: "30" },
  ];

  public static readonly POSTS_FROM = [
    { name: "Anyone", value: null },
    { name: "You", value: "01" },
    { name: "The person you're following", value: "02" },
    { name: "People are following you", value: "03" },
  ];
}
