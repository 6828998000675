import { useEffect, useRef, useState } from "react";

import "./ChipInput.css";

type SearchSelectProps = {
  value?: any[];
  onChange: (data?: any) => void;
  isDisabled?: boolean;
  suggestions?: any[];
  bindLabel: string;
  bindValue: any;
};

export default function ChipInput(props: SearchSelectProps) {
  const wrapperRef: any = useRef(null);

  const [text, setText] = useState<any>("");
  const [chips, setChips] = useState<any[]>([]);
  const [isShowSuggestion, setIsShowSuggestion] = useState(false);
  const [suggestions, setSuggestion] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(-1);

  useEffect(() => {
    setChips(props.value || []);
  }, [props.value]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsShowSuggestion(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  // const deleteSuggestion = (idx: any) => {
  //   let temps: any[] = JSON.parse(JSON.stringify(suggestions));
  //   temps = temps.splice(idx, 1);
  //   setSuggestion(temps);
  // };

  const handlePressEnter = (e: any) => {
    if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp")
      e.preventDefault();

    if (e.key === "Enter" && text) {
      let chipsTemps: any[] = JSON.parse(JSON.stringify(chips));
      if (selected !== -1) {
        chipsTemps.push(suggestions[selected][props.bindValue]);
        // deleteSuggestion(selected)
      } else {
        chipsTemps.push(e.target.value);
      }

      setText("");
      setChips(chipsTemps);
      setIsShowSuggestion(false);
      setSelected(-1);
      props.onChange(chipsTemps);
    }

    if (e.key === "Backspace" && text.length === 0) {
      let chipsTemps: any[] = JSON.parse(JSON.stringify(chips));
      chipsTemps.pop();
      setChips(chipsTemps);
      props.onChange(chipsTemps);
    }

    if (e.key === "ArrowDown") {
      if (selected < suggestions.length - 1) {
        setSelected(selected + 1);
      }
    }

    if (e.key === "ArrowUp") {
      if (selected > 0) {
        setSelected(selected - 1);
      }
    }
  };

  const onClickSuggestion = (data: any) => {
    let chipsTemps: any[] = JSON.parse(JSON.stringify(chips));
    chipsTemps.push(data);
    setText("");
    setChips(chipsTemps);
    setIsShowSuggestion(false);
    props.onChange(chipsTemps);
  };

  const onChangeText = (event: any) => {
    setIsShowSuggestion(true);
    setText(event.target.value);
    if (event.target.value.length === 0) {
      setIsShowSuggestion(false);
    } else {
      let sggList: any = props.suggestions?.filter(
        (ele) =>
          ele[props.bindLabel]
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1 &&
          !chips.some(
            (item) => ele[props.bindLabel].toUpperCase() === item.toUpperCase()
          )
      );
      setSuggestion(sggList);
    }
  };

  return (
    <div ref={wrapperRef}>
      <div className="input-container">
        <ul className="chips">
          {chips.map((chip, idx) => (
            <li key={idx} className="chip">
              <span>{chip} </span>
            </li>
          ))}
        </ul>
        <input
          type="text"
          placeholder="Press enter to add"
          value={text}
          onChange={onChangeText}
          onKeyDown={handlePressEnter}
          disabled={props.isDisabled ? true : false}
        />
      </div>
      {isShowSuggestion && (
        <div className="option-bar">
          <div className="lst-option scrollbar">
            {suggestions.length > 0 ? (
              suggestions.map((ele, idx) => (
                <div
                  key={idx}
                  className={selected === idx ? "option selected" : "option"}
                  onClick={() => onClickSuggestion(ele[props.bindValue])}
                >
                  <div>{ele[props.bindLabel]}</div>
                </div>
              ))
            ) : (
              <div className="option">
                <div style={{ textAlign: "center" }}>Not found</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
