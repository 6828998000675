import { Outlet } from "react-router-dom";

import Header from "./Header/Header";
import SidebarLeft from "./SidebarLeft/SidebarLeft";
import SidebarRight from "./SidebarRight/SidebarRight";
import Spinner from "../commons/ui/Spinner/Spinner";
import Footer from "./Footer/Footer";

export default function Layout() {

  return (
    <>
      {/* <a href="#wrapper" data-type="section-switch" className="scrollup">
            <i className="icofont-bubble-up"></i>
        </a> */}

      {/* <div id="preloader"></div> */}

      <Spinner></Spinner>

      <div id="wrapper" className="wrapper open">
        <Header></Header>

        <SidebarLeft></SidebarLeft>

        <SidebarRight></SidebarRight>
        <Outlet />
        {/* <Footer></Footer> */}
      </div>
    </>
  );
}
