import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";

export class AuthService {

    private static _authService: AuthService

    public static getInstance(): AuthService {
        if (!AuthService._authService) {
            AuthService._authService = new AuthService()
        }
        return AuthService._authService;
    }

    logout() {
        const url = process.env.REACT_APP_AUTH_URL + "/api/auth/logout";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }    

    login(request: any, authUrl: any) {
        const url = process.env.REACT_APP_AUTH_URL + "/api/auth/login?base_url=" + authUrl;
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }

    verifyOTP(param:any){
        const url = process.env.REACT_APP_AUTH_URL + "/api/auth/2fa-verify-otp";
        return axios.post(url, param, {
            headers: HeadersUtil.getHeadersAuth()
        });
    }
    
}

