import { t } from "i18next";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LabelRequired from "../../../../commons/form/LabelRequired";
import { showAndHideSpinner } from "../../../../../redux/reducers/spinnerSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GroupClassificationService } from "../../../../../services/group/GroupClassificationService";

type AddGroupClassificationProps = {
  object?: any;
  onClose?: (data?: any) => void;
};

export default function AddGroupClassification(props: AddGroupClassificationProps) {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const onSubmitForm = (data:any) => {
    let request: any = Object.assign({}, data);

    dispatch(showAndHideSpinner(true));
    GroupClassificationService.getInstance()
      .save(request)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res:any) => {
          if (res.data.status) {
            toast.error(t("toast.success"));
            props.onClose?.(res.data.status);
          }
        },
        (error:any) => {}
      );
  };

  return (
    <>
      <div className="modal-body scrollbar" style={{ maxHeight: "70vh", overflowY: "scroll" }}>
        <div className="row">
          <div className="col-lg-12">
            <div className="forum-topic-add px-4">
              <form>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group row">
                      <LabelRequired className="col-sm-3 col-form-label">{t("group.label.classificationName")}</LabelRequired>
                      <div className="col-sm-9">
                        <Controller
                          name="classificationName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input className="form-control" {...register("classificationName", { required: true })} type="text" {...field} />
                              {errors?.classificationName && (
                                <p className="msg-error">
                                  {t("group.label.classificationName")} {t("group.error.required")}
                                </p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => props.onClose?.()}>
          {t("common.button.cancel")}
        </button>
        <button type="button" className="btn btn-info" onClick={handleSubmit(onSubmitForm)}>
          {t("common.button.save")}
        </button>
      </div>
    </>
  );
}
