import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "./SubComment.css";
import AppTimeAgo from "../../../ui/DateFormat/AppTimeAgo";
import { useForm } from "react-hook-form";
import Reaction from "../../Reaction";
import AvatarImage from "../../AvatarImage";
import { useTranslation } from "react-i18next";

type SubCommentProps = {
  commentList: any[];
  onSubmitComment: (data?: any) => void;
  currentUser: any;
  refresh?: () => void;
  moveToComment: any
};

export default function SubComment(props: SubCommentProps) {

  const [commentList, setCommentList] = useState<any[]>(props.commentList);  

  useEffect(() => {
    setCommentList(props.commentList);
  }, [props.commentList]);

  return (
    <>
      {commentList.map((ele) => (
        <CommentView key={ele.id} comment={ele} onSubmitComment={(data) => props.onSubmitComment(data)} currentUser={props.currentUser} moveToComment={props.moveToComment}/>
      ))}
    </>
  );
}

type CommentViewProps = {
  comment: any,
  onSubmitComment: (data: any) => void,
  currentUser: any,
  moveToComment: any
};

export function CommentView(props: CommentViewProps) {

  const { t, i18n } = useTranslation();

  const { register, getValues, setValue } = useForm();

  const [comment, setComment] = useState<any>({});
  const [isShowInputReply, setIsShowInputReply] = useState(false);
  const [cmtIdReply, setCmtIdReply] = useState<String>();
  const [isShowReply, setIsShowReply] = useState<any>(false);
  const [moveToComment, setMoveToComment] = useState(props.moveToComment);

  useEffect(() => {
    setComment(props.comment)
  }, [props.comment]);

  useEffect(() => {
    // scrollToComment(props.moveToComment);
    setMoveToComment(props.moveToComment);
  }, [props.moveToComment]);

  const scrollToComment = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  }

  const onClickReply = (cmtId: string) => {
    setIsShowInputReply(true);
    setCmtIdReply(cmtId);
    setMoveToComment(cmtId);
    scrollToComment(cmtId)
  };

  const handleSubmitComment = (event: any) => {
    if (event.key !== "Enter") {
      return;
    }

    let content = getValues("content");
    if (!content) {
      return;
    }

    let request = {
      upCommentId: cmtIdReply,
      content: content,
      attachments: null,
    };

    props.onSubmitComment(request);
    setValue("content", "");
    setCmtIdReply("");
    setIsShowInputReply(true);
  };

  const showReply = () => {
    setIsShowReply(true);
  };

  // const handleDelete = (id: any) => {
  //   CommentService.getInstance().delete(id).then((res: any) => {
  //     if (res.data.status) {
  //     }
  //   });
  // }

  return (
    <>
      <li className="main-comments" id={comment.id}>
          <div className="each-comment">
            <div className="each-comment-children"  onLoad={() => scrollToComment(moveToComment)}>
              <div className="media">
                <div
                  className="user-img"
                  style={{ width: "50px", height: "50px" }}
                >
                  <AvatarImage atchFleSeq={comment.user?.imgPath}></AvatarImage>
                </div>
                <div className="media-body">
                  <div>
                    <div className="user-title">
                      <NavLink to={"/profile/" + comment.user?.userUid}>
                        {comment.user?.fullName}
                      </NavLink>
                    </div>
                    <ul className="entry-meta">
                      <div className="post-body">
                        {comment.reactionDetail && (
                          <div
                            className="react-icon react-icon-active"
                            id="comment-emoji"
                          >
                            {comment.reactionDetail?.emojis.map(
                              (ele: any, idx: any) =>
                                idx <= 1 && (
                                  <img
                                    key={idx}
                                    src={ele.valueconfig}
                                    alt="icon"
                                  />
                                )
                            )}
                            {comment.reactionDetail?.totalEmoji > 1 && (
                              <span style={{ marginLeft: "10px" }}>
                                {comment.reactionDetail?.totalEmoji}
                              </span>
                            )}
                          </div>
                        )}

                        <p>{comment.content}</p>
                      </div>
                    </ul>
                  </div>
                  <div className="post-footer">
                    <ul>
                      <Reaction
                        comment={comment}
                        onIncrease={(data) => {
                          let emoji = {
                            commcd: data.commCd,
                            valueconfig: data.valueConfig,
                            commnm: data.commNm,
                            commnmen: data.commNmEn,
                            commnmvi: data.commNmVi
                          }
                          let commentTemp = Object.assign({}, comment);
                          commentTemp.reactionDetail.totalEmoji +=1;                
                          let emojis: any[] = commentTemp.reactionDetail.emojis || [];        
                          emojis.push(emoji);
                          commentTemp.reactionDetail.emojis = emojis;
                          
                          setComment(commentTemp);
                        }}
                        onDecrease={(data) => {
                          let commentTemp = Object.assign({}, comment);
                          commentTemp.reactionDetail.totalEmoji -=1;
                          commentTemp.reactionDetail.emojis.forEach((item: any) => {
                            if (item.commcd === data.commCd) {
                              item.total -= 1;
                            }
                          });

                          let emojis: any[] = commentTemp.reactionDetail.emojis || [];
                          emojis = emojis.filter((ele: any) => ele.total > 0) ;

                          commentTemp.reactionDetail.emojis = emojis;

                          setComment(commentTemp);
                        }}
                      ></Reaction>

                      {props.currentUser.userUid !== comment.user?.userUid && (
                        <li>
                          <a onClick={() => onClickReply(comment.id)}>
                            <i className="icofont-reply"></i>{t("newsfeed.comment.reply")}
                          </a>
                        </li>
                      )}

                      <li className="meta-time">
                        <span>
                          <AppTimeAgo
                            date={comment.createdDate}
                          ></AppTimeAgo>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* {props.currentUser.userUid === ele.user.userUid && (
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    ...
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="#">
                      Close
                    </a>
                    <a className="dropdown-item" href="#">
                      Edit
                    </a>
                    <a className="dropdown-item" href="#">
                      Delete
                    </a>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {comment.children?.length > 0 && (
            <>
              <ul className="children">
                {!isShowReply && (
                  <div className="post-footer">
                    <ul>
                      <li>
                        <a onClick={() => showReply()}>
                          <img
                            width={20}
                            src="/media/icon-reply.png"
                            style={{ marginBottom: "10px" }}
                          />
                          <span>{comment.children?.length} {t("newsfeed.comment.reply")}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                {isShowReply && (
                  <SubComment
                    commentList={comment.children}
                    onSubmitComment={(data) => props.onSubmitComment(data)}
                    currentUser={props.currentUser}
                    moveToComment={moveToComment}
                  ></SubComment>
                )}
              </ul>
            </>
          )}
          <ul className="children" id = {"INPUT_REPLY-" + comment.id}>
            {isShowInputReply && cmtIdReply === comment.id && (
              <li>
                <div className="comment-reply" style={{ padding: "10px 0px" }} onLoad ={() => scrollToComment("INPUT_REPLY-" + comment.id)}>
                  <div
                    className="user-img"
                    style={{ width: "45px", height: "45px" }}
                  >
                    <AvatarImage atchFleSeq={comment.user?.imgPath}></AvatarImage>
                  </div>
                  <div className="input-box" >
                    <input
                      {...register("content", { required: true })}
                      type="text"
                      name="content"
                      className="form-control"
                      placeholder={t("newsfeed.comment.inputPlaceholder")}
                      style={{ height: "38px" }}
                      onKeyUp={handleSubmitComment}
                    />
                  </div>
                </div>
              </li>
            )}
          </ul>
        </li>
    </>
  )
}
