import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { FileService } from "../../../services/common/FileService";
import { t } from "i18next";
type ModalAvatarEditorProps = {
    onClose: (data?: any) => void;
    image: any,
    rotate: any,
    borderRadius: any,
    preview?: any,
    width: any,
    height: any
    border: any;
};

export default function AppAvatarEditor(props: ModalAvatarEditorProps) {
    const [position, setposition] = useState<any>({ x: 0.5, y: 0.5 });
    const [scale, setScale] = useState<any>(1);
    const [fileAvatar, setFileAvatar] = useState<File>();
    const fileAvatarInputRef = useRef<any>();
    const [previewUrl, setPreviewUrl] = useState<any>();
    const [preview, setPreview] = useState<any>();
    const editor = React.createRef<AvatarEditor>()
    const state = {
        image: props.image,
        rotate: props.rotate,
        borderRadius: props.borderRadius,
        preview: props.preview,
        width: props.width,
        height: props.height,
        border: props.border
    };
    const handlePositionChange = (position: any) => {
        setposition(position)
    };
    const handleScale = (e: any) => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };
    const handleImageUpload = (event: any) => {
        const file = event.target.files[0];
        setFileAvatar(event.target.files[0]);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleButtonClick = () => {
        fileAvatarInputRef.current.click();
    };
    const saveFile = async () => {
        let data: any = {};
        if (!previewUrl) {
            data.atchFleSeq = state.image;
        } else {
            data.atchFleSeq = editor.current?.getImage().toDataURL();
            const res = await fetch(data.atchFleSeq);
            const blob = await res.blob();
            let newFile = new File([blob], "pictureName", { type: "image/jpeg" });
            if (newFile) {
                await FileService.getInstance()
                    .uploadFile(newFile)
                    .then((res) => {
                        if (res.data.status) {
                            data.atchFleSeq = res.data.responseData.atchFleSeq;
                        }
                    });
            }
        }
        // const rect = editor.current?.getCroppingRect();
        // if (rect) {
        //     data.positionX = rect.x / (1.001 - rect.width);
        //     data.positionY = rect.y / (1.001 - rect.height);
        // } else {
        //     data.positionX = 0;
        //     data.positionY = 0;
        // }
        props.onClose(data);
    }

    return (
        <>
            <div style={{ padding: "20px" }}>
                <div>
                    <AvatarEditor
                        ref={editor}
                        scale={scale}
                        width={state.width}
                        height={state.height}
                        position={position}
                        rotate={parseFloat(state.rotate)}
                        borderRadius={state.width / (100 / state.borderRadius)}
                        image={previewUrl ? previewUrl : process.env.REACT_APP_API_URL +
                            "/public/image-view/" + state.image}
                        border={state.border}
                        onPositionChange={handlePositionChange}
                    />
                </div>
                <div>
                    <input
                        name="scale"
                        type="range"
                        onChange={handleScale}
                        min="1"
                        max="3"
                        step="0.01"
                        defaultValue="1"
                    />
                </div>
                <div className="submit-btn" style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                    <input type="file" ref={fileAvatarInputRef} style={{ display: 'none' }} onChange={handleImageUpload} />
                    <a onClick={handleButtonClick}>
                        <i className="fa fa-camera" style={{ marginRight: "5px" }}></i>{t("object.button.upload.avatar")}
                    </a>
                    <a onClick={saveFile}>{t("common.button.save")}</a>
                </div>
                {/* {preview && (
                    <>
                        <img
                            alt=""
                            src={preview}
                        />
                    </>)} */}
            </div>
        </>
    );
}
