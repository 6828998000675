import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GroupService } from "../../../../../services/group/GroupService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import GroupClassificationDropdownCustom from "../component/GroupClassificationDropdownCustom";
import { GroupClassificationService } from "../../../../../services/group/GroupClassificationService";

export default function EditGroupInfo(props: any) {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.groupData } });

  const [isOpenAddGroupClassification, setIsOpenAddGroupClassification] = useState<any>();
  const [lstClassificationSlt, setLstClassificationSlt] = useState<any>();
  const [lstGroupClassification, setLstGroupClassification] = useState<any>([]);

  useEffect(() => {
    _getGroupClassification();
  }, []);

  function _getGroupClassification() {
    GroupClassificationService.getInstance()
      .getAll()
      .then((res: any) => {
        setLstGroupClassification(res.data.responseData);
      })
      .catch((err: any) => {});
  }

  const onClickClose = () => {
    props.onClose();
  };

  const onChangeGroupClassification = (data: any) => {
    setLstClassificationSlt(data);
  };

  const onClickEventNoOptions = () => {
    setIsOpenAddGroupClassification(true);
  };

  const handleSaveForm = (dataForm: any) => {
    const param = {
      id: dataForm.id,
      groupName: dataForm.groupName,
      description: dataForm.description,
      classification: lstClassificationSlt,
    };
    GroupService.getInstance()
      .editGroup(param)
      .then((result: any) => {
        if (result.data.status) {
          toast.success(t("group.message.update-success"));
          props.onClose(param);
        } else {
          toast.error(t("group.message.update-err"));
        }
      })
      .catch((error: any) => {
        console.log(error);
        toast.error(t("group.message.update-err"));
      });
  };

  return (
    <div className="modal-content">
      <div className="modal-body">
        <h3 className="heading-title px-4">{props.title}</h3>
        <div className="form px-4 forum-topic-add" id="new-post-form">
          <div className="form-group">
            <label>{t("group.label.group-name")}</label>
            <input {...register("groupName", { required: true })} type="text" className="form-control" name="groupName" />
            {errors?.groupName && <p className="msg-error">{t("group.message.requires-name")}</p>}
          </div>
          <div className="form-group">
            <label>{t("group.label.classification")}</label>
            <GroupClassificationDropdownCustom
              sources={lstGroupClassification}
              bindValue={"id"}
              bindLabel={"classificationName"}
              onChange={onChangeGroupClassification}
              defaultValue={props.groupClassification}
              isMulti={true}
              onClickEventNoOptions={onClickEventNoOptions}
              isValid={errors?.selectedGroupCate ? false : true}></GroupClassificationDropdownCustom>
          </div>
          <div className="form-group">
            <label>{t("group.label.description")}</label>
            <textarea {...register("description", { required: true })} name="description" className="form-control" />
            {errors?.description && <p className="msg-error">{t("group.message.requires-des")}</p>}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-secondary mr-4" onClick={onClickClose}>
          {t("common.button.cancel")}
        </button>
        <button className="btn btn-info" onClick={handleSubmit(handleSaveForm)}>
          {t("common.button.save")}
        </button>
      </div>
    </div>
  );
}
