import { useEffect, useState } from "react";
import Select from "react-select";

type DropdownProps = {
  sources: any[];
  bindLabel: string;
  bindValue: any;
  value?: any;
  onChange: (data?: any) => void;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: any;
  isMulti?: boolean;
  isValid?: boolean;
};

export default function GroupDropdownCustom(props: DropdownProps) {
  const [sources, setSources] = useState<any[]>([]);
  const [defaultValue, setDefaultValue] = useState<any>({});
  const [isMulti, setMulti] = useState(false);

  useEffect(() => {
    let temps: any[] = [];
    props.sources.forEach((ele) => {
      let temp: any = {
        label: ele[props.bindLabel],
        value: ele[props.bindValue],
      };
      temps.push(temp);
    });
    setSources(temps);
  }, [props.sources]);

  useEffect(() => {
    if (props.defaultValue) {
      let temp: any = {
        label: props.defaultValue[props.bindLabel],
        value: props.defaultValue[props.bindValue],
      };     
      setDefaultValue(temp);
    } else {
      setDefaultValue({});
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.isMulti) {
      setMulti(props.isMulti);
    }
  }, [props.isMulti]);

  

  const onChange = (data: any) => {
    if (data) {
      let selected = props.sources.find(
        (ele) => ele[props.bindValue] === data.value
      );
      props.onChange(selected);
    } else {
      props.onChange(null);
    }
  };


  return (
    <>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            // primary25: 'hotpink',
            primary: "#C2B089",
          },
        })}
        
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            borderBottom: '1px solid',
            borderRadius: '0px',
            borderBottomColor: props.isValid? '#e0eaf0':'red',
            borderColor: state.isFocused ? '#e0eaf0' : '#e0eaf0',
          }),
        }}

        options={sources}
        className="select"
        classNamePrefix={props.placeholder ? props.placeholder : "Select"}
        defaultValue={defaultValue}
        isDisabled={props.disabled}
        onChange={onChange}
        isMulti={isMulti}
        isSearchable={true}
        
      />
    </>
  );
}


