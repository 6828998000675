import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";

export class HabbitService {

    private static _habbitService: HabbitService

    public static getInstance(): HabbitService {
        if (!HabbitService._habbitService) {
            HabbitService._habbitService = new HabbitService()
        }
        return HabbitService._habbitService;
    }

    save(request: any) {
        const url = process.env.REACT_APP_API_URL + "/habbit/save.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
    findUserSearchKeywords(){
        const url = process.env.REACT_APP_API_URL + "/habbit/findUserSearchKeywords.exclude";
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth(),
      });
    }
    delete(request: any) {
        const url = process.env.REACT_APP_API_URL + "/habbit/delete.exclude";
        return axios.post(url, request, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}

