import React from 'react'

export default function Term() {
  return (
    <div className="page-content object-page-content">
    <div className="container">
      <h3 className="heading-title">Term</h3>
      <div className="block-box p-4">
        <div className="text-center">
          <p>Day la trang dieu khoan</p>
        </div>
      </div>
    </div>
  </div>
  )
}
