import { Controller, useForm } from "react-hook-form";
import { CategoryService } from "../../../../services/category/CategoryService";
import { useEffect, useState } from "react";
import Dropdown from "../../../commons/form/Dropdown";
import LabelRequired from "../../../commons/form/LabelRequired";
import { ObjectService } from "../../../../services/object/ObjectService";
import { useDispatch } from "react-redux";
import { showAndHideSpinner } from "../../../../redux/reducers/spinnerSlice";
import { toast } from "react-toastify";
import { RegexConstant } from "../../../../constants/RegexConstant";
import { useTranslation } from "react-i18next";
import { CommonConstant } from "../../../../constants/CommonConstant";
import { TranslationConstant } from "../../../../constants/TranslationConstant";
import { useAppSelector } from "../../../../hooks/hook";

type AddObjectProps = {
  object?: any;
  onClose?: (data?: any) => void;
};

export default function AddObject(props: AddObjectProps) {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...props?.object,
      email: props.object?.objectDetail?.email,
      website: props?.object?.objectDetail?.website,
      phoneNumber: props?.object?.objectDetail?.phoneNumber,
    },
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useAppSelector((state) => state.language.lang);

  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    CategoryService.getInstance()
      .getAll()
      .then(
        (res) => {
          if (res.data.status) {
            setCategories(res.data.responseData);
            if (props.object) {
              res.data.responseData.forEach((value: any) => {
                if (value.cateId === props.object.category.cateId) {
                  setSelectedCategory(value);
                }
              });
            }
          }
        },
        (error) => {}
      );
  };

  const onChangeCategories = (data: any) => {
    setSelectedCategory(data);
  };

  const onSubmitForm = (data: any) => {
    setIsSubmit(true);
    let request: any = Object.assign({}, data);
    request.category = selectedCategory;

    dispatch(showAndHideSpinner(true));
    ObjectService.getInstance()
      .save(request)
      .finally(() => {
        dispatch(showAndHideSpinner(false));
      })
      .then(
        (res) => {
          if (res.data.status) {
            toast.error(t("toast.success"));
            props.onClose?.(res.data.status);
          }
        },
        (error) => {}
      );
  };

  return (
    <>
      <div
        className="modal-body scrollbar"
        style={{ maxHeight: "70vh", overflowY: "scroll" }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="forum-topic-add px-4">
              <form>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group row">
                      <LabelRequired className="col-sm-3 col-form-label">
                        {t("object.label.name")}
                      </LabelRequired>
                      <div className="col-sm-9">
                        <Controller
                          name="objectName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                className="form-control"
                                {...register("objectName", { required: true })}
                                type="text"
                                {...field}
                              />
                              {errors?.objectName && (
                                <p className="msg-error">
                                  {t("object.label.name")}{" "}
                                  {t("object.error.required")}
                                </p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group row">
                      <LabelRequired className="col-sm-3 col-form-label">
                        {t("object.label.category")}
                      </LabelRequired>
                      <div className="col-sm-9">
                        <Dropdown
                          sources={categories}
                          bindValue={"cateId"}
                          bindLabel={
                            lang === TranslationConstant.SOUTH_KOREA
                              ? "cateNameKr"
                              : lang === TranslationConstant.ENGLISH
                              ? "cateNameEn"
                              : "cateName"
                          }
                          onChange={onChangeCategories}
                          value={selectedCategory}
                        ></Dropdown>
                        {(!selectedCategory && isSubmit) && (
                          <p className="msg-error">
                            {t("object.error.valid.email")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        {t("object.label.email")}
                      </label>
                      <div className="col-sm-9">
                        <input
                          {...register("email", {
                            pattern: {
                              value: RegexConstant.EMAIL,
                              message: "Please enter a valid email",
                            },
                          })}
                          type="email"
                          className="form-control"
                          name="email"
                        />
                        {errors?.email?.type === "pattern" && (
                          <p className="msg-error">
                            {t("object.error.valid.email")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        {t("object.label.web")}
                      </label>
                      <div className="col-sm-9">
                        <input
                          {...register("website")}
                          type="text"
                          className="form-control"
                          name="website"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        {t("object.label.phone")}
                      </label>
                      <div className="col-sm-9">
                        <input
                          {...register("phoneNumber")}
                          type="email"
                          className="form-control"
                          name="phoneNumber"
                        />
                        {errors?.phoneNumber && (
                          <p className="msg-error">
                            {t("object.label.phone")}{" "}
                            {/* {t("object.error.required")} */}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group row">
                      <LabelRequired className="col-sm-3 col-form-label">
                        {t("object.label.address")}
                      </LabelRequired>
                      <div className="col-sm-9">
                        <input
                          {...register("address", { required: true })}
                          type="text"
                          className="form-control"
                          name="address"
                        />
                        {errors?.address && (
                          <p className="msg-error">
                            {t("object.label.address")}{" "}
                            {t("object.error.required")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group ">
                      <label>{t("object.label.description")}</label>
                      <textarea
                        {...register("description")}
                        name="description"
                        className="form-control textarea"
                        cols={30}
                        rows={5}
                      ></textarea>
                      {/* {errors?.description && (
                        <p className="msg-error">
                          {t("object.label.description")}{" "}
                          {t("object.error.required")}
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>

                {/* <div className="form-group">
                    <label>Image</label>
                    <UploadFile onChange={onChangeUpload}></UploadFile>
                  </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => props.onClose?.()}
        >
          {t("common.button.cancel")}
        </button>
        <button
          type="button"
          className="btn btn-info"
          onClick={handleSubmit(onSubmitForm)}
        >
          {t("common.button.save")}
        </button>
      </div>
    </>
  );
}
