import React, { useEffect, useState } from 'react'
import { ReviewerService } from '../../../services/reviewer/ReviewerService'
import { useAppDispatch } from '../../../hooks/hook'
import { showAndHideSpinner } from '../../../redux/reducers/spinnerSlice'
import { useNavigate } from 'react-router-dom';

export default function ObjectLikeTab(props: any) {

    // const [userUid, setUserUid] = useState<any>(props.userUid)
    const navigate = useNavigate();
    const [lstObject, setLstObject] = useState<any[]>([])
    const dispatch = useAppDispatch()

    console.log(props);
    
    useEffect(() => {
        if (props.userUid) {
            dispatch(showAndHideSpinner(true))
            ReviewerService.getInstance().getLikeObjectByUser({ userUid: props.userUid }).then((response: any) => {
                if (response.data.status) {
                    setLstObject(response.data.responseData)
                }
                dispatch(showAndHideSpinner(false))
            }).catch((error: any) => {
                console.log(error);
                dispatch(showAndHideSpinner(false))
            })
        }

    }, [props.userUid])

    const redirectObjectProfile = (objectId: any) => {
        navigate("/object/" + objectId)
    }

    return (
        <div className="user-list-view forum-member">
            {lstObject && lstObject.length > 0 ?
                <>
                    {
                        lstObject && lstObject.map((object: any) => (
                            <div className="widget-author block-box" key={object.id}>
                                <div className="author-heading" style={{ flex: "5" }}>
                                    <div className="cover-img">
                                        <img src="media/figure/cover_1.jpg" alt="cover" />
                                    </div>
                                    <div className="group-img legal">
                                        <a onClick={() => redirectObjectProfile(object.id)}>
                                            <img style={{ width: "100px", height: "100px" }} 
                                                src={process.env.REACT_APP_API_URL + "/public/image-view/" + object.avatarImage?.atchFleSeq}
                                                onError={(event: any) => {
                                                event.target.src = require("../../../assets/placeholder.jpg")
                                            }} alt="author" />
                                        </a>
                                    </div>
                                    <div className="profile-name mx-2" style={{cursor: "pointer"}} onClick={() => redirectObjectProfile(object.id)}>
                                        <h4 className="author-name text-dark-c">
                                            <>{object.objectName}</>
                                            { object.legal && <i className="icofont-check legal mx-1" style={{ borderRadius: "50%", color: "var(--deep-lilac-color)", border: "2px solid var(--deep-lilac-color)"}}></i>}
                                        </h4>
                                        <div className="author-location">{object.objectDetail.email}</div>
                                    </div>
                                </div>
                                <div className='author-statistics'>
                                </div>
                            </div>
                        ))
                    }
                </>
                :
                <div className="text-center">
                    <h5>No data</h5>
                </div>
            }
        </div>
    )
}
