import React, { useEffect, useRef, useState } from "react";
import { PostService } from "../../../services/post/PostService";
import { PaginationConstant } from "../../../constants/PaginationConstant";
import PostView from "../Newsfeed/components/PostView";
import { useTranslation } from "react-i18next";

export default function Following() {

  const { t, i18n } = useTranslation();
  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });
  const [posts, setPosts] = useState<any[]>([]);
  const postElementsRef = useRef<HTMLDivElement[]>([]);
  const [totalElements, setTotalElements] = useState(0);



  const refreshPosts = () => {
    getFollowingPost();
  };

  useEffect(() => {
    getFollowingPost();
  },[])

  const getFollowingPost = () =>{
    PostService.getInstance().getFollowingPost(searchRequest).then((result) =>{
      if(result.data.status){
        setPosts(result.data.responseData?.lstPost);
        setTotalElements(result.data.responseData?.totalElement);
      }
    })
  }

  const loadMorePosts = () => {
    searchRequest.page = searchRequest.page + 1;
    setSearchRequest(searchRequest);
    PostService.getInstance().getFollowingPost(searchRequest).then((result) =>{
      if(result.data.status){
        let temps = posts.concat(result.data.responseData?.lstPost || []);
        setPosts(temps);
        setTotalElements(result.data.responseData?.totalElement);
      }
    })
  };

  return (
    <div className="page-content">
        <div className="container">
          <div
            className="row"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div className="post-view-wrap">
              {posts?.map((ele: any, idx) => (
                <div
                  key={idx}
                  ref={(element: any) => {
                    postElementsRef.current[idx] = element;
                  }}
                >
                  <PostView
                    key={idx}
                    post={ele}
                    refresh={refreshPosts}
                  ></PostView>
                </div>
              ))}

              {searchRequest.page * searchRequest.size < totalElements && posts.length < totalElements && (
                <div className="block-box load-more-btn">
                  <a className="item-btn" onClick={loadMorePosts}>
                    <i className="icofont-refresh"></i>
                    {t("common.button.loadmore")}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}
