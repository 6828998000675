import { Fragment, useEffect, useState } from "react";
import Rating from "../../../commons/post/Rating";
import AppTimeAgo from "../../../commons/ui/DateFormat/AppTimeAgo";
import PostImage from "../../../commons/form/PostImage";

import Share from "../../../commons/post/Share";
import AvatarImage from "../../../commons/post/AvatarImage";
import { AuthUtil } from "../../../../utils/AuthUtil";
import { PostService } from "../../../../services/post/PostService";
import { useTranslation } from "react-i18next";

type PostViewProps = {
  post: any;
  onLogin: () => void;
  isShared?: boolean;
};

export default function PostView(props: PostViewProps) {

  const {t, i18n} = useTranslation();

  const [post, setPost] = useState(props.post);
  const [postShared, setPostShared] = useState<any>();


  useEffect(() => {
    setPost(props.post);
    if (props.post?.postSharedId) {
      PostService.getInstance()
        .getPostShared(props.post.postSharedId)
        .then(
          (res: any) => {
            if (res.data.status) {
              setPostShared(res.data.responseData);
            }
          },
          (error) => {}
        ).catch((e:any)=>{
          console.log(e);
        });
    }
  }, [props.post]);

  const shortenParaghap = (content: String) => {
    const sub = content.split(" ", 50).join(" ");
    return (
      <>
        {sub + (sub.length < content.length ? "...  " : "  ")}{" "}
        {sub.length < content.length && (
          <a className="" onClick={onClickAction}>
            <i>{t("newsfeed.post.seeDetails")}</i>
          </a>
        )}
      </>
    );
  }

  const onClickAction = () => {
    // AuthUtil.logout();
    // if (!AuthUtil.isLogin()) {
    //     let domain = window.location.origin;
    //     let url = process.env.REACT_APP_AUTH_URL + "/login?redirect_uri=" + domain;
    //     let loginWindow = window.open(url, 'popup','width=600,height=600');
    // }
    props.onLogin();
  };

  return (
    <>
      <div className="block-box post-view">
        <div className="post-header">
          <div className="media">
            <div className="user-img" style={{ width: "54px", height: "54px" }}>
              <AvatarImage atchFleSeq={post?.creator?.imgPath}></AvatarImage>
            </div>
            <div className="post-view">
            {
                (post.postSharedId && postShared) ?
                <div className="user-title">
                <a onClick={onClickAction}>{post?.creator?.fullName}</a>{" "}
                &nbsp;{t("newsfeed.post.sharedPostOf")}&nbsp;{" "}
                <a onClick={onClickAction}>{postShared?.creator?.fullName}</a>
              </div>
              :
              <div className="user-title">
              <a onClick={onClickAction}>{post?.creator?.fullName}</a>{" "}
              &nbsp;{t("newsfeed.post.postedReviewAbout")}&nbsp;{" "}
              <a onClick={onClickAction}>{post?.object?.objectName}</a>
              {post && post.object?.legal && (
                <img src="/assets/media/blue-tick.svg" alt=""/>
              )}
            </div>
            }
              <ul className="entry-meta">
                <li className="meta-privacy">
                  <i className="icofont-world"></i> {t("newsfeed.post.public")}
                </li>
                <li className="meta-time">
                  <AppTimeAgo date={new Date(post.createdDate)}></AppTimeAgo>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="post-body">
          <p className="post-title">{post.title}</p>
          <div className="post-content">
            <div>
              {shortenParaghap(post.content)}
              {/* <a className="" onClick={onSelect}><i>Xem chi tiết</i></a> */}
            </div>
          </div>

          {!post.postSharedId && (
          <>
          <div style={{ position: "relative" }}>
            <PostImage attachments={post.attachments} detail={false} />
            <div
              className={post.attachments ? "image_cover" : "not_image_cover"}
              onClick={onClickAction}
            >
              <div className={post.attachments ? "" : "rating-not-attch"}>
                {post.ratingProps?.map(
                  (ele: any, idx: any) =>
                    idx <= 5 && (
                      <Fragment key={idx}>
                        <div className="eval_item" title={ele.propNm}>
                          {ele.propNm}
                        </div>
                        <Rating rate={ele.rating}></Rating>
                      </Fragment>
                    )
                )}
              </div>
            </div>
          </div>
          {
            !props.isShared && 
            <div className="post-meta-wrap">
              <div className="post-meta">
                <div className="post-reaction">
                  {post.totalReaction > 0 && (
                    <>
                      <div className="reaction-icon">
                        <img src="media/figure/reaction_1.png" alt="icon" />
                        <img src="media/figure/reaction_2.png" alt="icon" />
                      </div>
                      <div className="meta-text">{post.totalReaction}</div>
                    </>
                  )}
                </div>
              </div>
              <div className="post-meta">
                {post.totalComment > 0 && (
                  <div className="meta-text">{post.totalComment} {t("newsfeed.post.comments")}</div>
                )}

                {/* <div className="meta-text">05 Share</div> */}
              </div>
            </div>            
          }

          </>
          )}
          {post.postSharedId && postShared && (
            <>
              <PostView onLogin={onClickAction} post={postShared} isShared={true}></PostView>
              <div className="post-meta-wrap">
                <div className="post-meta">
                <div className="post-reaction">
                {post.totalReaction > 0 && (
                  <>
                    <div className="reaction-icon">
                      <img src="media/figure/reaction_1.png" alt="icon" />
                      <img src="media/figure/reaction_2.png" alt="icon" />
                    </div>
                    <div className="meta-text">{post.totalReaction}</div>
                  </>
                )}
              </div>
                  {/* <div className="post-reaction">
                    {post.reactionDetail?.totalEmoji > 0 && (
                      <>
                        {post.reactionDetail?.emojis.map(
                          (ele: any, idx: any) =>
                            idx <= 1 && (
                              <img key={idx} src={ele.valueconfig} alt="icon" />
                            )
                        )}
                        <div className="meta-text">
                          {post.reactionDetail?.totalEmoji}
                        </div>
                      </>
                    )}
                  </div> */}
                </div>
                <div className="post-meta">
                  {post.totalComment > 0 && (
                    <div className="meta-text">
                      {post.totalComment} {t("newsfeed.post.comments")}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {!props.isShared && (
        <div className="post-footer">
          <ul>
            <li className="post-react">
              <a onClick={onClickAction}>
                <i className="icofont-thumbs-up"></i>{t("common.button.interest")}
              </a>
            </li>
            <li>
              <a onClick={onClickAction}>
                <i className="icofont-comment"></i>{t("newsfeed.post.comment")}
              </a>
            </li>
            <li className="post-share">
              <a className="share-btn">
                <i className="icofont-share"></i>{t("newsfeed.post.share")}
              </a>
            </li>
          </ul>
        </div>
        )}
        {/* <div className="post-comment">
          <ul className="comment-list">
            {comments.map((ele) => (
              <li key={ele.id} className="main-comments">
                <div className="each-comment">
                  <div className="each-comment-children">
                    <div className="media">
                      <div className="user-img">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "/public/avatar-view/" +
                            ele.user?.imgPath
                          }
                          width={30}
                        />
                      </div>
                      <div className="media-body">
                        <div>
                          <div className="user-title">
                            <NavLink to={"/profile/" + ele.user?.userUid}>
                              {ele.user?.fullName}
                            </NavLink>
                          </div>
                          <ul className="entry-meta">
                            <div className="post-body">
                              <div className="react-icon">
                                <img
                                  src="media/figure/reaction_1.png"
                                  alt="icon"
                                />
                                <img
                                  src="media/figure/reaction_2.png"
                                  alt="icon"
                                />
                              </div>
                              <p>{ele.content}</p>
                            </div>
                          </ul>
                        </div>
                        <div className="post-footer">
                          <ul>
                            <Reaction comment={ele}></Reaction>
                            <li className="meta-time">
                              <span>
                                <AppTimeAgo
                                  date={new Date(ele.createdDate)}
                                ></AppTimeAgo>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="post-comment" style={{ borderTop: 'unset' }}>
          <div className="comment-reply">
            <div className="user-img">
              <img src="media/figure/chat_15.jpg" alt="Aahat" />
            </div>
            <div className="input-box">
              <input
                {...register("content", { required: true })}
                type="text"
                name="content"
                className="form-control"
                placeholder="Your Reply...."
                onKeyUp={handleSubmitComment}
              />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
