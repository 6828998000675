import { HashLoader, BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { showAndHideSpinnerSelector } from "../../../../redux/reducers/spinnerSlice";

const override: any = {
  position: "absolute",
  display: "block",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) rotate(220deg) ",
  zIndex: 999
};

export default function Spinner() {
  
  const isShowAndHide = useSelector(showAndHideSpinnerSelector);

  return (
    <>
      {isShowAndHide ? (
        <>
          <div className="overlay"></div>
          <HashLoader
            color={"#fd8535"}
            loading={true}
            cssOverride={override}
            size={60}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}
