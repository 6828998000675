import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";
import { ApiUrlUtil } from "../../utils/ApiUrlUtil";
import { RequestParam } from "../../models/request-params";
import { ParamUtil } from "../../utils/ParamUtil";

export class GroupService {
  private static _groupService: GroupService;

  public static getInstance(): GroupService {
    if (!GroupService._groupService) {
      GroupService._groupService = new GroupService();
    }
    return GroupService._groupService;
  }

  public search(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/search.exclude", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getMyGroup() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/getMyGroup.exclude");
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getMyGroupInf() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/getMyGroupInf.exclude");
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getInvitationJoinGroup() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/getInvitationJoinGroup.exclude");
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getGroupById(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/getGroupById.exclude", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }
  public getMembersInfo(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/getMembersInfo.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public requestJoinGroup(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/requestJoinGroup.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public approveRequestJoin(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/approveRequestJoin.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public rejectRequestJoin(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/rejectRequestJoin.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public searchUserByName(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/searchUserByName.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public createGroup(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/createGroup.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public acceptInviteJoinGroup(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/acceptInviteJoinGroup.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public rejectInviteJoinGroup(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/rejectInviteJoinGroup.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public leaveGroup(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/leaveGroup.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getPostInGroup(param: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/getPostInGroup.exclude", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public inviteMemeber(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/inviteMemeber.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public saveImage(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/saveImage.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getGroupsHaveJoined() {
    const url = process.env.REACT_APP_API_URL + "/group/getGroupsHaveJoined";
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public cancelRequestJoin(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/cancelRequestJoin.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public editGroup(param: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/editGroup.exclude");
    return axios.post(url, param, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getGroupSggtByClassification(request: any) {
    const params = ParamUtil.toRequestParams(request);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/group/getGroupSggtByClassification.exclude", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }
}
