import axios from "axios";
import { HeadersUtil } from "../../utils/HeadersUtil";

export class GroupClassificationService {
  private static _groupClassificationService: GroupClassificationService;

  public static getInstance(): GroupClassificationService {
    if (!GroupClassificationService._groupClassificationService) {
      GroupClassificationService._groupClassificationService = new GroupClassificationService();
    }
    return GroupClassificationService._groupClassificationService;
  }

  public save(request: any) {
    const url = process.env.REACT_APP_API_URL + "/classification/save.exclude";
    return axios.post(url, request, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getAll() {
    const url = process.env.REACT_APP_API_URL + "/classification/getAll.exclude";
    return axios.get(url, { headers: HeadersUtil.getHeadersAuth() });
  }
}
