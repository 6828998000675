import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactTimeAgo from "react-time-ago";

import { langSelector } from "../../../../redux/reducers/multiLangSilce";
import { TranslationConstant } from "../../../../constants/TranslationConstant";

export default function AppTimeAgo(props: any) {
    
  const lang = useSelector(langSelector);
  const [locales, setLocales] = useState("vi");
  
  

  useEffect(() => {
    if (lang === TranslationConstant.VIETNAMESE) {
      setLocales("vi");
    } else if (lang === TranslationConstant.ENGLISH) {
      setLocales("en-US");
    } else {
      setLocales("ko");
    }
  }, [lang]);

  return (
    <>
      <ReactTimeAgo
        date={props.date? new Date(props.date) : new Date()}
        timeStyle="round-minute"
        locale={locales}
      />
    </>
  );
}
