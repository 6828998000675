import React, { useState } from 'react'
import QRCode from "qrcode.react";
import { SettingService } from '../../../../services/setting/SettingService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


export default function TwoFAEnableModal(props: any) {

    const [otpCode, setOtpCode] = useState('')   
    const { t } = useTranslation();
 
    
    const onClickClose = () => {
        props.onCloseModal(false)
    }

    const handleSubmit = () => {
        const param = {
            otpCode: otpCode,
            status: true
        }
        SettingService.getInstance().verifyOtp(param).then((result:any)=>{
            if(result.data.status){
                toast.success(t("setting.message.enable-2fa"))
                props.onCloseModal(false)
            }else{
                toast.error(t("setting.message.otp-err"))
            }
        }).catch((error:any)=>{
            console.log(error);
        })
    }
    return (
        <div className='modal-content'>
            <div className='modal-body text-center'>
                <QRCode value={props.qrData}></QRCode>
                <h6 className='text-dark-c'>{props.faKey}</h6>
                <p className="my-2 text-left"><span className='text-dark-c font-weight-bold'>{t("setting.text.step1")}: </span>{t("setting.text.step1-text")}</p>
                <p className="my-2 text-left"><span className='text-dark-c font-weight-bold'>{t("setting.text.step2")}: </span>{t("setting.text.step2-text")}</p>
                <p className="my-2 text-left"><span className='text-dark-c font-weight-bold'>{t("setting.text.step3")}: </span>{t("setting.text.step3-text")}</p>
                <p className="my-2">{t("setting.text.enter-code")}</p>
                <div className="px-4">
                    <input onChange={(event:any)=>setOtpCode(event.target.value)} className='form-control text-center' type="text" />
                </div>

            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary mr-4" onClick={onClickClose}>{t("common.button.cancel")}</button>
                <button className="btn btn-info" onClick={handleSubmit}>{t("common.button.save")}</button>
            </div>
        </div>
    )
}
