import { useState, useEffect } from "react";
import { CommonConstant } from "../../../constants/CommonConstant";
import { ComStdService } from "../../../services/common/ComStdService";
import { ReactionService } from "../../../services/post/ReactionService";
import { HabbitService } from "../../../services/habbit/HabbitService";
import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/reducers/multiLangSilce";
import { TranslationConstant } from "../../../constants/TranslationConstant";
import { useTranslation } from "react-i18next";

type ReactionProps = {
  actions?: any[];
  post?: any;
  comment?: any;
  refresh?: () => void;
  onIncrease?: (event?: any) => void;
  onDecrease?: (event?: any) => void;
  onChangeEmoji?: (type: any, preEmoji: any, currentEmoji: any) => void;
};

export default function Reaction(props: ReactionProps) {

  const currentLang = useSelector(langSelector);

  const [reaction, setReaction] = useState<any>();
  const [actions, setActions] = useState<any[]>([]);

  const {t, i18n} = useTranslation();

  useEffect(() => {
    if (props.post) {
      setReaction(props.post?.currentUserReactionToPost?.reaction);
    }
  }, [props.post]);

  useEffect(() => {
    if (props.comment) {
      setReaction(props.comment?.currentUserEmojiWithComment);
    }
  }, [props.comment]);

  useEffect(() => {
    getActions();
  }, []);

  const getActions = () => {
    let request = {
      upCommCd: CommonConstant.REACTION_TYPE,
      useYn: "Y",
    };
    ComStdService.getInstance()
      .getByUpCommCd(request)
      .then(
        (res) => {
          setActions(res.data.responseData);
        },
        (error) => { }
      );
  };

  const onClickAction = (data: any) => {
    let request: any = data;
    request.actionType = data.commCd;
    request.id = null;

    if (props.post) {
      request.postId = props.post?.id;
    } else {
      request.commentId = props.comment?.id;
    }

    if (reaction == null) {
      props.onChangeEmoji?.("INCREASE", reaction, data);
      handleSave(request);
      // props.onIncrease?.(data);
      
    } else if (data.commCd === reaction.actionType) {
      props.onChangeEmoji?.("DECREASE", reaction, data);
      handleDelete(request);
      // props.onDecrease?.(data);
      
    } else {
      props.onChangeEmoji?.("CHANGE", reaction, data);
      request.id = reaction.id;    
      handleSave(request);    
    }
  };

  const handleSave = (data: any) => {
    ReactionService.getInstance()
      .save(data)
      .then((res) => {
        setReaction({
          ...res.data.responseData,
        });
        props.refresh?.();
      });
    //collect habbit
    const habbit: any = {
      actionType: CommonConstant.ACTION_TYPE_LIKE,
      postId: data?.postId,
    }
    HabbitService.getInstance().save(habbit).then(resp => {
      if (resp.data.status) {
        // console.log(resp);
      }
    })
      .catch(err => {
      })
  };

  const handleDelete = (request: any) => {
    ReactionService.getInstance()
      .delete(request)
      .then((res) => {
        setReaction(null);
        props.refresh?.();
      });
  };

  return (
    <>
      <li className="post-react">
        {reaction != null ? (
          <a onClick={() => onClickAction({ ...reaction })} className={"reaction reaction" + reaction.commCd}>
            <img src={reaction.valueConfig} />
            &ensp;
            {
              TranslationConstant.ENGLISH === currentLang && reaction.commNmEn
            }
            {
              TranslationConstant.VIETNAMESE === currentLang && reaction.commNmVi
            }
            {
              TranslationConstant.SOUTH_KOREA === currentLang && reaction.commNm
            }
            
          </a>
        ) : (
          <a onClick={() => onClickAction(actions[0])}>
            <i className="icofont-thumbs-up"></i>{t("common.button.interest")}
          </a>
        )}

        <ul className="react-list">
          {actions.map((ele, idx) => (
            <li key={idx}>
              <a onClick={() => onClickAction(ele)} style={{ width: "35px" }}>
                <img
                  src={ele.valueConfig}
                  className={
                    reaction != null && ele.commCd == reaction.actionType
                      ? "reaction-active"
                      : ""
                  }
                />
              </a>
            </li>
          ))}
        </ul>
      </li>
    </>
  );
}
