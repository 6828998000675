import Layout from "../components/layout/Layout";
import Following from "../components/pages/Following/Following";
import Groups from "../components/pages/Groups/Groups";
import Messenger from "../components/pages/Messenger/Messenger";
import MyPage from "../components/pages/MyPage/MyPage";
import Newsfeed from "../components/pages/Newsfeed/Newsfeed";
import ObjectPage from "../components/pages/Object/ObjectPage";
import Profile from "../components/pages/Profile/Profile/Profile";
import WriteReview from "../components/pages/WriteReview/WriteReview";
import AuthGuard from "../guard/authGuard";
import GroupInfo from "../components/pages/Groups/GroupInfo/GroupInfo";
import Notification from "../components/pages/Notification/Notification";
import { AuthUtil } from "../utils/AuthUtil";
import Search from "../components/pages/Search/Search";
import Setting from "../components/pages/Setting/Setting";
import Discovery from "../components/pages/Discovery/Discovery";
import About from "../components/pages/FooterPages/About";
import Guide from "../components/pages/FooterPages/Guide";
import Privacy from "../components/pages/FooterPages/Privacy";
import QuestionAnswer from "../components/pages/FooterPages/QuestionAnswer";
import Term from "../components/pages/FooterPages/Term";

export const layoutRoutes: any = {
  path: "/",
  element: <AuthGuard><Layout /></AuthGuard>,
  children: [
    { path: "newsfeed", element: <Newsfeed /> },
    { path: "discovery", element: <Discovery /> },
    { path: "search", element: <Search /> },
    { path: "groups", element: <Groups /> },
    { path: "groups/:id", element: <GroupInfo /> },
    { path: "following", element: <Following /> },
    { path: "my-page", element: <MyPage /> },
    { path: "object/:id",element: <ObjectPage />},
    { path: "messenger", element: <Messenger /> },
    { path: "notification", element: <Notification /> },
    { 
      path: "profile/:uid",
      element: <Profile />,
    },
    { path: "my-page", element: <MyPage /> },
    { path: "write-review", element: <WriteReview /> },
    {
      path:"setting",
      element: <Setting></Setting>
    },
    { path: "about", element: <About/>},
    { path: "guide", element: <Guide/>},
    { path: "privacy", element: <Privacy/>},
    { path: "q&a", element: <QuestionAnswer/>},
    { path: "term", element: <Term/>},
  ],
}


export const privateRoutes = [
  { path: '/newsfeed', component: Newsfeed, icon: 'icofont-newspaper', name: 'menu.newsfeed' },
  { path: '/write-review', component: Messenger, icon: 'icofont-ui-edit', name: 'menu.writeReview' },
  { path: '/discovery', component: Messenger, icon: 'icofont-ui-search', name: 'menu.discovery' },
  { path: '/groups', component: Groups, icon: 'icofont-users-alt-2', name: 'menu.groups' },
  { path: '/following', component: Following, icon: 'icofont-search-user', name: 'menu.following' },
  { path: '/profile/' + AuthUtil.getCurrentUserUid(), component: MyPage, icon: 'icofont-user', name: 'menu.profile' },
  // { path: '/messenger', component: Messenger, icon: 'icofont-speech-comments', name: 'Messenger' },
  { path: '/setting', component: Messenger, icon: 'icofont-ui-settings', name: 'menu.setting' },
  // { path: '/sys/user-mng', component: UserManagement, icon: 'icofont-ui-settings', name: 'User Management', },
]

export const publicRoutes = [
  { path: '/', icon: 'icofont-chart-histogram-alt', name: 'sidebar.trending' },
  { path: '/', icon: 'icofont-news', name: 'sidebar.lastest' },
]
