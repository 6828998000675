import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { SettingService } from '../../../services/setting/SettingService';
import { toast } from 'react-toastify';
import Switch from "react-switch";
import AppModal from '../../commons/ui/Modal/AppModal';
import OtpModal from './modal/OtpModal';
import VerifyPasswordModal from './modal/VerifyPasswordModal';
import { useTranslation } from 'react-i18next';


export default function PrivacyTab() {

    const { t } = useTranslation();

    const [isOpenChangePwd, setIsOpenChangePwd] = useState(false)
    const changePwdForm = useForm();
    const [changePwdDataForm, setChangePwdDataForm] = useState<any>()
    const [isEnableTwoFA, setIsEnableTwoFA] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)


    useEffect(() => {
        getTwoFa()
    }, [])
    const handleChangePwdForm = (event: any) => {
        setChangePwdDataForm({
            ...changePwdDataForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmitChangePwdForm = (data: any) => {

        if (changePwdDataForm?.newPwd === changePwdDataForm?.confirmPwd) {
            SettingService.getInstance().changePassword(data).then((result: any) => {
                if (result.data.status) {
                    if (result.data.responseData.oldPwdIncorrect) {
                        toast.error(t("setting.message.wrong-pwd"))
                    } else {
                        toast.success(t("setting.message.change-pwd"));
                        setIsOpenChangePwd(false);
                        changePwdForm.reset();
                    }
                } else {
                    toast.error(t("common.message.server-error"))
                }
            }).catch((err) => {
                console.log(err);

            })
        }
    }

    function getTwoFa() {
        SettingService.getInstance().getTwoFa().then((result: any) => {
            if (result.data.status) {
                setIsEnableTwoFA(result.data.responseData.faEnable)
            }
        }).catch(err => {
            console.log(err);

        })
    }


    const onChangeSwitch = (e: any) => {
        setIsOpenModal(true)
        // setIsEnableTwoFA(e);
    }

    const onCloseModal = (e: any) => {
        // console.log(e);
        setIsOpenModal(false)
        getTwoFa()
    }
    return (
        <div>
            <div className="widget widget-memebers">
                <div className="widget-heading">
                    <h3 className="widget-title text-dark-c">{t("setting.text.password")}</h3>
                </div>
                <div className="members-list">
                    <div className="media">
                        <div className="media-body">
                            <div className='d-flex'><h3><i className="icofont-ui-password"></i> </h3>&nbsp;<h6 className="text-dark-c">{t("setting.text.text-pass")}</h6></div>
                            <div className="member-status" style={{ width: 'fit-content' }}>
                                {
                                    !isOpenChangePwd ?
                                        <div className='btn-dark-c'>
                                            <a style={{ cursor: 'pointer' }} onClick={() => { setIsOpenChangePwd(true) }}>{t("setting.button.change-pwd")}</a>
                                        </div>
                                        :
                                        <div className='btn-dark-c'>
                                            <a style={{ cursor: 'pointer' }} onClick={() => { setIsOpenChangePwd(false); changePwdForm.reset() }}>{t("common.button.cancel")}</a>
                                        </div>
                                }
                            </div>
                            {isOpenChangePwd &&
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div className="form p-4 w-50">
                                            <div className="form-group">
                                                <h6 className="text-dark-c">{t("setting.label.old-pwd")}</h6>
                                                <input {...changePwdForm.register("oldPwd", { required: true })}
                                                    onChange={handleChangePwdForm}
                                                    type="password" className="form-control" name='oldPwd' />
                                                {changePwdForm.formState.errors?.oldPwd && <p className="msg-error">{t("setting.text.req-oldpwd")}</p>}
                                            </div>

                                            <div className="form-group">
                                                <h6 className="text-dark-c">{t("setting.label.new-pwd")}</h6>
                                                <input {...changePwdForm.register("newPwd", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-.]).{8,}$/,
                                                        message: "Invalid password",
                                                    }
                                                })}
                                                    onChange={handleChangePwdForm}
                                                    type="password" className="form-control" name='newPwd' />
                                                {changePwdForm.formState.errors?.newPwd?.type === 'required' && <p className="msg-error">{t("setting.text.req-newpwd")}</p>}
                                                {changePwdForm.formState.errors?.newPwd?.type === 'pattern' && <p className="msg-error">{t("setting.text.pwd-err")}</p>}
                                            </div>

                                            <div className="form-group">
                                                <h6 className="text-dark-c">{t("setting.label.cf-pwd")}</h6>
                                                <input {...changePwdForm.register("confirmPwd", { required: true })}
                                                    onChange={handleChangePwdForm}
                                                    type="password" className="form-control" name='confirmPwd' />
                                                {changePwdForm.formState.errors?.confirmPwd && <p className="msg-error">{t("setting.text.req-cfpwd")}</p>}
                                                {changePwdForm.getValues('confirmPwd') && changePwdDataForm?.newPwd !== changePwdDataForm?.confirmPwd && <p className="msg-error">{t("setting.text.cfpwd-err")}</p>}
                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='w-50 d-flex justify-content-end p-4'>
                                            <div className='btn-light-c'>
                                            <a className='act-click' onClick={changePwdForm.handleSubmit(handleSubmitChangePwdForm)}>{t("common.button.save")}</a>

                                            </div>
                                        </div>
                                    </div>
                                </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="widget widget-memebers">
                <div className="widget-heading">
                    <h3 className="widget-title text-dark-c">{t("setting.text.2fa")}</h3>
                </div>
                <div className="members-list">
                    <div className="media">
                        <div className="media-body">
                            <div className='d-flex'><h3><i className="icofont-lock"></i> </h3>&nbsp;<h6 className="text-dark-c">{t("setting.text.text-2fa")}</h6></div>
                            <div className="member-status" style={{ width: 'fit-content' }}>
                                <Switch offColor="#e8cdb8" onColor='#de4e45' uncheckedIcon={false} checkedIcon={false} onChange={onChangeSwitch} checked={isEnableTwoFA} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppModal onClose={onCloseModal} open={isOpenModal} title={t("setting.text.2fa")} size='lg'>
                {isEnableTwoFA ?
                    <OtpModal onCloseModal={onCloseModal}></OtpModal>
                    :
                    <VerifyPasswordModal onCloseModal={onCloseModal}></VerifyPasswordModal>
                }
            </AppModal>
        </div>
    )
}
