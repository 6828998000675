import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PostService } from "../../../services/post/PostService";
import { useDispatch } from "react-redux";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";

import { PaginationConstant } from "../../../constants/PaginationConstant";
import { CommonConstant } from "../../../constants/CommonConstant";

import { HabbitService } from "../../../services/habbit/HabbitService";
import PostView from "../Newsfeed/components/PostView";

export default function Discovery() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [searchRequest, setSearchRequest] = useState<any>({
    page: 0,
    size: PaginationConstant.PAGE_SIZE,
  });
  const [totalElements, setTotalElements] = useState(0);

  const [posts, setPosts] = useState<any[]>([]);
  const [isLoaded, setIsLoaded] = useState<any>(false);
  useEffect(() => {
    getPosts();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", addEventListenerScroll);
  //   return () => {
  //     window.removeEventListener("scroll", addEventListenerScroll);
  //   };
  // }, []);

  // const addEventListenerScroll = useCallback((event: any) => {
  //   console.log(
  //     event.target.scrollingElement.scrollHeight -
  //       event.target.scrollingElement.scrollTop,
  //     event.target.scrollingElement.clientHeight
  //   );
  //   if (
  //     event.target.scrollingElement.scrollHeight -
  //       event.target.scrollingElement.scrollTop ===
  //     event.target.scrollingElement.clientHeight
  //   ) {

  //     loadMorePosts();
  //   }
  // }, []);

  const getPosts = () => {
    dispatch(showAndHideSpinner(true));
    setIsLoaded(false)
    PostService.getInstance()
        .discovery(searchRequest)
        .finally(() => {
          setIsLoaded(true)
          setTimeout(() => {
            dispatch(showAndHideSpinner(false));
          }, 500);
        })
        .then(
          (res) => {            
            let temps = posts.concat(res.data.responseData?.content || []);
            setPosts(temps);
            setTotalElements(res.data.responseData?.totalElements);
          },
          (error) => { }
        );
  };

  const loadMorePosts = () => {
    searchRequest.page = searchRequest.page + 1;
    setSearchRequest(searchRequest);
    getPosts();
  };

  const refreshPosts = () => {
    getPosts();
  };

  const postElementsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const handleScroll = () => {
      postElementsRef.current.forEach((postElement, index) => {
        const rect = postElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isVisible && posts) {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            const habbit: any = {
              actionType: CommonConstant.ACTION_TYPE_SCROLL,
              postId: posts[index]?.id,
            };
            HabbitService.getInstance()
              .save(habbit)
              .then((resp) => {
                if (resp.data.status) {
                }
              })
              .catch((err) => {});
          }, 30000);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [getPosts]);

  return (
    <>
      <div className="page-content">
        <div className="container">
          <div
            className="row"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div className="post-view-wrap">
            {posts?.length == 0 && isLoaded &&
                <><h4 className="not-post-title">{t("newsfeed.post.seeAll")}</h4>
                </>
              }
              {posts?.map((ele: any, idx) => (
                <div
                  key={idx}
                  ref={(element: any) => {
                    postElementsRef.current[idx] = element;
                  }}
                >
                  <PostView
                    key={idx}
                    post={ele}
                    refresh={refreshPosts}
                  ></PostView>
                </div>
              ))}

              {searchRequest.page * searchRequest.size < totalElements && posts.length > 0 && posts.length < totalElements && (
                <div className="block-box load-more-btn">
                  <a className="item-btn" onClick={loadMorePosts}>
                    <i className="icofont-refresh"></i>
                    {t("common.button.loadmore")}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
