import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import "./Setting.css";
import { TranslationConstant } from '../../../constants/TranslationConstant';
import { useAppDispatch, useAppSelector } from '../../../hooks/hook';
import { changeMultiLang } from '../../../redux/reducers/multiLangSilce';

export default function LanguageTab() {
    const [lang, setLang] = useState('')
    const { t, i18n } = useTranslation()
    const language = useAppSelector((state) => state.language.lang);
    const dispatch = useAppDispatch();
    const [languages, setLanguages] = useState<any[]>(() => {
        return TranslationConstant.LANGUAGES;
    });


    function handleChange(lang: string) {
        localStorage.setItem(TranslationConstant.STORAGE_KEY, lang)
        dispatch(changeMultiLang(lang))
        setLang(lang)
        i18n.changeLanguage(lang)
    }

    useEffect(() => {
        setLang(language || TranslationConstant.VIETNAMESE)
    }, [])
    return (
        <div>
            <div className="widget widget-memebers">
                <div className="widget-heading">
                    <h3 className="widget-title">{t("setting.text.choose-lang")}</h3>
                </div>
                {
                    languages.map((element) => (
                        <div className='form-check language-check' key={element.key}>
                            <input className='mx-3 form-check-input' type="radio" id={element.key} name="language" value={element.key} checked={lang === element.key} onChange={()=>handleChange(element.key)}></input>
                            <label htmlFor={element.key}>
                                <span className="media" style={{cursor:"pointer"}}>
                                    <span className="item-img" style={{width: "45px", height: "45px"}}>
                                        <img style={{ objectFit: "cover" }} src={element.icon}/>
                                    </span>
                                    <span className='media-body' >
                                        {element.name}
                                    </span>
                                </span>                    
                            </label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
