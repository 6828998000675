import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ElasticsearchService } from "../../../services/common/ElasticsearchService";
import { useDispatch } from "react-redux";
import { showAndHideSpinner } from "../../../redux/reducers/spinnerSlice";
import PostView from "../Newsfeed/components/PostView";
import { ReviewerService } from "../../../services/reviewer/ReviewerService";
import { GroupService } from "../../../services/group/GroupService";
import { AuthUtil } from "../../../utils/AuthUtil";
import PostDetailModal from "../Newsfeed/components/PostDetailModal";
import PostDetail from "../Newsfeed/components/PostDetail/PostDetail";
import AppModal from "../../commons/ui/Modal/AppModal";
import Login from "../Public/components/Login/Login";
import Dropdown from "../../commons/form/Dropdown";
import { CommonConstant } from "../../../constants/CommonConstant";

export default function Search() {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const searchCont = queryParameters.get("searchCont");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState<any>({});
  const [searchResults, setSearchResults] = useState<any>({});
  const [followingUsers, setFollowingUsers] = useState<any[]>([]);
  const [followingObjects, setFollowingObjects] = useState<any[]>([]);
  const [groupsJoined, setGroupsJoined] = useState<any[]>([]);

  const [isOpenLoginPopup, setIsOpenLoginPopup] = useState(false);
  const [typeFilter, setTypeFilter] = useState<string>("ALL");
  const [periods, setPeriods] = useState<any[]>(() => {
    return CommonConstant.PERIODS;
  });
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null);

  useEffect(() => {
    getFollowingObjects();
    getFollowingUsers();
    getGroupsHaveJoined();
  }, []);

  useEffect(() => {
    let params = {
      ...searchParams,
      searchCont: searchCont,
    };
    setSearchParams({ ...params });
    search(params);
  }, [searchCont]);

  const search = (params: any) => {
    dispatch(showAndHideSpinner(true));
    ElasticsearchService.getInstance()
      .searchAll(params)
      .finally(() => {
        setTimeout(() => {
          dispatch(showAndHideSpinner(false));
        }, 500);
      })
      .then(
        (res) => {
          setSearchResults(res.data);
        },
        (error) => {}
      );
  };

  const filter = (type: any) => {
    setTypeFilter(type);

    let params = {
      ...searchParams,
      type: type,
      period: null,
    };
    setSearchParams({ ...params });
    search(params);
  };

  const onChangePeriod = (data: any) => {
    let params = {
      ...searchParams,
      period: data,
    };
    setSearchParams({ ...params });
    search(params);
  }

  const handleFollowUser = (actionType: boolean) => {};

  const handleJoinGroup = (groupId: any) => {
    let request = {
      groupId: groupId,
    };
    GroupService.getInstance()
      .requestJoinGroup(request)
      .then(
        (res) => {
          if (res.data.status) {
            getGroupsHaveJoined();
          }
        },
        (error) => {}
      );
  };

  const getFollowingUsers = () => {
    ReviewerService.getInstance()
      .getFollowingUser()
      .then(
        (res) => {
          if (res.data.status) {
            setFollowingUsers(res.data.responseData);
          }
        },
        (error) => {}
      );
  };

  const getFollowingObjects = () => {
    ReviewerService.getInstance()
      .getFollowingObject()
      .then(
        (res) => {
          if (res.data.status) {
            setFollowingObjects(res.data.responseData);
          }
        },
        (error) => {}
      );
  };

  const getGroupsHaveJoined = () => {
    GroupService.getInstance()
      .getGroupsHaveJoined()
      .then(
        (res) => {
          if (res.data.status) {
            setGroupsJoined(res.data.responseData);
          }
        },
        (error) => {}
      );
  };

  const checkGroupJoined = (groupId: any) => {
    return groupsJoined.some((ele) => ele.id === groupId);
  };

  const checkUserFollowed = (userUid: any) => {
    return followingUsers.some((ele) => ele.uid === userUid);
  };

  const checkObjectFollowed = (objectId: any) => {
    return followingObjects.some((ele) => ele.objectid === objectId);
  };

  const refreshPosts = () => {
    search(searchParams);
  };

  return (
    <>
      <AppModal
        open={isOpenLoginPopup}
        size="lg"
        onClose={() => setIsOpenLoginPopup(false)}
      >
        <Login></Login>
      </AppModal>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 block-box ">
              <div
                className="widget widget-groups "
                style={{ padding: "30px 15px 30px" }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    className="widget-heading"
                    style={{ marginBottom: "unset" }}
                  >
                    <h3 className="widget-title">Search results</h3>
                  </div>
                  <ul
                    className="nav nav-tabs"
                    role="tablist"
                    style={{ marginBottom: "unset" }}
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#TAB_ALL"
                        role="tab"
                        aria-selected="true"
                        onClick={() => filter("ALL")}
                      >
                        ALL
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#TAB_POST"
                        role="tab"
                        aria-selected="false"
                        onClick={() => filter("POST")}
                      >
                        Post
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#TAB_GROUP"
                        role="tab"
                        aria-selected="false"
                        onClick={() => filter("GROUP")}
                      >
                        Group
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#TAB_OBJECT"
                        role="tab"
                        aria-selected="false"
                        onClick={() => filter("OBJECT")}
                      >
                        Object
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#TAB_USER"
                        role="tab"
                        aria-selected="false"
                        onClick={() => filter("USER")}
                      >
                        User
                      </a>
                    </li>
                  </ul>
                </div>
                {typeFilter === "POST" && (
                  <div className="tab-content" style={{ paddingTop: "35px" }}>
                    <div
                      className="tab-pane fade"
                      id="TAB_POST"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-12">
                          <form id="new-post-form">
                            <div className="form-group">

                              <label>Date posted</label>
                              {
                                periods.map((ele: any, idx: any) => (                                 
                                  <div className="form-check" key={idx}>
                                    <input type="radio" className="form-check-input" value={ele.value} name="period" onClick={(data: any) => onChangePeriod(data.target.value)}/>
                                    <label className="form-check-label" >{ele.name}</label>
                                  </div>
                                ))
                              }
                              
                            </div>
                            {/* <div className="form-group">
                              <label>Posts from</label>
                              <Dropdown sources={} bindLabel="" bindValue={} value={} defaultValue={} onChange={}></Dropdown>
                            </div> */}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {searchResults.groups?.length > 0 && (
              <div className="col-lg-12 block-box user-blog">
                <div className="widget widget-groups">
                  <div className="widget-heading">
                    <h3 className="widget-title">Groups</h3>
                  </div>
                  <div className="group-list">
                    {searchResults.groups?.map((ele: any) => (
                      <div className="media" key={ele.groupId}>
                        <div className="item-img">
                          <NavLink to={"/groups/" + ele.groupId}>
                            <img
                              src={
                                process.env.REACT_APP_API_URL +
                                "/public/avatar-view/" +
                                ele.avatar?.atchFleSeq
                              }
                              width={50}
                            />
                          </NavLink>
                        </div>
                        <div className="media-body">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4 className="item-title">
                              <NavLink to={"/groups/" + ele.groupId}>
                                {ele.groupName}
                              </NavLink>
                            </h4>
                            <div className="item-member">
                              {checkGroupJoined(ele.groupId) ? (
                                <>
                                  {ele.members.map((item: any) => (
                                    <>
                                      {item.userUid ===
                                        AuthUtil.getCurrentUserUid() &&
                                        item.memberStatus === "04-01" && (
                                          <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() =>
                                              navigate("/groups/" + ele.groupId)
                                            }
                                          >
                                            Visit
                                          </button>
                                        )}

                                      {item.userUid ===
                                        AuthUtil.getCurrentUserUid() &&
                                        item.memberStatus === "04-04" && (
                                          <>
                                            <button
                                              className="btn btn-outline-secondary"
                                              type="button"
                                              style={{ marginRight: "10px" }}
                                            >
                                              Accept
                                            </button>
                                            <button
                                              className="btn btn-outline-danger"
                                              type="button"
                                            >
                                              Reject
                                            </button>
                                          </>
                                        )}

                                      {item.userUid ===
                                        AuthUtil.getCurrentUserUid() &&
                                        item.memberStatus === "04-03" && (
                                          <>
                                            <button
                                              className="btn btn-outline-secondary"
                                              type="button"
                                              onClick={() =>
                                                navigate(
                                                  "/groups/" + ele.groupId
                                                )
                                              }
                                            >
                                              Pending
                                            </button>
                                          </>
                                        )}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={() => handleJoinGroup(ele.groupId)}
                                >
                                  Join
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="item-member">{ele.description}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {searchResults.users?.length > 0 && (
              <div className="col-lg-12 block-box user-blog">
                <div className="widget widget-groups">
                  <div className="widget-heading">
                    <h3 className="widget-title">People</h3>
                  </div>
                  <div className="group-list">
                    {searchResults.users?.map((ele: any) => (
                      <div className="media" key={ele.userInfoId}>
                        <div className="item-img">
                          <NavLink to={"/profile/" + ele.userUid}>
                            <img
                              src={
                                process.env.REACT_APP_API_URL +
                                "/public/avatar-view/" +
                                ele.avatar?.atchFleSeq
                              }
                              width={50}
                            />
                          </NavLink>
                        </div>
                        <div className="media-body">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4 className="item-title">
                              <NavLink to={"/profile/" + ele.userUid}>
                                {ele.fullName}
                              </NavLink>
                            </h4>
                            <div className="item-member">
                              {checkUserFollowed(ele.userUid) ? (
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={() => {
                                    navigate("/profile/" + ele.userUid);
                                  }}
                                >
                                  Visit
                                </button>
                              ) : (
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={() => handleFollowUser(ele.userUid)}
                                >
                                  Follow
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="item-member">{ele.address}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {searchResults.objects?.length > 0 && (
              <div className="col-lg-12 block-box user-blog">
                <div className="widget widget-groups">
                  <div className="widget-heading">
                    <h3 className="widget-title">Object</h3>
                  </div>
                  <div className="group-list">
                    {searchResults.objects?.map((ele: any) => (
                      <div className="media" key={ele.objectId}>
                        <div className="item-img">
                          <NavLink to={"/groups/" + ele.objectId}>
                            <img
                              src={
                                process.env.REACT_APP_API_URL +
                                "/public/avatar-view/" +
                                ele.avatar?.atchFleSeq
                              }
                              width={50}
                            />
                          </NavLink>
                        </div>
                        <div className="media-body">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4 className="item-title">
                              <NavLink to={"/groups/" + ele.objectId}>
                                {ele.objectName}
                              </NavLink>
                            </h4>
                            <div className="item-member">
                              {checkObjectFollowed(ele.objectId) ? (
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={() => {
                                    navigate("/object/" + ele.objectId);
                                  }}
                                >
                                  Following
                                </button>
                              ) : (
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                >
                                  Follow
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="item-member block-ellipsis">
                            <p>{ele.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="row"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div className="col-lg-12">
              <div className="post-view-wrap">
                {searchResults.posts?.map((ele: any, idx: any) => (
                  <div key={idx}>
                    <>
                      <PostView
                        key={idx}
                        post={ele}
                        refresh={refreshPosts}
                      ></PostView>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
